import { getCurrentInstance } from 'vue';
import ApiGraphql from './Api';
export default class ConfiguracaoInicialService {
	headers: any;
	constructor() {
		const app = getCurrentInstance();
		this.headers = app?.appContext.config.globalProperties.$headers;
	}
	async getEmpresasAtivas() {
		const body = {
			query: `
                query {
					empresas(filtro: { ativa: true orderBy: ["codigo"] }) {
					  ativa
					  codigo
					  pessoa {
						nome
						nomeAlternativo
						id
					  }
					}
				  }`
		};
		try {
			const result = await ApiGraphql.post('graphql', body, { headers: this.headers() });
			return result.data.empresas;
		} catch (error: any) {
			throw new Error(error);
		}
	}

	async getTerminaisByEmpresaId(empresaId: string) {
		const body = {
			query: `
			query {
				terminaisByEmpresaId(empresaId: "${empresaId}") {
				  id
				  nome
				}
			  }`
		};
		try {
			const result = await ApiGraphql.post('graphql', body, { headers: this.headers() });
			return result.data.terminaisByEmpresaId;
		} catch (error: any) {
			throw new Error(error);
		}
	}
}
