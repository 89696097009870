import { PessoaIndicacaoInput } from '../inputs/PessoaIndicacaoInput';
import { Pessoa } from './Pessoa';
import { Tipo } from './Tipo';

export class PessoaIndicacao {
	id: string;

	pessoaIndicada: Pessoa;

	pessoaComissao: Pessoa;

	comissao: number;

	dataIndicacao: string;

	dataValidade: string;

	tipoCredito: Tipo;

	createdAt: string;

	updatedAt: string;
}

export function pessoaIndicacaoToInput(pessoaIndicacao: PessoaIndicacao): PessoaIndicacaoInput {
	const converter: any = { ...pessoaIndicacao };

	delete converter.pessoaIndicada;
	delete converter.pessoaComissao;
	delete converter.tipoCredito;

	const input: PessoaIndicacaoInput = { ...converter };

	if (pessoaIndicacao.pessoaIndicada) input.pessoaIndicadaId = pessoaIndicacao.pessoaIndicada.id;
	if (pessoaIndicacao.pessoaComissao) input.pessoaComissaoId = pessoaIndicacao.pessoaComissao.id;
	if (pessoaIndicacao.tipoCredito) input.tipoCreditoId = pessoaIndicacao.tipoCredito.id;

	return input;
}
