import { ContaTelefoneInput } from '../inputs/ContaTelefoneInput';
import { Conta } from './Conta';
import { Telefone, telefoneToInput } from './Telefone';

export class ContaTelefone {
	conta: Conta;

	telefone!: Telefone;

	createdAt!: string;

	updatedAt!: string;

	editando: boolean;

	excluir: boolean;
}

export function contaTelefoneToInput(contaTelefone: ContaTelefone): ContaTelefoneInput {
	const converter: any = { ...contaTelefone };

	delete converter.conta;
	delete converter.telefone;
	delete converter.editando;

	const input: ContaTelefoneInput = { ...converter };

	if (contaTelefone.conta) input.contaId = contaTelefone.conta.id;

	if (contaTelefone.telefone) {
		input.telefone = telefoneToInput(contaTelefone.telefone);
	}

	return input;
}
