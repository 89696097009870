import { UsuarioEmpresaFuncaoInput } from '../inputs/UsuarioEmpresaFuncaoInput';
import { Empresa } from './Empresa';
import { Funcao } from './Funcao';
import { Usuario } from './Usuario';

export class UsuarioEmpresaFuncao {
	usuario: Usuario;

	empresa: Empresa;

	funcao: Funcao;

	usuarioSupervisor: Usuario;

	usuarioSupervisorCodigo: number;

	usuarioSupervisorNome: string;

	solicitarSenha: boolean;

	createdAt: string;

	updatedAt: string;
}

export function usuarioEmpresaFuncaoToInput(usuarioEmpresaFuncao: UsuarioEmpresaFuncao): UsuarioEmpresaFuncaoInput {
	const converter: any = { ...usuarioEmpresaFuncao };

	delete converter.usuario;
	delete converter.familia;
	delete converter.funcao;
	delete converter.usuarioSupervisor;

	const input: UsuarioEmpresaFuncaoInput = { ...converter };

	if (usuarioEmpresaFuncao.usuario?.pessoa) input.usuarioId = usuarioEmpresaFuncao.usuario.pessoa.id;
	if (usuarioEmpresaFuncao.empresa?.pessoa) input.empresaId = usuarioEmpresaFuncao.empresa.pessoa.id;
	if (usuarioEmpresaFuncao.funcao) input.funcaoId = usuarioEmpresaFuncao.funcao.id;
	if (usuarioEmpresaFuncao.usuarioSupervisor) input.usuarioSupervisorId = usuarioEmpresaFuncao.usuarioSupervisor.pessoa!.id;

	return input;
}
