import { BoletoConfiguracaoIntegracao, boletoConfiguracaoToInput } from './BoletoConfiguracaoIntegracao';
import { TipoBancoEnum, TipoContaEnum } from '../enum/TipoEnum';
import { ContaInput } from '../inputs/ContaInput';
import { getDataBD } from '../../util/DataUtil';
import { BoletoConfiguracao } from './BoletoConfiguracao';
import { ContaTelefone, contaTelefoneToInput } from './ContaTelefone';
import { Endereco, enderecoToInput } from './Endereco';
import { PessoaConta, pessoaContaToInput } from './PessoaConta';
import { Tipo } from './Tipo';
export class Conta {
	id: string;

	nome: string;

	pessoaContas: PessoaConta[];

	tipoBanco: Tipo;

	tipoConta: Tipo;

	agencia: number;

	agenciaDigito: number;

	conta: number;

	contaDigito: number;

	nomeGerente: string;

	boletoConfiguracao: BoletoConfiguracao;

	boletoConfiguracoesIntegracao: BoletoConfiguracaoIntegracao[];

	dataAbertura: Date;

	limiteConta: number;

	contasTelefones: ContaTelefone[];

	complemento: string;

	endereco: Endereco;

	padrao: boolean;

	nomeTipoConta: string;

	createdAt: Date;

	updatedAt: Date;
}

export function contaToInput(conta: Conta): ContaInput {
	const converter: any = { ...conta };

	delete converter.tipoBanco;
	delete converter.tipoConta;
	delete converter.endereco;
	delete converter.pessoaContas;
	delete converter.boletoConfiguracao;
	delete converter.boletoConfiguracoesIntegracao;
	delete converter.contasTelefones;
	delete converter.createdAt;
	delete converter.dataAbertura;

	const input: ContaInput = { ...converter };

	if (conta.tipoBanco) input.tipoBancoId = conta.tipoBanco.id as TipoBancoEnum;
	if (conta.tipoConta) input.tipoContaId = conta.tipoConta.id as TipoContaEnum;
	if (conta.boletoConfiguracao) input.boletoConfiguracaoId = conta.boletoConfiguracao.id;

	if (conta.pessoaContas && conta.pessoaContas.length > 0) {
		input.pessoaContas = [];
		for (let index = 0; index < conta.pessoaContas.length; index++) {
			const pessoaConta = conta.pessoaContas[index];
			const pessoaContaInput = pessoaContaToInput(pessoaConta);
			input.pessoaContas.push(pessoaContaInput);
		}
	}
	if (conta.boletoConfiguracoesIntegracao && conta.boletoConfiguracoesIntegracao.length > 0) {
		input.boletoConfiguracoesIntegracao = [];
		for (let index = 0; index < conta.boletoConfiguracoesIntegracao.length; index++) {
			const boletoConfiguracaoIntegracao = conta.boletoConfiguracoesIntegracao[index];
			const boletoConfiguracaoIntegracaoInput = boletoConfiguracaoToInput(boletoConfiguracaoIntegracao);
			input.boletoConfiguracoesIntegracao.push(boletoConfiguracaoIntegracaoInput);
		}
	}

	if (conta.dataAbertura) input.dataAbertura = getDataBD(conta.dataAbertura);

	if (conta.contasTelefones && conta.contasTelefones.length > 0) {
		input.contasTelefones = [];
		for (let index = 0; index < conta.contasTelefones.length; index++) {
			const contaTel = conta.contasTelefones[index];
			const contaTelInput = contaTelefoneToInput(contaTel);
			input.contasTelefones.push(contaTelInput);
		}
	}

	if (conta.endereco) {
		input.endereco = enderecoToInput(conta.endereco);
	}

	return input;
}
