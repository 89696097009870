import { PessoaEnderecoEletronicoInput } from '../inputs/PessoaEnderecoEletronicoInput';
import { EnderecoEletronico, enderecoEletronicoToInput } from './EnderecoEletronico';
import { Pessoa } from './Pessoa';

export class PessoaEnderecoEletronico {
	pessoa: Pessoa;

	enderecoEletronico: EnderecoEletronico;

	createdAt: string;

	updatedAt: string;
}

export function pessoaEnderecoEletronicoToInput(pessoaEnderecoEletronico: PessoaEnderecoEletronico): PessoaEnderecoEletronicoInput {
	const converter: any = { ...pessoaEnderecoEletronico };

	delete converter.pessoa;
	delete converter.enderecoEletronico;

	const input: PessoaEnderecoEletronicoInput = { ...converter };

	if (pessoaEnderecoEletronico.pessoa) input.pessoaId = pessoaEnderecoEletronico.pessoa.id;

	if (pessoaEnderecoEletronico.enderecoEletronico) {
		input.enderecoEletronico = enderecoEletronicoToInput(pessoaEnderecoEletronico.enderecoEletronico);
	}

	return input;
}
