import FrenteLojaService from './FrenteLojaService';
import OperacaoService from './OperacaoService';
export default class AppService {
	constructor(vue) {
		this.$store = vue.$store;
		this.$indexDB = vue.$indexDB;
		this.frenteLojaService = new FrenteLojaService(vue);
		this.operacaoService = new OperacaoService(vue);
	}

	async init() {
		try {
			await this.frenteLojaService.initFrenteLoja();
			await this.operacaoService.initOperacoesAbertas();
			await this.operacaoService.initOperacao();
		} catch (e) {
			console.error(e);
			throw new Error(e);
		}
	}
}
