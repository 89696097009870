/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/pt-BR'



import {QLayout,QHeader,QFooter,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QInput,QField,QExpansionItem,QAvatar,QTabs,QTab,QRouteTab,QTabPanels,QTabPanel,QSelect,QSeparator,QCard,QCardSection,QCardActions,QCarousel,QCarouselControl,QCarouselSlide,QBadge,QBtnDropdown,QPopupProxy,QMenu,QToggle,QCheckbox,QTable,QTr,QTd,QTh,QPopupEdit,QImg,QEditor,QDialog,QUploader,QTooltip,QVirtualScroll,QSpace,QTime,QDate,QBtnToggle,QScrollArea,QBanner,QSpinner,QSpinnerHourglass,QRadio,QMarkupTable,QChip,QInfiniteScroll,QForm,QSkeleton,Ripple,ClosePopup,Notify,Dialog,AddressbarColor,Loading} from 'quasar'



const userOptions = { config: {"loading":{"spinner":"QSpinnerHourglass","spinnerColor":"white","spinnerSize":140,"messageColor":"white","message":"Carregando... Por favor, Aguarde!"}},lang,components: {QLayout,QHeader,QFooter,QDrawer,QPageContainer,QPage,QToolbar,QToolbarTitle,QBtn,QIcon,QList,QItem,QItemSection,QItemLabel,QInput,QField,QExpansionItem,QAvatar,QTabs,QTab,QRouteTab,QTabPanels,QTabPanel,QSelect,QSeparator,QCard,QCardSection,QCardActions,QCarousel,QCarouselControl,QCarouselSlide,QBadge,QBtnDropdown,QPopupProxy,QMenu,QToggle,QCheckbox,QTable,QTr,QTd,QTh,QPopupEdit,QImg,QEditor,QDialog,QUploader,QTooltip,QVirtualScroll,QSpace,QTime,QDate,QBtnToggle,QScrollArea,QBanner,QSpinner,QSpinnerHourglass,QRadio,QMarkupTable,QChip,QInfiniteScroll,QForm,QSkeleton},directives: {Ripple,ClosePopup},plugins: {Notify,Dialog,AddressbarColor,Loading} }
  
userOptions.config.loading.spinner = QSpinnerHourglass
  
  
export default userOptions

