import { TipoFuncaoEnum, TipoSetorEnum } from '../enum/TipoEnum';
import { ColaboradorInput } from '../inputs/ColaboradorInput';
import { UsuarioFuncao, usuarioFuncaoToInput } from './UsuarioFuncao';
import { Empresa } from './Empresa';
import { Pessoa, pessoaToInput } from './Pessoa';
import { Tipo } from './Tipo';

export class Colaborador {
	pessoa: Pessoa;

	pessoaSupervisor: Pessoa;

	comissaoParcela: number;

	tipoSetor: Tipo;

	tipoFuncao: Tipo;

	usuarioFuncoes: UsuarioFuncao[];

	empresa: Empresa;

	trabalhoDataAdmissao: string;

	trabalhoSalario: number;

	supervisorCodigo: number;

	supervisorNome: string;

	comissaoDependePagamento: boolean;

	repasseComissao: number;

	// eventos: [Evento];

	createdAt: string;

	updatedAt: string;
}

export function colaboradorToInput(colaborador: Colaborador): ColaboradorInput {
	const converter: any = { ...colaborador };

	delete converter.pessoa;
	delete converter.pessoaSupervisor;
	delete converter.empresa;
	delete converter.tipoSetor;
	delete converter.tipoFuncao;
	delete converter.usuarioFuncoes;

	const input: ColaboradorInput = { ...converter };

	if (colaborador.empresa?.pessoa) input.empresaId = colaborador.empresa.pessoa.id;
	if (colaborador.pessoaSupervisor) input.supervisorId = colaborador.pessoaSupervisor.id;
	if (colaborador.tipoSetor) input.tipoSetorId = colaborador.tipoSetor.id as TipoSetorEnum;
	if (colaborador.tipoFuncao) input.tipoFuncaoId = colaborador.tipoFuncao.id as TipoFuncaoEnum;

	if (colaborador.pessoa) input.pessoa = pessoaToInput(colaborador.pessoa);
	if (colaborador.pessoa && colaborador.pessoa.id && colaborador.usuarioFuncoes && colaborador.usuarioFuncoes.length > 0) {
		input.usuarioFuncoes = [];
		for (let index = 0; index < colaborador.usuarioFuncoes!.length; index++) {
			const usuarioFunc = colaborador.usuarioFuncoes![index];
			const usuarioFuncaoInput = usuarioFuncaoToInput(usuarioFunc);
			input.usuarioFuncoes.push(usuarioFuncaoInput);
		}
	}
	return input;
}
