import { TipoPessoaRestricaoBloqueioEnum, TipoPessoaRestricaoEnum } from '../enum/TipoEnum';
import { PessoaRestricaoInput } from '../inputs/PessoaRestricaoInput';
import { Pessoa } from './Pessoa';
import { Tipo } from './Tipo';

export class PessoaRestricao {
	id: string;

	pessoa: Pessoa;

	tipoRestricao: Tipo;

	tipoBloqueio: Tipo;

	observacao: string;

	createdAt: string;

	updatedAt: string;
}

export function pessoaRestricaoToInput(pessoaRestricao: PessoaRestricao): PessoaRestricaoInput {
	const converter: any = { ...pessoaRestricao };

	delete converter.pessoa;
	delete converter.tipoRestricao;
	delete converter.tipoBloqueio;

	const input: PessoaRestricaoInput = { ...converter };

	if (pessoaRestricao.pessoa) input.pessoaId = pessoaRestricao.pessoa.id;
	if (pessoaRestricao.tipoRestricao) input.tipoRestricaoId = pessoaRestricao.tipoRestricao.id as TipoPessoaRestricaoEnum;
	if (pessoaRestricao.tipoBloqueio) input.tipoBloqueioId = pessoaRestricao.tipoBloqueio.id as TipoPessoaRestricaoBloqueioEnum;

	return input;
}
