import stringifyObject from 'stringify-object';
import { getCurrentInstance } from 'vue';
import { parseObject } from './AppUtil';

export function objectToQuery(obj: any): any {
	return stringifyObject(parseObject(obj), {
		indent: '  ',
		singleQuotes: false,
		inlineCharacterLimit: 12
	});
}

export function capturarErroGraphql(data: any): void {
	const app = getCurrentInstance();
	const errors = data.errors;

	if (errors) {
		const message = errors.map((error: any) => error.message).join(', ');

		app?.appContext.config.globalProperties.$notificacao.erro(message, 10000);
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', message);
		}
		throw new Error(message);
	}
}
