import { UsuarioInput } from '../inputs/UsuarioInput';
import { Evento } from './Evento';
import { Pessoa, pessoaToInput } from './Pessoa';
import { UsuarioEmpresa, usuarioEmpresaToInput } from './UsuarioEmpresa';
import { UsuarioFuncao } from './UsuarioFuncao';

export class Usuario {
	pessoa: Pessoa;

	login: string;

	senha: string;

	codigo: number;

	senhaOperacional: string;

	usuarioNome: string;

	token: string;

	iat: number;

	exp: number;

	dataUltimoAcesso: string;

	usuarioEmpresaLogado: UsuarioEmpresa;

	usuarioEmpresas: UsuarioEmpresa[];

	usuarioFuncoes: UsuarioFuncao[];

	eventos: [Evento];

	createdAt: string;

	updatedAt: string;

	utilizandoSenhaPadrao: boolean;
}

export function usuarioToInput(usuario: Usuario): UsuarioInput {
	const converter: any = { ...usuario };

	delete converter.pessoa;
	delete converter.usuarioNome;

	const input: UsuarioInput = { ...converter };

	input.usuarioEmpresas = [];

	if (usuario.pessoa) {
		input.pessoa = pessoaToInput(usuario.pessoa);
	}

	if (usuario.usuarioEmpresas && usuario.usuarioEmpresas.length > 0) {
		for (let index = 0; index < usuario.usuarioEmpresas.length; index++) {
			const usuarioEmpresa = usuario.usuarioEmpresas[index];
			const usuarioEmpresaInput = usuarioEmpresaToInput(usuarioEmpresa);
			input.usuarioEmpresas.push(usuarioEmpresaInput);
		}
	}

	return input;
}
