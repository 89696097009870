import { TipoEnderecoEletronicoEnum } from '../enum/TipoEnum';
import { EnderecoEletronicoInput } from '../inputs/EnderecoEletronicoInput';
import { Tipo } from './Tipo';

export class EnderecoEletronico {
	id: string;

	tipoEnderecoEletronico: Tipo;

	enderecoEletronico: string;

	contato: string;

	createdAt: string;

	updatedAt: string;
}

export function enderecoEletronicoToInput(enderecoEletronico: EnderecoEletronico): EnderecoEletronicoInput {
	const converter: any = { ...enderecoEletronico };

	delete converter.tipoEnderecoEletronico;

	const input: EnderecoEletronicoInput = { ...converter };

	if (enderecoEletronico!.tipoEnderecoEletronico) {
		input.tipoEnderecoEletronicoId = enderecoEletronico!.tipoEnderecoEletronico.id as TipoEnderecoEletronicoEnum;
	}

	return input;
}
