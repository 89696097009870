import moment from 'moment';
import { getSomenteNumeros } from './AppUtil';
import { format } from 'date-fns';

export function getDataFormatada(date: Date): string {
	if (date) {
		return moment(date).format('DD/MM/YYYY');
	} else {
		return '';
	}
}

export function distanciaEntreDatasEmDias(data1: string, data2: string): number {
	const date1 = getDateFromString(data1);
	const date2 = getDateFromString(data2);
	return moment(date1).diff(date2, 'days');
}

export function getDataHoraSomada(dataFinal: Date, maisDias: number): Date {
	return moment(dataFinal).utcOffset('-03:00').add(maisDias, 'days').toDate();
}

export function mesmoDia(data1: Date, data2: Date): boolean {
	return moment(new Date(data1)).isSame(new Date(data2));
}

export function getDataHoraFormatadaByFormatoEntrada(
	data: any,
	dataEntrada = 'DD/MM/YYYY',
	formatoData = 'YYYY-MM-DD HH:mm:ss ZZ'
): string {
	return moment(data, dataEntrada).format(formatoData);
}

export function formatarDate(date: Date) {
	return format(date, 'dd/MM/yyyy HH:mm');
}

export function getAno(date: Date): string {
	if (date) {
		return moment(date).format('YYYY');
	} else {
		return '';
	}
}

export function getDataHoraFormatada(date: Date, exibirSegundos = false): string | void {
	if (date) {
		if (exibirSegundos) {
			return moment(new Date(date)).format('DD/MM/YYYY HH:mm:ss');
		}
		return moment(new Date(date)).format('DD/MM/YYYY HH:mm');
	} else {
		return '';
	}
}

export function getDataBD(date: Date, formatoData = 'YYYY-MM-DD HH:mm:ss') {
	if (date) {
		return moment(date).format(formatoData);
	}

	return null;
}

export function getDataUTC(date: Date) {
	if (date) {
		const data = moment(date).format('YYYY-MM-DD');
		const hora = moment(date).format('hh:mm:ss');
		const utc = moment(date).format('Z');

		return `${data}T${hora}${utc}`;
	}

	throw Error('Informar data');
}

export function getDataHoraFinalDia(dataFinal: any, formatoData = 'YYYY-MM-DD HH:mm:ss ZZ'): string {
	return moment(dataFinal, 'DD/MM/YYYY').endOf('day').format(formatoData);
}

export function getDataHoraAtual(formatoData = 'YYYY-MM-DD HH:mm:ss.SSS ZZ'): string {
	try {
		return moment(new Date()).utcOffset('-03:00').format(formatoData);
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
		throw new Error();
	}
}

export function getDataHora(formatoData = 'YYYY-MM-DD HH:mm:ss') {
	try {
		return moment(new Date()).utcOffset('-03:00').format(formatoData);
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
	}
}

export function getDataHoraMenosDias(menosDias: number, formatoData = 'YYYY-MM-DD HH:mm:ss') {
	return moment().utcOffset('-03:00').subtract(menosDias, 'days').format(formatoData);
}

export function getDataHoraMaisDias(maisDias: number, formatoData = 'YYYY-MM-DD HH:mm:ss') {
	return moment().utcOffset('-03:00').add(maisDias, 'days').format(formatoData);
}

export function getDataMaisDias(data: string, maisDias: number, formatoData = 'DD/MM/YYYY') {
	return moment(getDateFromString(data)).add(maisDias, 'days').format(formatoData);
}

export function getDateFromString(dataStr: string, formatoData = 'DD/MM/YYYY') {
	const momentObj = moment(dataStr, formatoData);
	return momentObj.toDate();
}

export function getDiaSemana(data: Date): string {
	try {
		const indexDia = data.getDay();
		if (indexDia === 0) return 'domingo';
		if (indexDia === 1) return 'segundaFeira';
		if (indexDia === 2) return 'tercaFeira';
		if (indexDia === 3) return 'quartaFeira';
		if (indexDia === 4) return 'quintaFeira';
		if (indexDia === 5) return 'sextaFeira';
		if (indexDia === 6) return 'sabado';
		return '';
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
		throw new Error();
	}
}

export function isDataSelecionadaMenorIgualDataAtual(dataSelecionada: Date): boolean {
	try {
		if (!dataSelecionada) {
			throw new Error('Necessário informar data para para comparação.');
		}
		let dataAtual = getDataHoraAtual('YYYY-MM-DD');
		dataAtual = `${dataAtual} 00:00.000`;
		return moment(dataSelecionada).isSameOrBefore(new Date(dataAtual));
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
		throw new Error();
	}
}

export function isDataSelecionadaMaiorIgualDataAtual(dataSelecionada: Date): boolean {
	try {
		if (!dataSelecionada) {
			throw new Error('Necessário informar data para para comparação.');
		}
		let dataAtual = getDataHoraAtual('YYYY-MM-DD');
		dataAtual = `${dataAtual} 00:00.000`;
		return moment(dataSelecionada).isSameOrAfter(new Date(dataAtual));
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
		throw new Error();
	}
}

export function isHoraSelecionadaMenorIgualHoraComparacao(horaSelecionada: string, horaComparacao: string): boolean {
	try {
		const horaHS = horaSelecionada.slice(0, 2);
		const minutoHS = horaSelecionada.slice(3, 5);
		const segundoHS = horaSelecionada.slice(6, 8);
		const horaHC = horaComparacao.slice(0, 2);
		const minutoHC = horaComparacao.slice(3, 5);
		const segundoHC = horaComparacao.slice(6, 8);
		if (horaHS > horaHC) {
			return false;
		} else if (horaHS < horaHC) {
			return true;
		} else if (minutoHS > minutoHC) {
			return false;
		} else if (minutoHS < minutoHC) {
			return true;
		} else if (segundoHS > segundoHC) {
			return false;
		} else {
			return true;
		}
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
		throw new Error();
	}
}

export function isHoraSelecionadaMaiorIgualHoraComparacao(horaSelecionada: string, horaComparacao: string): boolean {
	try {
		const horaHS = horaSelecionada.slice(0, 2);
		const minutoHS = horaSelecionada.slice(3, 5);
		const segundoHS = horaSelecionada.slice(6, 8);
		const horaHC = horaComparacao.slice(0, 2);
		const minutoHC = horaComparacao.slice(3, 5);
		const segundoHC = horaComparacao.slice(6, 8);
		if (horaHS < horaHC) {
			return false;
		} else if (horaHS > horaHC) {
			return true;
		} else if (minutoHS < minutoHC) {
			return false;
		} else if (minutoHS > minutoHC) {
			return true;
		} else if (segundoHS < segundoHC) {
			return false;
		} else {
			return true;
		}
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
		throw new Error();
	}
}

export function formatarData(data: string, formatoData = 'DD/MM/YYYY HH:mm'): string {
	return moment(data, 'YYYY-MM-DD hh:mm:ss A Z').format(formatoData);
}

export function formatarDataUtc(data: string, formatoData = 'DD/MM/YYYY'): string {
	return moment.utc(data, 'YYYY-MM-DD').format(formatoData);
}

export function formatarDataToString(dataStr: string, formatoData = 'DD/MM/YYYY HH:mm:ss'): string {
	return moment(dataStr).format(formatoData);
}

export function getDataHoraFormatadaDbByDate(date: string): string {
	return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function getDataHoraFormatadaDbByString(date: string): string {
	const dateFromString = getDateFromString(date, 'DD/MM/YYYY HH:mm:ss');
	return moment(dateFromString).format('YYYY-MM-DD HH:mm:ss.SSS');
}

export function getDataHoraSubtraida(dataFinal: Date, menosDias: number): Date {
	return moment(dataFinal).utcOffset('-03:00').subtract(menosDias, 'days').toDate();
}

export function getDataPrimeiroDiaMes(): Date {
	return moment().utcOffset('-03:00').startOf('month').toDate();
}

export function getDataUltimoDiaMes(): Date {
	return moment().utcOffset('-03:00').endOf('month').toDate();
}

export function getDataUltimoDiaMesEspecifico(mes: number, ano: number, formatoData = 'DD-MM-YYYY'): string {
	return moment().month(mes).year(ano).endOf('month').format(formatoData).toString();
}

export function getQuantidadeDiasEntreDatas(primeiraData: string, segundaData: string, formatoData = 'DD/MM/YYYY'): number {
	const primeira = moment(primeiraData, formatoData);
	const segunda = moment(segundaData, formatoData);
	const dias = moment.duration(primeira.diff(segunda)).asDays();
	return Math.abs(dias);
}

// usar com o q-date e o atributo options do quasar
export function validarDataInicio(data: string): boolean {
	const dataAtual = moment().format('YYYY/MM/DD');
	return data <= dataAtual;
}

export function getDataAtual(formatoData = 'DD/MM/YYYY'): string {
	const data = moment().format(formatoData);
	return data;
}

export function formatarDataSelecionada(dataSelecionada: string): string {
	dataSelecionada = moment(dataSelecionada, 'DD/MM/YYYY').format('YYYY-MM-DD');
	return dataSelecionada;
}

export function formatarDataHora(dataHora: string): string {
	return moment(dataHora, 'YYYY-MM-DD hh:mm:ss A Z').format('DD/MM/YYYY - HH:mm');
}

export function formatarCreatedAt(createdAt: string): string {
	return moment(createdAt, 'YYYY-MM-DD hh:mm:ss A Z').format('DD/MM/YYYY');
}

export function verificarSeDataExiste(dataSelecionada: string): boolean {
	return moment(dataSelecionada, 'DD/MM/YYYY').isValid();
}

export function somarData(dataAlterar: string, diasSomar: string): string {
	return moment(dataAlterar, 'DD-MM-YYYY').add(diasSomar, 'days').format('DD/MM/YYYY');
}

export function subtratirData(dataAlterar: string, diasSubtrair: string): string {
	return moment(dataAlterar, 'DD-MM-YYYY').subtract(diasSubtrair, 'days').format('DD/MM/YYYY');
}

export function verificarDataLength(dataSelecionada: string): boolean {
	const dataInicio = getSomenteNumeros(dataSelecionada);
	if (`${dataInicio}`.length !== 8) {
		return false;
	}
	return true;
}

export function isDataMaiorByHora(data: Date, hours: number): boolean {
	return moment().isAfter(moment(data).add(hours, 'hour'));
}

export function dataSelecionadaMaiorDataComparacao(dataSelecionada: string, dataComparacao: string, maiorIgual = false): boolean {
	dataSelecionada = formatarDataSelecionada(dataSelecionada);
	if (!dataComparacao) {
		dataComparacao = getDataAtual('YYYY-MM-DD');
	} else {
		dataComparacao = formatarDataSelecionada(dataComparacao);
	}
	if (maiorIgual) {
		return moment(dataSelecionada).isSameOrAfter(dataComparacao);
	} else {
		return moment(dataSelecionada).isAfter(dataComparacao);
	}
}

export function dataSelecionadaMenorDataComparacao(dataSelecionada: string, dataComparacao: string, menorIgual = false): boolean {
	if (!dataComparacao || !dataSelecionada) {
		throw new Error('Necessário informar duas datas para comparação.');
	}

	dataSelecionada = formatarDataSelecionada(dataSelecionada);

	dataComparacao = formatarDataSelecionada(dataComparacao);

	if (menorIgual) {
		return moment(dataSelecionada).isSameOrBefore(dataComparacao);
	} else {
		return moment(dataSelecionada).isBefore(dataComparacao);
	}
}

export function isDataSelecionadaMenorDataComparacao(dataSelecionada: Date, dataComparacao: Date): boolean {
	if (!dataComparacao || !dataSelecionada) {
		throw new Error('Necessário informar duas datas para comparação.');
	}
	const dataComparada = moment(dataComparacao).startOf('day');

	return moment(dataSelecionada).isBefore(dataComparada);
}

export function isDataSelecionadaMenorIgualDataComparacao(dataSelecionada: Date, dataComparacao: Date): boolean {
	if (!dataComparacao || !dataSelecionada) {
		throw new Error('Necessário informar duas datas para comparação.');
	}

	return moment(dataSelecionada).isSameOrBefore(dataComparacao);
}

export function isDataSelecionadaMaiorDataComparacao(dataSelecionada: Date, dataComparacao: Date): boolean {
	if (!dataComparacao || !dataSelecionada) {
		throw new Error('Necessário informar duas datas para comparação.');
	}

	return moment(dataSelecionada).isAfter(dataComparacao);
}

export function isDataSelecionadaMaiorIgualDataComparacao(dataSelecionada: Date, dataComparacao: Date): boolean {
	if (!dataComparacao || !dataSelecionada) {
		throw new Error('Necessário informar duas datas para comparação.');
	}

	return moment(dataSelecionada).isSameOrAfter(dataComparacao);
}

export function isDataSelecionadaMenorDataAtual(dataSelecionada: Date): boolean {
	if (!dataSelecionada) {
		throw new Error('Necessário informar data para para comparação.');
	}

	return moment(dataSelecionada).isBefore(moment(new Date()).hours(0));
}

export function isDataSelecionadaMaiorDataAtual(dataSelecionada: Date): boolean {
	if (!dataSelecionada) {
		throw new Error('Necessário informar data para para comparação.');
	}

	return moment(dataSelecionada).isAfter(moment(new Date()));
}

export function desmembrarChave(chaveNfe: string) {
	const anoNota = Number(chaveNfe.substring(2, 2));
	const ano = Number(moment(anoNota, 'YY').format('YYYY'));
	const mes = Number(chaveNfe.substring(4, 2));
	const data = new Date(ano, mes - 1, 1);
	const serie = Number(chaveNfe.substring(22, 3));
	const numero = Number(chaveNfe.substring(25, 9));

	const notaFiscalDesmembrada = {
		serie,
		numero,
		dataEmissao: data
	};

	return notaFiscalDesmembrada;
}
