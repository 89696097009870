import { FornecedorInput } from 'src/modules/inputs/FornecedorInput';
import { Evento } from './Evento';
import { Pessoa, pessoaToInput } from './Pessoa';

export class Fornecedor {
	pessoa: Pessoa;

	transportadora: boolean;

	transportadoraTaxa: number;

	vendaValorMinimo: number;

	prazoMedioEntrega: number;

	prazoMedioPagamento: number;

	parcelamentoMaximo: number;

	eventos: Evento[];

	createdAt: string;

	updatedAt: string;
}

export function fornecedorToInput(fornecedor: Fornecedor): FornecedorInput {
	const converter: any = { ...fornecedor };

	delete converter.pessoa;

	const input: FornecedorInput = { ...converter };

	if (fornecedor.pessoa) {
		input.pessoaId = fornecedor.pessoa.id;
		input.pessoa = pessoaToInput(fornecedor.pessoa);
	}

	return input;
}
