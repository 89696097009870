import { RouteRecordRaw } from 'vue-router';
const routes: RouteRecordRaw[] = [
	{
		path: '/',
		component: () => import('layouts/MyLayout.vue'),
		children: [
			{ path: '', component: () => import('pages/Login.vue') },
			{ path: 'index', component: () => import('pages/Index.vue') },
			{ path: 'pedido', name: 'pedido', component: () => import('pages/Pedido.vue'), props: true }
		]
	},
	{
		path: '/403',
		meta: {
			public: true
		},
		name: 'AccessDenied',
		component: () => import('pages/Error403.vue')
	},
	{
		path: '/:catchAll(.*)*',
		component: () => import('pages/Error404.vue')
	}
];

export default routes;
