import { TipoTelefoneEnum } from '../enum/TipoEnum';
import { TelefoneInput } from '../inputs/TelefoneInput';
import { Evento } from './Evento';
import { Tipo } from './Tipo';

export class Telefone {
	id!: string;

	tipoTelefone!: Tipo;

	ddd!: string;

	numero!: string;

	contato!: string;

	whatsapp!: boolean;

	eventos!: [Evento];

	editando: boolean;

	createdAt!: string;

	updatedAt!: string;
}

export function telefoneToInput(telefone: Telefone): TelefoneInput {
	const converter: any = { ...telefone };

	delete converter.tipoTelefone;
	delete converter.editando;

	const input: TelefoneInput = { ...converter };

	if (telefone.tipoTelefone) input.tipoTelefoneId = telefone.tipoTelefone.id as TipoTelefoneEnum;

	return input;
}
