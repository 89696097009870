import { UsuarioEmpresaInput } from '../inputs/UsuarioEmpresaInput';
import { Empresa } from './Empresa';
import { GrupoUsuario } from './GrupoUsuario';
import { Usuario } from './Usuario';
import { UsuarioEmpresaFuncao, usuarioEmpresaFuncaoToInput } from './UsuarioEmpresaFuncao';

export class UsuarioEmpresa {
	usuario: Usuario;

	empresa: Empresa;

	grupoUsuarios: [GrupoUsuario];

	usuarioEmpresaFuncoes: [UsuarioEmpresaFuncao];

	createdAt: string;

	updatedAt: string;
}

export function usuarioEmpresaToInput(usuarioEmpresa: UsuarioEmpresa): UsuarioEmpresaInput {
	const converter: any = { ...usuarioEmpresa };

	delete converter.usuario;
	delete converter.empresa;

	const input: UsuarioEmpresaInput = { ...converter };

	if (usuarioEmpresa.usuario?.pessoa) input.usuarioId = usuarioEmpresa.usuario.pessoa.id;
	if (usuarioEmpresa.empresa?.pessoa) input.empresaId = usuarioEmpresa.empresa.pessoa.id;

	input.usuarioEmpresaFuncoes = [];

	if (usuarioEmpresa.usuarioEmpresaFuncoes && usuarioEmpresa.usuarioEmpresaFuncoes.length > 0) {
		for (let index = 0; index < usuarioEmpresa.usuarioEmpresaFuncoes.length; index++) {
			const usuarioEmpresaFuncao = usuarioEmpresa.usuarioEmpresaFuncoes[index];
			const usuarioEmpresaFuncaoInput = usuarioEmpresaFuncaoToInput(usuarioEmpresaFuncao);
			input.usuarioEmpresaFuncoes.push(usuarioEmpresaFuncaoInput);
		}
	}

	return input;
}
