import { TipoSedeEnum } from '../enum/TipoEnum';
import { PessoaJuridicaInput } from '../inputs/PessoaJuridicaInput';
import { Evento } from './Evento';
import { Pessoa } from './Pessoa';
import { Tipo } from './Tipo';

export class PessoaJuridica {
	pessoa: Pessoa;

	nomeResponsavel: string;

	cnpj: string;

	ie: string;

	inscricaoMunicipal: string;

	inscricaoSuframa: string;

	atividadePrincipal: string;

	dataInicioAtividade: string;

	numeroSocio: number;

	numeroFuncionario: number;

	faturamento: number;

	tipoSede: Tipo;

	tipoIndicadorIeDest: Tipo;

	tipoRegimeFiscal: Tipo;

	eventos: [Evento];

	createdAt: string;

	updatedAt: string;
}

export function pessoaJuridicaToInput(pessoaJuridica: PessoaJuridica): PessoaJuridicaInput {
	const converter: any = { ...pessoaJuridica };

	delete converter.pessoa;
	delete converter.tipoSede;
	delete converter.tipoIndicadorIeDest;
	delete converter.tipoRegimeFiscal;

	const input: PessoaJuridicaInput = { ...converter };

	if (pessoaJuridica.pessoa) input.pessoaId = pessoaJuridica.pessoa.id;
	if (pessoaJuridica.tipoSede) input.tipoSedeId = pessoaJuridica.tipoSede.id as TipoSedeEnum;
	if (pessoaJuridica.tipoIndicadorIeDest) input.tipoIndicadorIeDestId = pessoaJuridica.tipoIndicadorIeDest.id;
	if (pessoaJuridica.tipoRegimeFiscal) input.tipoRegimeFiscalId = pessoaJuridica.tipoRegimeFiscal.id;

	return input;
}
