import ApiGraphql from './Api';
import { getCurrentInstance } from 'vue';
import { HeadersPros } from 'src/boot/geralService';
import { CREDENCIADORA_TEF_ID } from 'src/util/ConstantesUtil';

export default class CredenciadoraService {
	headers: () => HeadersPros;

	constructor() {
		const app = getCurrentInstance()!;
		this.headers = app?.appContext.config.globalProperties.$headers;
	}

	async getCredenciadoras() {
		try {
			const body = {
				query: `
				query credenciadorasCartoes {
					credenciadorasCartoes(filtro: { where: "credenciadora_cartao.id <> '${CREDENCIADORA_TEF_ID}'" }) {
					  cnpj
					  id
					  nome
					}
				  }
				`
			};
			const result = await ApiGraphql.post('graphql', body, { headers: this.headers() });

			return result.data.credenciadorasCartoes;
		} catch (e) {
			if (process.env.MODE === 'electron') {
				window.windowAPI.logs('error', e);
			}
			throw e;
		}
	}

	async getCredenciadoTef() {
		try {
			const body = {
				query: `
				query credenciadoraCartao {
					credenciadoraCartao(filtro: { id: "${CREDENCIADORA_TEF_ID}" }) {
					  cnpj
					}
				  }

				`
			};
			const result = await ApiGraphql.post('graphql', body, { headers: this.headers() });
			return result.data.credenciadoraCartao;
		} catch (e) {
			if (process.env.MODE === 'electron') {
				window.windowAPI.logs('error', e);
			}
			throw e;
		}
	}
}
