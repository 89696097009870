import { PessoaObservacaoInput } from '../inputs/PessoaObservacaoInput';
import { Pessoa } from './Pessoa';
import { Usuario } from './Usuario';

export class PessoaObservacao {
	id: string;

	pessoa: Pessoa;

	data: string;

	descricao: string;

	usuario: Usuario;

	usuarioNome: string;

	usuarioCodigo: number;

	createdAt: string;

	updatedAt: string;
}

export function pessoaObservacaoToInput(pessoaObservacao: PessoaObservacao): PessoaObservacaoInput {
	const converter: any = { ...pessoaObservacao };

	delete converter.pessoa;
	delete converter.usuario;

	const input: PessoaObservacaoInput = { ...converter };

	if (pessoaObservacao.pessoa) input.pessoaId = pessoaObservacao.pessoa.id;
	if (pessoaObservacao.usuario) input.usuarioId = pessoaObservacao.usuario.pessoa?.id;

	return input;
}
