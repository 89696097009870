import { UsuarioFuncaoInput } from '../inputs/UsuarioFuncaoInput';
import { Empresa } from './Empresa';
import { Tipo } from './Tipo';
import { Usuario } from './Usuario';

export class UsuarioFuncao {
	empresa: Empresa;

	usuario: Usuario;

	tipoFuncao: Tipo;

	createdAt: string;

	updatedAt: string;

	excluido: boolean;
}

export function usuarioFuncaoToInput(usuarioFuncao: UsuarioFuncao): UsuarioFuncaoInput {
	const converter: any = { ...usuarioFuncao };

	delete converter.usuario;
	delete converter.tipoFuncao;
	delete converter.empresa;

	const input: UsuarioFuncaoInput = { ...converter };

	if (usuarioFuncao.usuario) input.usuarioId = usuarioFuncao.usuario.pessoa!.id;
	if (usuarioFuncao.tipoFuncao) input.tipoFuncaoId = usuarioFuncao.tipoFuncao.id;
	if (usuarioFuncao.empresa?.pessoa) input.empresaId = usuarioFuncao.empresa.pessoa.id;

	return input;
}
