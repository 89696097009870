import { isEmptyObject } from 'src/util/AppUtil';
import { objectToQuery } from 'src/util/GraphqlUtil';
import ApiGraphql from './Api';

export default class OperacaoService {
	$vue = null;

	constructor(vue) {
		this.$indexDB = vue.$indexDB;
		this.$store = vue.$store;
		this.$headers = vue.$headers;
		this.$vue = vue;
		this.$getFrenteLoja = vue.$getFrenteLoja;
	}

	async initOperacoesAbertas() {
		const frenteLoja = await this.$vue.$getFrenteLoja();
		if (frenteLoja.terminal.grupoTerminal) {
			const grupoTerminalId = frenteLoja.terminal.grupoTerminal.id;
			const operacoesAbertas = await this.getOperacoesAbertasByGrupoTerminal(grupoTerminalId);
			await this.$store.dispatch('geral/setOperacoes', operacoesAbertas);
		}
	}

	async initOperacao() {
		const operacao = await this.$indexDB.get('operacao');
		const operador = await this.$indexDB.get('operador');
		const grupoEmbalagemRetornavel = await this.$indexDB.get('grupoEmbalagemRetornavel');
		const itemCodigoEmbalagensConsultadas = await this.$indexDB.get('itemCodigoEmbalagensConsultadas');
		if (operacao && !isEmptyObject(operacao)) {
			await this.$store.dispatch('geral/setOperacao', operacao);
			await this.$store.dispatch('geral/setOperacaoCodigo', operacao.codigo);
		}
		if (operador && !isEmptyObject(operador)) {
			await this.$store.dispatch('geral/setOperador', operador);
		}
		if (grupoEmbalagemRetornavel && !isEmptyObject(grupoEmbalagemRetornavel)) {
			await this.$store.dispatch('geral/setGrupoEmbalagemRetornavel', grupoEmbalagemRetornavel);
		}
		if (itemCodigoEmbalagensConsultadas && !isEmptyObject(itemCodigoEmbalagensConsultadas)) {
			await this.$store.dispatch('geral/setItemCodigoEmbalagensConsultadas', itemCodigoEmbalagensConsultadas);
		}
	}

	async inserirOperacao(dados) {
		const body = {
			query: `
			mutation { inserirOperacao (
                    dados: ${objectToQuery(dados)}
            )   {
					${this.retornoOperacao}
				}
            } `
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.inserirOperacao;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async atualizarOperacao(dados) {
		const body = {
			query: `
			mutation { atualizarOperacao (
                    dados: ${objectToQuery(dados)}
            )   {
					${this.retornoOperacao}
				}
            } `
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.atualizarOperacao;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async getValorCaixaDinheiro(operacaoId) {
		const body = {
			query: `
				query { getValorCaixaDinheiro (operacaoId: "${operacaoId}") }
			`
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.getValorCaixaDinheiro;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async getOperacoesDataFechamentoDesc(grupoTerminalId) {
		const body = {
			query: `
				query {
					getOperacoesDataFechamentoDesc(grupoTerminalId: "${grupoTerminalId}") {
						id
						codigo
						valorFechamento
						dataFechamento
					}
				}
			`
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.getOperacoesDataFechamentoDesc;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async getOperacao(operacaoId) {
		const body = {
			query: `
				query { operacao (id: "${operacaoId}")
					{
						${this.retornoOperacao}
					}
				}
			`
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.operacao;
		} catch (e) {
			console.error(e);
			return null;
		}
	}
	async dadosRelatorioFechamento(operacaoId, empresaId) {
		const body = {
			query: `
			query {
				dadosRelatorioFechamento(
				  operacaoId: "${operacaoId}"
				  empresaId: "${empresaId}"
				) {
				  dataAbertura
				  dataFechamento
				  pdv
				  operacaoCodigo
				  operadores
				  supervisorAbertura
				  supervisorFechamento
				  fundoAbertura {
					quantidade
					subtotal
				  }
				  entradas {
					quantidade
					subtotal
				  }
				  vendas {
					quantidade
					subtotal
				  }
				  titulosRecebidos {
					quantidade
					subtotal
				  }
				  fretes {
					quantidade
					subtotal
				  }
				  valesEmitidos {
					quantidade
					subtotal
				  }
				  valesUtilizados {
					quantidade
					subtotal
				  }
				  fundoFechamento {
					quantidade
					subtotal
				  }
				  retiradas {
					quantidade
					subtotal
				  }
				  titulosPagos {
					quantidade
					subtotal
				  }
				  trocoFinalizadora {
					quantidade
					subtotal
				  }
				  formaPagamentos {
					id
					nome
					registradoPdv
					lancadoOperador
					diferenca
				  }
				  totalGeral {
					nome
					registradoPdv
					lancadoOperador
					diferenca
				  }
				}
			  }
			`
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.dadosRelatorioFechamento;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async dadosTotalVendasFormaPagamento(operacaoId, empresaId) {
		const body = {
			query: `
			query {
				dadosRelatorioFechamento(
				  operacaoId: "${operacaoId}"
				  empresaId: "${empresaId}"
				) {
				  formaPagamentos {
					id
					registradoPdv
				  }
				}
			  }
			`
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.dadosRelatorioFechamento;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async getOperacoes(filtro = undefined) {
		let where = '';
		if (filtro) {
			if (filtro.where) {
				where = `where: "${filtro.where}"`;
			}
		}
		const body = {
			query: `
				query { operacoes ( filtro: {
					orderBy:["created_at"]
					${where}
				})	{
					${this.retornoOperacao}
					}
				}
			`
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.operacoes;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async getOperacoesAbertas() {
		const frenteLoja = await this.$getFrenteLoja();
		const body = {
			query: `
				query { operacoes ( filtro: {
					orderBy:["created_at"]
					tipoStatusId: OPERACAO_ABERTA
					empresaId: "${frenteLoja.empresa.pessoa.id}"
				}) {
					id
					tipoStatus { id }
				}}`
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.operacoes;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async getOperacoesAbertasByGrupoTerminal(grupoTerminalId) {
		const body = {
			query: `
				query { getOperacoesAbertasByGrupoTerminal(grupoTerminalId: "${grupoTerminalId}")
					{
						${this.retornoOperacao}
					}
				}
			`
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.getOperacoesAbertasByGrupoTerminal;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async inserirOperacaoMovimentacao(dados) {
		const body = {
			query: `
			mutation { inserirOperacaoMovimentacao (
                    dados: ${objectToQuery(dados)}
            )   {
					id
					valor
					contador
					tipoMovimentacao {
					  nome
					}
					usuarioSupervisorNome
					usuarioSupervisorCodigo
					dataLancamento
					formaPagamento {
					  tipoFormaPagamento {
						nome
					  }
					}
				}
            } `
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.inserirOperacaoMovimentacao;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	async atualizarOperacaoTerminalEntradaSaida(dados) {
		const body = {
			query: `
			mutation { atualizarOperacaoTerminalEntradaSaida (
                    dados: ${objectToQuery(dados)}
            )   {
					id
					operacaoTerminal {
						id
					}
					horarioEntrada
					horarioSaida
				}
            } `
		};

		try {
			const result = await ApiGraphql.post('graphql', body, {
				headers: this.$headers()
			});

			return result.data.atualizarOperacaoTerminalEntradaSaida;
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	retornoOperacao = `
		id
		codigo
		contadorSangria
		contadorSuprimento
		empresa {
			pessoa {
				id
				nome
			}
		}
		grupoTerminal {
			id
			nome
		}
		dataAbertura
		dataFechamento
		tipoStatus {
			id
			nome
		}
		usuarioSupervisorAbertura {
			pessoa {
				id
				nome
			}
			codigo
		}
		usuarioSupervisorAberturaCodigo
		usuarioSupervisorAberturaNome
		usuarioSupervisorFechamento {
			pessoa {
				id
				nome
			}
		}
		usuarioSupervisorFechamentoCodigo
		usuarioSupervisorFechamentoNome
		valorAbertura
		valorFechamento
		terminalAbertura {
			id
			nome
		}
		terminalFechamento {
			id
			nome
		}
		operacaoMovimentacoes {
			id
			formaPagamento {
				id
				nome
				tipoFormaPagamento {
				id
				}
			}
			valor
			dataLancamento
			tipoMovimentacao {
				id
				nome
			}

			tipoMovimentacaoLancamento {
				id
				nome
			}
			usuarioSupervisor {
				pessoa {
				id
				nome
				}
			}
		}
		operacaoTerminais {
			id
			terminal {
				id
				nome
			}
			operacao {
				id
				codigo
			}
			usuarioOperador {
				pessoa {
				id
				nome
				}
			}
			operacaoTerminalEntradaSaida {
				id
				operacaoTerminal {
				  id
				}
				horarioEntrada
				horarioSaida
			}
		}
		createdAt
		updatedAt
	`;
}
