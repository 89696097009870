import { PessoaContaInput } from '../inputs/PessoaContaInput';
import { Conta, contaToInput } from './Conta';
import { Pessoa, pessoaToInput } from './Pessoa';

export class PessoaConta {
	pessoa: Pessoa;

	conta: Conta;

	createdAt: string;

	updatedAt: string;
}

export function pessoaContaToInput(pessoaConta: PessoaConta): PessoaContaInput {
	const converter: any = { ...pessoaConta };

	delete converter.pessoa;
	delete converter.conta;

	const input: PessoaContaInput = { ...converter };

	// if (pessoaConta.conta) input.contaId = pessoaConta.conta.id;

	if (pessoaConta.pessoa) {
		input.pessoa = pessoaToInput(pessoaConta.pessoa);
	}

	if (pessoaConta.conta) {
		input.conta = contaToInput(pessoaConta.conta);
	}

	return input;
}
