import { TipoPessoaVinculoEnum, TipoPessoaVinculoStatusEnum } from '../enum/TipoEnum';
import { PessoaVinculoInput } from '../inputs/PessoaVinculoInput';
import { Pessoa } from './Pessoa';
import { Tipo } from './Tipo';

export class PessoaVinculo {
	id: string;

	pessoa: Pessoa;

	nomeVinculo: string;

	cpf: string;

	rg: string;

	limiteValor: number;

	limiteData: string;

	tipoStatus: Tipo;

	tipoVinculo: Tipo;

	createdAt: string;

	updatedAt: string;
}

export function pessoaVinculoToInput(pessoaVinculo: PessoaVinculo): PessoaVinculoInput {
	const converter: any = { ...pessoaVinculo };

	delete converter.pessoa;
	delete converter.tipoStatus;
	delete converter.tipoVinculo;

	const input: PessoaVinculoInput = { ...converter };

	if (pessoaVinculo.pessoa) input.pessoaId = pessoaVinculo.pessoa.id;
	if (pessoaVinculo.tipoStatus) input.tipoStatusId = pessoaVinculo.tipoStatus.id as TipoPessoaVinculoStatusEnum;
	if (pessoaVinculo.tipoVinculo) input.tipoVinculoId = pessoaVinculo.tipoVinculo.id as TipoPessoaVinculoEnum;

	return input;
}
