import { TipoResidenciaEnum, TipoStatusClienteEnum } from '../enum/TipoEnum';
import { ClienteInput } from '../inputs/ClienteInput';
import { Colaborador, colaboradorToInput } from './Colaborador';
import { Evento } from './Evento';
import { Fornecedor, fornecedorToInput } from './Fornecedor';
import { Pessoa, pessoaToInput } from './Pessoa';
import { PessoaFormaPagamentoParcela, pessoaFormaPagamentoParcelaToInput } from './PessoaFormaPagamentoParcela';
import { TabelaVenda } from './TabelaVenda';
import { Tipo } from './Tipo';

export class Cliente {
	pessoa: Pessoa;

	tabelaVendaPadrao: TabelaVenda;

	valorTotalAbc: number;

	classificacaoAbc: string;

	tipoStatus: Tipo;

	pessoaRepresentante: Pessoa;

	taxaEntrega: number;

	revenda: boolean;

	exibirMensagem: boolean;

	tipoResidencia: Tipo;

	residenciaDataEntrada: string;

	dependenteNumero: number;

	profissao: string;

	trabalhoEmpresa: string;

	trabalhoCargo: string;

	trabalhoDataAdmicao: string;

	trabalhoSalario: number;

	outrasRendas: number;

	codigoCartaoFidelidade: string;

	usuarioVendedorPadrao: Colaborador;

	fornecedorTransportadoraPadrao: Fornecedor;

	eventos: Evento[];

	conjugueExcluido: boolean;

	createdAt: string;

	updatedAt: string;

	pessoaFormaPagamentoParcelas: PessoaFormaPagamentoParcela[];
}

export function clienteToInput(cliente: Cliente): ClienteInput {
	const converter: any = { ...cliente };

	delete converter.tabelaVendaPadrao;
	delete converter.pessoaRepresentanteId;
	delete converter.tipoStatus;
	delete converter.tipoResidencia;
	delete converter.pessoaFormaPagamentoParcelas;

	const input: ClienteInput = { ...converter };

	if (cliente.pessoa) {
		input.pessoa = pessoaToInput(cliente.pessoa);
	}
	if (cliente.usuarioVendedorPadrao) {
		input.usuarioVendedorPadrao = colaboradorToInput(cliente.usuarioVendedorPadrao);
	}
	if (cliente.fornecedorTransportadoraPadrao) {
		input.fornecedorTransportadoraPadrao = fornecedorToInput(cliente.fornecedorTransportadoraPadrao);
	}

	if (cliente.tabelaVendaPadrao?.id) input.tabelaVendaPadraoId = cliente.tabelaVendaPadrao.id;
	if (cliente.pessoaRepresentante) input.pessoaRepresentanteId = cliente.pessoaRepresentante.id;
	if (cliente.tipoStatus) input.tipoStatusId = cliente.tipoStatus.id as TipoStatusClienteEnum;
	if (cliente.tipoResidencia) input.tipoResidenciaId = cliente.tipoResidencia.id as TipoResidenciaEnum;

	if (cliente.pessoaFormaPagamentoParcelas && cliente.pessoaFormaPagamentoParcelas.length > 0) {
		input.pessoaFormaPagamentoParcelas = [];
		for (let index = 0; index < cliente.pessoaFormaPagamentoParcelas.length; index++) {
			const pfpp = cliente.pessoaFormaPagamentoParcelas[index];
			const pfppInput = pessoaFormaPagamentoParcelaToInput(pfpp);
			input.pessoaFormaPagamentoParcelas.push(pfppInput);
		}
	}

	return input;
}
