import { TipoEnderecoEnum } from '../enum/TipoEnum';
import { EnderecoInput } from '../inputs/EnderecoInput';
import { Evento } from './Evento';
import { Tipo } from './Tipo';

export class Endereco {
	id!: string;

	logradouro!: string;

	numero!: number;

	complemento!: string;

	proximidade!: string;

	tipoEndereco!: Tipo;

	bairro!: string;

	cidade!: string;

	uf!: string;

	cep!: string;

	pais!: string;

	latitude!: number;

	longitude!: number;

	codigoPais!: number;

	codigoIbgeUf!: number;

	codigoIbgeCidade!: number;

	cepValido!: boolean;

	geolocalizacaoValida!: boolean;

	eventos!: Evento[];

	createdAt!: string;

	updatedAt!: string;
}

export function enderecoToInput(endereco: Endereco): EnderecoInput {
	const converter: any = { ...endereco };

	delete converter.tipoEndereco;

	const input: EnderecoInput = { ...converter };

	if (endereco.tipoEndereco) input.tipoEnderecoId = endereco.tipoEndereco.id as TipoEnderecoEnum;

	return input;
}
