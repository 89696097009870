import { TipoPessoaReferenciaEnum } from '../enum/TipoEnum';
import { PessoaReferenciaInput } from '../inputs/PessoaReferenciaInput';
import { Pessoa } from './Pessoa';
import { Telefone, telefoneToInput } from './Telefone';
import { Tipo } from './Tipo';

export class PessoaReferencia {
	id!: string;

	pessoa!: Pessoa;

	tipoReferencia!: Tipo;

	nome!: string;

	telefone!: Telefone;

	complemento!: string;

	createdAt!: string;

	updatedAt!: string;
}

export function pessoaReferenciaToInput(pessoaReferencia: PessoaReferencia): PessoaReferenciaInput {
	const converter: any = { ...pessoaReferencia };

	delete converter.pessoa;
	delete converter.tipoReferencia;
	delete converter.telefone;

	const input: PessoaReferenciaInput = { ...converter };

	if (pessoaReferencia.pessoa) input.pessoaId = pessoaReferencia.pessoa.id;
	if (pessoaReferencia.tipoReferencia) input.tipoReferenciaId = pessoaReferencia.tipoReferencia.id as TipoPessoaReferenciaEnum;

	if (pessoaReferencia.telefone) {
		input.telefone = telefoneToInput(pessoaReferencia.telefone);
	}

	return input;
}
