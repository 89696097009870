import { Empresa } from './Empresa';
import { FormaPagamento } from './FormaPagamento';
import { TabelaVenda } from './TabelaVenda';
import { Terminal } from './Terminal';
import { Tipo } from './Tipo';

export class FrenteLoja {
	empresa: Empresa;

	terminal: Terminal;

	tipos: Tipo[];

	tiposMap: Map<string, Tipo>;

	tipoGrupos: any;

	tabelasVenda: TabelaVenda[];

	formasPagamento: FormaPagamento[];

	empresaConfiguracoes: any;

	empresaId: string;

	descricao: string;

	tipoPedidoId: string;

	cancelado: boolean;

	terminalId: string;

	dataLancamento: string;

	dataSaida: string;

	frete: number;

	taxa: number;

	acrescimo: number;

	desconto: number;

	valorBruto: number;

	agendado: boolean;

	editando: boolean;

	quantidadePessoaVenda: number;

	pedidoTitulos: [];

	pedidoItens: [];

	pedidoPessoa: undefined;
}
