import ApiGraphql from './Api';
import { getCurrentInstance } from 'vue';
import { VERSAO_SISTEMA } from 'src/util/ConstantesUtil';

export default class TerminalVersaoPdvService {
	headers: any;

	constructor() {
		const app = getCurrentInstance();
		this.headers = app?.appContext.config.globalProperties.$headers;
	}

	async inserirTerminalVersaoPdv(terminalId: string): Promise<any> {
		try {
			const body = {
				query: `
				mutation {
					inserirTerminalVersaoPdv(dados: {
					  sistemaId: "TOTEM2_FRENTE_LOJA"
					  terminalId: "${terminalId}"
					  versaoPdv: "${VERSAO_SISTEMA}"
					}) {
					  versaoPdv
					}
				  }`
			};
			if (terminalId) {
				const result = await ApiGraphql.post('graphql', body, { headers: this.headers() });

				return result.data.inserirTerminalVersaoPdv;
			} else {
				return null;
			}
		} catch (e) {
			console.error(e);
			throw e;
		}
	}
	async atualizarTerminalVersaoPdv(terminalId: string): Promise<any> {
		try {
			const body = {
				query: `
				mutation {
					atualizarTerminalVersaoPdv(
					  dados: {
						sistemaId: "TOTEM2_FRENTE_LOJA"
						terminalId: "${terminalId}"
						versaoPdv: "${VERSAO_SISTEMA}"
					  }
					) {
					  versaoPdv
					}
				  }
				  `
			};
			if (terminalId) {
				const result = await ApiGraphql.post('graphql', body, { headers: this.headers() });

				return result.data.atualizarTerminalVersaoPdv;
			} else {
				return null;
			}
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	async getTerminalVersaoPdv(terminalId: string): Promise<any> {
		try {
			const body = {
				query: `
				query {
					terminalVersaoPdv(
					  terminalId: "${terminalId}"
					  sistemaId: "TOTEM2_FRENTE_LOJA"
					) {
					  versaoPdv
					}
				  }
				  `
			};
			if (terminalId) {
				const result = await ApiGraphql.post('graphql', body, { headers: this.headers() });

				return result.data.terminalVersaoPdv;
			} else {
				return null;
			}
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	async retornarVersaoApp() {
		try {
			const body = {
				query: `
					query  {
						sistema(id: "TOTEM2_FRENTE_LOJA") {
						  versao
						}
					  }
					`
			};
			const result = await ApiGraphql.post('graphql', body, { headers: this.headers() });
			return result.data.sistema;
		} catch (e) {
			if (process.env.MODE === 'electron') {
				window.windowAPI.logs('error', e);
			}
			throw e;
		}
	}
}
