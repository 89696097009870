import { PessoaLimiteCreditoInput } from '../inputs/PessoaLimiteCreditoInput';
import { FormaPagamento } from './FormaPagamento';
import { Pessoa } from './Pessoa';

export class PessoaLimiteCredito {
	id: string;

	pessoa: Pessoa;

	formaPagamento: FormaPagamento;

	limiteValor: number;

	valorUtilizado: number;

	prazoEntreParcelas: number;

	numeroParcelas: number;

	createdAt: string;

	updatedAt: string;
}

export function pessoaLimiteCreditoToInput(pessoaLimiteCredito: PessoaLimiteCredito): PessoaLimiteCreditoInput {
	const converter: any = { ...pessoaLimiteCredito };

	delete converter.pessoa;
	delete converter.formaPagamento;

	const input: PessoaLimiteCreditoInput = { ...converter };

	if (pessoaLimiteCredito.pessoa) input.pessoaId = pessoaLimiteCredito.pessoa.id;
	if (pessoaLimiteCredito.formaPagamento?.id) input.formaPagamentoId = pessoaLimiteCredito.formaPagamento.id;

	return input;
}
