import { TipoEstadoCivilEnum, TipoSexoEnum } from '../enum/TipoEnum';
import { PessoaFisicaInput } from '../inputs/PessoaFisicaInput';
import { Cliente, clienteToInput } from './Cliente';
import { Evento } from './Evento';
import { Pessoa } from './Pessoa';
import { Tipo } from './Tipo';

export class PessoaFisica {
	pessoa: Pessoa;

	numeroDocumentoEstrangeiro: string;

	cpf: string;

	rg: string;

	rgOrgao: string;

	rgEmissao: string;

	tipoSexo: Tipo;

	tipoEstadoCivil: Tipo;

	dataNascimento: string;

	naturalidadeCidade: string;

	naturalidadeUf: string;

	naturalidadePais: string;

	nomePai: string;

	nomeMae: string;

	ie: string;

	numeroPis: string;

	conjuge: Cliente;

	eventos: [Evento];

	createdAt: string;

	updatedAt: string;
}

export function pessoaFisicaToInput(pessoaFisica: PessoaFisica): PessoaFisicaInput {
	const converter: any = { ...pessoaFisica };

	delete converter.pessoa;
	delete converter.tipoSexo;
	delete converter.tipoEstadoCivil;
	delete converter.conjuge;

	const input: PessoaFisicaInput = { ...converter };

	if (pessoaFisica.pessoa) input.pessoaId = pessoaFisica.pessoa.id;
	if (pessoaFisica.tipoSexo) input.tipoSexoId = pessoaFisica.tipoSexo.id as TipoSexoEnum;
	if (pessoaFisica.tipoEstadoCivil) input.tipoEstadoCivilId = pessoaFisica.tipoEstadoCivil.id as TipoEstadoCivilEnum;

	if (pessoaFisica.conjuge) {
		input.conjuge = clienteToInput(pessoaFisica.conjuge);
	}

	return input;
}
