import {
	TipoAceiteIntegracaoEnum,
	TipoBaixaIntegracaoEnum,
	TipoConvenioCarteiraCodigoEnum,
	TipoDescontoIntegracaoEnum,
	TipoEmissaoIntegracaoEnum,
	TipoJurosIntegracaoEnum,
	TipoMultaIntegracaoEnum,
	TipoPostagemIntegracaoEnum,
	TipoProtestoIntegracaoEnum,
	TipoRegistroIntegracaoEnum
} from '../enum/TipoEnum';
import { BoletoConfiguracaoIntegracaoInput } from '../inputs/BoletoConfiguracaoIntegracaoInput';
import { Empresa } from './Empresa';
import { Tipo } from './Tipo';

export class BoletoConfiguracaoIntegracao {
	id: string;
	contaId: string;
	empresa: Empresa;
	empresaId: string;
	idIntegracao: number;
	convenioNumero: string;
	convenioDescricao: string;
	convenioCarteira: string;
	convenioCodigoCarteira: string;
	convenioEspecie: string;
	convenioPadraoCnab: string;
	tipoConvenioCarteiraCodigo!: Tipo;
	convenioReiniciarDiariamente: boolean;
	convenioNumeroRemessa: string;
	idContaIntegracao: number;
	convenioDensidaDeRemessa: number;
	convenioRegistroInstantaneo: boolean;
	convenioApiId: string;
	convenioApiKey: string;
	convenioApiSecret: string;
	convenioEstacao: string;
	convenioNossoNumeroBanco: boolean;
	convenioTipoWebservice: string;
	convenioNumeroContrato: string;
	excluir: boolean;
	editando: boolean;
	novo: boolean;
	tipoTituloCodigoDesconto!: Tipo;
	tipoTituloAceite!: Tipo;
	tipoTituloCodigoJuros!: Tipo;
	tipoTituloCodigoMulta!: Tipo;
	tipoCodigoProtesto!: Tipo;
	tipoCodigoBaixaDevolucao!: Tipo;
	tipoTituloEmissaoBoleto!: Tipo;
	tipoTituloCategoria!: Tipo;
	tipoTituloPostagemBoleto!: Tipo;
	tituloLocalPagamento: string;
	tituloPrazoDesconto: number;
	tituloValorDesconto: number;
	tituloPrazoJuros: number;
	tituloValorJuros: number;
	tituloPrazoMulta: number;
	tituloValorMulta: number;
	tituloPrazoProtesto: number;
	tituloPrazoBaixa: number;
	tituloMensagem01: string;
	tituloMensagem02: string;
	tituloOutrosAcrescimos: number;
	tituloInformacoesAdicionais: string;
	tituloInstrucoes: string;
	tituloParcela: string;
	tituloPrazoLimite: number;
	createdAt: string;
	updatedAt: string;
}

export function boletoConfiguracaoToInput(boletoConfiguracaoIntegracao: BoletoConfiguracaoIntegracao): BoletoConfiguracaoIntegracaoInput {
	const converter: any = { ...boletoConfiguracaoIntegracao };

	delete converter.tipoTituloCodigoDescontoId;
	delete converter.tipoTituloAceiteId;
	delete converter.tipoTituloCodigoJurosId;
	delete converter.tipoTituloCodigoMultaId;
	delete converter.tipoCodigoProtestoId;
	delete converter.tipoCodigoBaixaDevolucaoId;
	delete converter.tipoTituloEmissaoBoletoId;
	delete converter.tipoTituloCategoriaId;
	delete converter.tipoTituloPostagemBoletoId;
	delete converter.tipoConvenioCarteiraCodigoId;
	delete converter.empresa;
	delete converter.editando;
	delete converter.novo;
	delete converter.createdAt;
	delete converter.updatedAt;

	const input: BoletoConfiguracaoIntegracaoInput = { ...converter };

	if (boletoConfiguracaoIntegracao.tipoConvenioCarteiraCodigo)
		input.tipoConvenioCarteiraCodigo = boletoConfiguracaoIntegracao.tipoConvenioCarteiraCodigo.id as TipoConvenioCarteiraCodigoEnum;

	if (boletoConfiguracaoIntegracao.tipoTituloCodigoDesconto)
		input.tipoTituloCodigoDesconto = boletoConfiguracaoIntegracao.tipoTituloCodigoDesconto.id as TipoDescontoIntegracaoEnum;

	if (boletoConfiguracaoIntegracao.tipoTituloAceite)
		input.tipoTituloAceite = boletoConfiguracaoIntegracao.tipoTituloAceite.id as TipoAceiteIntegracaoEnum;

	if (boletoConfiguracaoIntegracao.tipoTituloCodigoJuros)
		input.tipoTituloCodigoJuros = boletoConfiguracaoIntegracao.tipoTituloCodigoJuros.id as TipoJurosIntegracaoEnum;

	if (boletoConfiguracaoIntegracao.tipoTituloCodigoMulta)
		input.tipoTituloCodigoMulta = boletoConfiguracaoIntegracao.tipoTituloCodigoMulta.id as TipoMultaIntegracaoEnum;

	if (boletoConfiguracaoIntegracao.tipoCodigoProtesto)
		input.tipoCodigoProtesto = boletoConfiguracaoIntegracao.tipoCodigoProtesto.id as TipoProtestoIntegracaoEnum;

	if (boletoConfiguracaoIntegracao.tipoCodigoBaixaDevolucao)
		input.tipoCodigoBaixaDevolucao = boletoConfiguracaoIntegracao.tipoCodigoBaixaDevolucao.id as TipoBaixaIntegracaoEnum;

	if (boletoConfiguracaoIntegracao.tipoTituloEmissaoBoleto)
		input.tipoTituloEmissaoBoleto = boletoConfiguracaoIntegracao.tipoTituloEmissaoBoleto.id as TipoEmissaoIntegracaoEnum;

	if (boletoConfiguracaoIntegracao.tipoTituloCategoria)
		input.tipoTituloCategoria = boletoConfiguracaoIntegracao.tipoTituloCategoria.id as TipoRegistroIntegracaoEnum;

	if (boletoConfiguracaoIntegracao.tipoTituloPostagemBoleto)
		input.tipoTituloPostagemBoleto = boletoConfiguracaoIntegracao.tipoTituloPostagemBoleto.id as TipoPostagemIntegracaoEnum;

	if (boletoConfiguracaoIntegracao?.empresa?.pessoa) input.empresaId = boletoConfiguracaoIntegracao.empresa.pessoa.id;

	return input;
}
