import { PessoaEnderecoInput } from '../inputs/PessoaEnderecoInput';
import { Endereco } from './Endereco';
import { Evento } from './Evento';
import { Pessoa } from './Pessoa';

export class PessoaEndereco {
	pessoa: Pessoa;

	endereco: Endereco;

	eventos: [Evento];

	createdAt: string;

	updatedAt: string;

	valorFretePadrao: number;
}

export function pessoaEnderecoToInput(pessoaEndereco: PessoaEndereco): PessoaEnderecoInput {
	const converter: any = { ...pessoaEndereco };

	delete converter.pessoa;

	const input: PessoaEnderecoInput = { ...converter };

	if (pessoaEndereco.pessoa) input.pessoaId = pessoaEndereco.pessoa.id;

	return input;
}
