import { PessoaTelefoneInput } from '../inputs/PessoaTelefoneInput';
import { Pessoa } from './Pessoa';
import { Telefone, telefoneToInput } from './Telefone';

export class PessoaTelefone {
	pessoa: Pessoa;

	telefone: Telefone;

	principal: boolean;

	createdAt: string;

	updatedAt: string;
}

export function pessoaTelefoneToInput(pessoaTelefone: PessoaTelefone): PessoaTelefoneInput {
	const converter: any = { ...pessoaTelefone };

	delete converter.pessoa;
	delete converter.telefone;

	const input: PessoaTelefoneInput = { ...converter };

	if (pessoaTelefone.pessoa) input.pessoaId = pessoaTelefone.pessoa.id;

	if (pessoaTelefone.telefone) {
		input.telefone = telefoneToInput(pessoaTelefone.telefone);
	}

	return input;
}
