export enum TipoUnidadeEnum {
	AMPOLA = 'AMPOLA',
	BALDE = 'BALDE',
	BANDEJ = 'BANDEJ',
	BARRA = 'BARRA',
	BISNAG = 'BISNAG',
	BLOCO = 'BLOCO',
	BOBINA = 'BOBINA',
	BOMB = 'BOMB',
	CAPS = 'CAPS',
	CART = 'CART',
	CENTO = 'CENTO',
	CJ = 'CJ',
	CM = 'CM',
	CM2 = 'CM2',
	CX = 'CX',
	CX2 = 'CX2',
	CX3 = 'CX3',
	CX5 = 'CX5',
	CX10 = 'CX10',
	CX15 = 'CX15',
	CX20 = 'CX20',
	CX25 = 'CX25',
	CX50 = 'CX50',
	CX100 = 'CX100',
	DISP = 'DISP',
	DUZIA = 'DUZIA',
	EMBAL = 'EMBAL',
	FARDO = 'FARDO',
	FOLHA = 'FOLHA',
	FRASCO = 'FRASCO',
	GALAO = 'GALAO',
	GF = 'GF',
	G = 'G',
	JOGO = 'JOGO',
	KG = 'KG',
	KIT = 'KIT',
	LATA = 'LATA',
	LT = 'LT',
	METRO = 'METRO',
	M2 = 'M2',
	M3 = 'M3',
	MILHEI = 'MILHEI',
	ML = 'ML',
	MWH = 'MWH',
	PACOTE = 'PACOTE',
	PALETE = 'PALETE',
	PARES = 'PARES',
	PC = 'PC',
	POTE = 'POTE',
	QUILAT = 'QUILAT',
	RESMA = 'RESMA',
	ROLO = 'ROLO',
	SACO = 'SACO',
	SACOLA = 'SACOLA',
	TAMBOR = 'TAMBOR',
	TANQUE = 'TANQUE',
	TON = 'TON',
	TUBO = 'TUBO',
	UN = 'UN',
	VASIL = 'VASIL',
	VIDRO = 'VIDRO'
}

export enum TipoPortaEnum {
	PORTA_COM1 = 'PORTA_COM1',
	PORTA_COM2 = 'PORTA_COM2',
	PORTA_COM3 = 'PORTA_COM3',
	PORTA_COM4 = 'PORTA_COM4',
	PORTA_COM5 = 'PORTA_COM5',
	PORTA_COM6 = 'PORTA_COM6',
	PORTA_COM7 = 'PORTA_COM7',
	PORTA_COM8 = 'PORTA_COM8',
	PORTA_LPT1 = 'PORTA_LPT1',
	PORTA_LPT2 = 'PORTA_LPT2',
	PORTA_LPT3 = 'PORTA_LPT3',
	PORTA_LPT4 = 'PORTA_LPT4',
	PORTA_USB = 'PORTA_USB',
	PORTA_IP = 'PORTA_IP',
	PORTA_AT = 'PORTA_AT',
	PORTA_S0 = 'PORTA_S0',
	PORTA_S1 = 'PORTA_S1',
	PORTA_S2 = 'PORTA_S2',
	PORTA_S3 = 'PORTA_S3',
	PORTA_S4 = 'PORTA_S4',
	PORTA_S5 = 'PORTA_S5',
	PORTA_S6 = 'PORTA_S6',
	PORTA_S7 = 'PORTA_S7',
	PORTA_ACM0 = 'PORTA_ACM0'
}

export enum TipoFormaPagamentoEnum {
	CHEQUE = 'CHEQUE',
	DINHEIRO = 'DINHEIRO',
	CARTAO_DEBITO = 'CARTAO_DEBITO',
	CARTAO_CREDITO = 'CARTAO_CREDITO',
	CONVENIO = 'CONVENIO',
	BOLETO = 'BOLETO',
	OPERACAO_BANCARIA = 'OPERACAO_BANCARIA',
	VALE_COMPRA = 'VALE_COMPRA',
	CORTESIA = 'CORTESIA',
	CARTAO_ALIMENTACAO = 'CARTAO_ALIMENTACAO',
	CARTAO_REFEICAO = 'CARTAO_REFEICAO',
	CARTEIRA_DIGITAL = 'CARTEIRA_DIGITAL'
}

export enum TipoDiaSemanaEnum {
	DIA_SEMANA_DOMINGO = 'DIA_SEMANA_DOMINGO',
	DIA_SEMANA_SEGUNDA = 'DIA_SEMANA_SEGUNDA',
	DIA_SEMANA_TERCA = 'DIA_SEMANA_TERCA',
	DIA_SEMANA_QUARTA = 'DIA_SEMANA_QUARTA',
	DIA_SEMANA_QUINTA = 'DIA_SEMANA_QUINTA',
	DIA_SEMANA_SEXTA = 'DIA_SEMANA_SEXTA',
	DIA_SEMANA_SABADO = 'DIA_SEMANA_SABADO'
}

export enum TipoItemEnum {
	ITEM_MERCADORIA_REVENDA = 'ITEM_MERCADORIA_REVENDA',
	ITEM_MATERIA_PRIMA = 'ITEM_MATERIA_PRIMA',
	ITEM_EMBALAGEM = 'ITEM_EMBALAGEM',
	ITEM_PROD_PROCESSO = 'ITEM_PROD_PROCESSO',
	ITEM_PROD_ACABADO = 'ITEM_PROD_ACABADO',
	ITEM_SUBPRODUTO = 'ITEM_SUBPRODUTO',
	ITEM_PROD_INTERMEDIARIO = 'ITEM_PROD_INTERMEDIARIO',
	ITEM_USO_CONSUMO = 'ITEM_USO_CONSUMO',
	ITEM_ATIVO_IMOBILIZADO = 'ITEM_ATIVO_IMOBILIZADO',
	ITEM_SERVICOS = 'ITEM_SERVICOS',
	ITEM_INSUMOS = 'ITEM_INSUMOS',
	ITEM_OUTRAS = 'ITEM_OUTRAS'
}

export enum TipoOrigemMercadoriaEnum {
	ORIGEM_0_NACIONAL = 'ORIGEM_0_NACIONAL',
	ORIGEM_1_ESTRANGEIRA = 'ORIGEM_1_ESTRANGEIRA',
	ORIGEM_2_ESTRANGEIRA = 'ORIGEM_2_ESTRANGEIRA',
	ORIGEM_3_NACIONAL = 'ORIGEM_3_NACIONAL',
	ORIGEM_4_NACIONAL = 'ORIGEM_4_NACIONAL',
	ORIGEM_5_NACIONAL = 'ORIGEM_5_NACIONAL',
	ORIGEM_6_ESTRANGEIRA = 'ORIGEM_6_ESTRANGEIRA',
	ORIGEM_7_ESTRANGEIRA = 'ORIGEM_7_ESTRANGEIRA',
	ORIGEM_8_NACIONAL = 'ORIGEM_8_NACIONAL'
}

export enum TipoAliquotaIcmsEnum {
	ALIQUOTA_ISENTO = 'ALIQUOTA_ISENTO',
	ALIQUOTA_PRIMEIRA = 'ALIQUOTA_PRIMEIRA',
	ALIQUOTA_SEGUNDA = 'ALIQUOTA_SEGUNDA',
	ALIQUOTA_TERCEIRA = 'ALIQUOTA_TERCEIRA',
	ALIQUOTA_QUARTA = 'ALIQUOTA_QUARTA',
	ALIQUOTA_QUINTA = 'ALIQUOTA_QUINTA',
	ALIQUOTA_SEXTA = 'ALIQUOTA_SEXTA',
	ALIQUOTA_SETIMA = 'ALIQUOTA_SETIMA',
	ALIQUOTA_OITAVA = 'ALIQUOTA_OITAVA',
	ALIQUOTA_INTERNA = 'ALIQUOTA_INTERNA',
	ALIQUOTA_NAO_CONTRIBUINTE = 'ALIQUOTA_NAO_CONTRIBUINTE'
}

export enum TipoRegimeFiscalEnum {
	SIMPLES_NACIONAL = 'SIMPLES_NACIONAL',
	SIMPLES_NACIONAL_EPP = 'SIMPLES_NACIONAL_EPP',
	LUCRO_PRESUMIDO = 'LUCRO_PRESUMIDO',
	LUCRO_REAL = 'LUCRO_REAL'
}

export enum TipoSituacaoTributariaEnum {
	// Tipos para empresa simples nacional
	CSOSN_ICMS_101 = 'CSOSN_ICMS_101',
	CSOSN_ICMS_102 = 'CSOSN_ICMS_102',
	CSOSN_ICMS_103 = 'CSOSN_ICMS_103',
	CSOSN_ICMS_201 = 'CSOSN_ICMS_201',
	CSOSN_ICMS_202 = 'CSOSN_ICMS_202',
	CSOSN_ICMS_203 = 'CSOSN_ICMS_203',
	CSOSN_ICMS_300 = 'CSOSN_ICMS_300',
	CSOSN_ICMS_400 = 'CSOSN_ICMS_400',
	CSOSN_ICMS_500 = 'CSOSN_ICMS_500',
	CSOSN_ICMS_900 = 'CSOSN_ICMS_900',
	// Tipos para empresa lucro real e presumido
	CST_ICMS_00 = 'CST_ICMS_00',
	CST_ICMS_10 = 'CST_ICMS_10',
	CST_ICMS_20 = 'CST_ICMS_20',
	CST_ICMS_30 = 'CST_ICMS_30',
	CST_ICMS_40 = 'CST_ICMS_40',
	CST_ICMS_41 = 'CST_ICMS_41',
	CST_ICMS_50 = 'CST_ICMS_50',
	CST_ICMS_51 = 'CST_ICMS_51',
	CST_ICMS_60 = 'CST_ICMS_60',
	CST_ICMS_70 = 'CST_ICMS_70',
	CST_ICMS_90 = 'CST_ICMS_90'
}

export enum TipoDesoneracaoIcmsEnum {
	DESONERACAO_ICMS_1 = 'DESONERACAO_ICMS_1',
	DESONERACAO_ICMS_3 = 'DESONERACAO_ICMS_3',
	DESONERACAO_ICMS_4 = 'DESONERACAO_ICMS_4',
	DESONERACAO_ICMS_5 = 'DESONERACAO_ICMS_5',
	DESONERACAO_ICMS_6 = 'DESONERACAO_ICMS_6',
	DESONERACAO_ICMS_7 = 'DESONERACAO_ICMS_7',
	DESONERACAO_ICMS_8 = 'DESONERACAO_ICMS_8',
	DESONERACAO_ICMS_9 = 'DESONERACAO_ICMS_9',
	DESONERACAO_ICMS_10 = 'DESONERACAO_ICMS_10',
	DESONERACAO_ICMS_11 = 'DESONERACAO_ICMS_11',
	DESONERACAO_ICMS_12 = 'DESONERACAO_ICMS_12'
}

export enum TipoModalideBaseCalculoIcmsEnum {
	MOD_BASE_CALCULO_ICMS_0 = 'MOD_BASE_CALCULO_ICMS_0',
	MOD_BASE_CALCULO_ICMS_1 = 'MOD_BASE_CALCULO_ICMS_1',
	MOD_BASE_CALCULO_ICMS_2 = 'MOD_BASE_CALCULO_ICMS_2',
	MOD_BASE_CALCULO_ICMS_3 = 'MOD_BASE_CALCULO_ICMS_3'
}

export enum TipoModalideBaseCalculoIcmsStEnum {
	MOD_BASE_CALCULO_ST_0 = 'MOD_BASE_CALCULO_ST_0',
	MOD_BASE_CALCULO_ST_1 = 'MOD_BASE_CALCULO_ST_1',
	MOD_BASE_CALCULO_ST_2 = 'MOD_BASE_CALCULO_ST_2',
	MOD_BASE_CALCULO_ST_3 = 'MOD_BASE_CALCULO_ST_3',
	MOD_BASE_CALCULO_ST_4 = 'MOD_BASE_CALCULO_ST_4',
	MOD_BASE_CALCULO_ST_5 = 'MOD_BASE_CALCULO_ST_5'
}

export enum GrupoCstIpi {
	TABELA_IMPOSTO_CST_IPI_SAIDA = 'TABELA_IMPOSTO_CST_IPI_SAIDA',
	TABELA_IMPOSTO_CST_IPI_ENTRADA = 'TABELA_IMPOSTO_CST_IPI_ENTRADA'
}

export enum TipoSituacaoTributariaIpiEnum {
	CST_IPI_ENTRADA_00 = 'CST_IPI_ENTRADA_00',
	CST_IPI_ENTRADA_01 = 'CST_IPI_ENTRADA_01',
	CST_IPI_ENTRADA_02 = 'CST_IPI_ENTRADA_02',
	CST_IPI_ENTRADA_03 = 'CST_IPI_ENTRADA_03',
	CST_IPI_ENTRADA_04 = 'CST_IPI_ENTRADA_04',
	CST_IPI_ENTRADA_05 = 'CST_IPI_ENTRADA_05',
	CST_IPI_ENTRADA_49 = 'CST_IPI_ENTRADA_49',
	CST_IPI_SAIDA_50 = 'CST_IPI_SAIDA_50',
	CST_IPI_SAIDA_51 = 'CST_IPI_SAIDA_51',
	CST_IPI_SAIDA_52 = 'CST_IPI_SAIDA_52',
	CST_IPI_SAIDA_53 = 'CST_IPI_SAIDA_53',
	CST_IPI_SAIDA_54 = 'CST_IPI_SAIDA_54',
	CST_IPI_SAIDA_55 = 'CST_IPI_SAIDA_55',
	CST_IPI_SAIDA_99 = 'CST_IPI_SAIDA_99'
}

export enum GrupoPisCofins {
	TABELA_IMPOSTO_CST_PIS_COFINS_ENTRADA = 'TABELA_IMPOSTO_CST_PIS_COFINS_ENTRADA',
	TABELA_IMPOSTO_CST_PIS_COFINS_SAIDA = 'TABELA_IMPOSTO_CST_PIS_COFINS_SAIDA'
}

export enum TipoSituacaoTributariaSaidaPisCofinsEnum {
	PIS_COFINS_SAIDA_01 = 'PIS_COFINS_SAIDA_01',
	PIS_COFINS_SAIDA_02 = 'PIS_COFINS_SAIDA_02',
	PIS_COFINS_SAIDA_03 = 'PIS_COFINS_SAIDA_03',
	PIS_COFINS_SAIDA_04 = 'PIS_COFINS_SAIDA_04',
	PIS_COFINS_SAIDA_05 = 'PIS_COFINS_SAIDA_05',
	PIS_COFINS_SAIDA_06 = 'PIS_COFINS_SAIDA_06',
	PIS_COFINS_SAIDA_07 = 'PIS_COFINS_SAIDA_07',
	PIS_COFINS_SAIDA_08 = 'PIS_COFINS_SAIDA_08',
	PIS_COFINS_SAIDA_09 = 'PIS_COFINS_SAIDA_09',
	PIS_COFINS_SAIDA_49 = 'PIS_COFINS_SAIDA_49',
	PIS_COFINS_SAIDA_99 = 'PIS_COFINS_SAIDA_99'
}

export enum TipoSituacaoTributariaEntradaPisCofinsEnum {
	PIS_COFINS_ENTRADA_50 = 'PIS_COFINS_ENTRADA_50',
	PIS_COFINS_ENTRADA_51 = 'PIS_COFINS_ENTRADA_51',
	PIS_COFINS_ENTRADA_52 = 'PIS_COFINS_ENTRADA_52',
	PIS_COFINS_ENTRADA_53 = 'PIS_COFINS_ENTRADA_53',
	PIS_COFINS_ENTRADA_54 = 'PIS_COFINS_ENTRADA_54',
	PIS_COFINS_ENTRADA_55 = 'PIS_COFINS_ENTRADA_55',
	PIS_COFINS_ENTRADA_56 = 'PIS_COFINS_ENTRADA_56',
	PIS_COFINS_ENTRADA_60 = 'PIS_COFINS_ENTRADA_60',
	PIS_COFINS_ENTRADA_61 = 'PIS_COFINS_ENTRADA_61',
	PIS_COFINS_ENTRADA_62 = 'PIS_COFINS_ENTRADA_62',
	PIS_COFINS_ENTRADA_63 = 'PIS_COFINS_ENTRADA_63',
	PIS_COFINS_ENTRADA_64 = 'PIS_COFINS_ENTRADA_64',
	PIS_COFINS_ENTRADA_65 = 'PIS_COFINS_ENTRADA_65',
	PIS_COFINS_ENTRADA_66 = 'PIS_COFINS_ENTRADA_66',
	PIS_COFINS_ENTRADA_67 = 'PIS_COFINS_ENTRADA_67',
	PIS_COFINS_ENTRADA_70 = 'PIS_COFINS_ENTRADA_70',
	PIS_COFINS_ENTRADA_71 = 'PIS_COFINS_ENTRADA_71',
	PIS_COFINS_ENTRADA_72 = 'PIS_COFINS_ENTRADA_72',
	PIS_COFINS_ENTRADA_73 = 'PIS_COFINS_ENTRADA_73',
	PIS_COFINS_ENTRADA_74 = 'PIS_COFINS_ENTRADA_74',
	PIS_COFINS_ENTRADA_75 = 'PIS_COFINS_ENTRADA_75',
	PIS_COFINS_ENTRADA_98 = 'PIS_COFINS_ENTRADA_98'
}

export enum TipoCalculoPisCofinsEnum {
	CALCULO_PIS_COFINS_0 = 'CALCULO_PIS_COFINS_0',
	CALCULO_PIS_COFINS_1 = 'CALCULO_PIS_COFINS_1'
}

export enum TipoSubItemEnum {
	SUBITEM_COMBO = 'SUBITEM_COMBO',
	SUBITEM_COMPOSICAO = 'SUBITEM_COMPOSICAO',
	SUBITEM_DESMEMBRAMENTO = 'SUBITEM_DESMEMBRAMENTO',
	SUBITEM_PRODUCAO = 'SUBITEM_PRODUCAO',
	SUBITEM_VENDA_CONJUNTA = 'SUBITEM_VENDA_CONJUNTA'
}

export enum TipoModeloDocumentoEnum {
	MODELO_DOCUMENTO_01 = 'MODELO_DOCUMENTO_01',
	MODELO_DOCUMENTO_02 = 'MODELO_DOCUMENTO_02',
	MODELO_DOCUMENTO_04 = 'MODELO_DOCUMENTO_04',
	MODELO_DOCUMENTO_06 = 'MODELO_DOCUMENTO_06',
	MODELO_DOCUMENTO_07 = 'MODELO_DOCUMENTO_07',
	MODELO_DOCUMENTO_08 = 'MODELO_DOCUMENTO_08',
	MODELO_DOCUMENTO_09 = 'MODELO_DOCUMENTO_09',
	MODELO_DOCUMENTO_10 = 'MODELO_DOCUMENTO_10',
	MODELO_DOCUMENTO_11 = 'MODELO_DOCUMENTO_11',
	MODELO_DOCUMENTO_13 = 'MODELO_DOCUMENTO_13',
	MODELO_DOCUMENTO_14 = 'MODELO_DOCUMENTO_14',
	MODELO_DOCUMENTO_15 = 'MODELO_DOCUMENTO_15',
	MODELO_DOCUMENTO_16 = 'MODELO_DOCUMENTO_16',
	MODELO_DOCUMENTO_17 = 'MODELO_DOCUMENTO_17',
	MODELO_DOCUMENTO_18 = 'MODELO_DOCUMENTO_18',
	MODELO_DOCUMENTO_1B = 'MODELO_DOCUMENTO_1B',
	MODELO_DOCUMENTO_20 = 'MODELO_DOCUMENTO_20',
	MODELO_DOCUMENTO_21 = 'MODELO_DOCUMENTO_21',
	MODELO_DOCUMENTO_22 = 'MODELO_DOCUMENTO_22',
	MODELO_DOCUMENTO_23 = 'MODELO_DOCUMENTO_23',
	MODELO_DOCUMENTO_24 = 'MODELO_DOCUMENTO_24',
	MODELO_DOCUMENTO_25 = 'MODELO_DOCUMENTO_25',
	MODELO_DOCUMENTO_26 = 'MODELO_DOCUMENTO_26',
	MODELO_DOCUMENTO_27 = 'MODELO_DOCUMENTO_27',
	MODELO_DOCUMENTO_28 = 'MODELO_DOCUMENTO_28',
	MODELO_DOCUMENTO_29 = 'MODELO_DOCUMENTO_29',
	MODELO_DOCUMENTO_2D = 'MODELO_DOCUMENTO_2D',
	MODELO_DOCUMENTO_2E = 'MODELO_DOCUMENTO_2E',
	MODELO_DOCUMENTO_55 = 'MODELO_DOCUMENTO_55',
	MODELO_DOCUMENTO_57 = 'MODELO_DOCUMENTO_57',
	MODELO_DOCUMENTO_59 = 'MODELO_DOCUMENTO_59',
	MODELO_DOCUMENTO_65 = 'MODELO_DOCUMENTO_65',
	MODELO_DOCUMENTO_8B = 'MODELO_DOCUMENTO_8B'
}

export enum TipoNotaFiscalEnum {
	NF_ENTRADA = 'NF_ENTRADA',
	NF_SAIDA = 'NF_SAIDA'
}

export enum TipoStatusEnum {
	NF_STATUS_DENEGADA = 'NF_STATUS_DENEGADA',
	NF_STATUS_EM_CADASTRO = 'NF_STATUS_EM_CADASTRO',
	NF_STATUS_CANCELADA = 'NF_STATUS_CANCELADA',
	NF_STATUS_EM_ANALIZA = 'NF_STATUS_EM_ANALIZA',
	NF_STATUS_REJEITADA = 'NF_STATUS_REJEITADA',
	NF_STATUS_AUTORIZADA = 'NF_STATUS_AUTORIZADA',
	NF_STATUS_INUTILIZADA = 'NF_STATUS_INUTILIZADA',
	NF_STATUS_CONTINGENCIA = 'NF_STATUS_CONTINGENCIA'
}

export enum TipoModeloEnum {
	MODELO_DOCUMENTO_55 = 'MODELO_DOCUMENTO_55',
	MODELO_DOCUMENTO_65 = 'MODELO_DOCUMENTO_65'
}

export enum TipoEmissaoEnum {
	NF_EMISSAO_NORMAL = 'NF_EMISSAO_NORMAL',
	NF_EMISSAO_CONTINGENCIA_FORM = 'NF_EMISSAO_CONTINGENCIA_FORM',
	NF_EMISSAO_CONTINGENCIA_SCAN = 'NF_EMISSAO_CONTINGENCIA_SCAN',
	NF_EMISSAO_CONTINGENCIA_DPEC = 'NF_EMISSAO_CONTINGENCIA_DPEC',
	NF_EMISSAO_CONTINGENCIA_FSDA = 'NF_EMISSAO_CONTINGENCIA_FSDA',
	NF_EMISSAO_CONTINGENCIA_NFCE = 'NF_EMISSAO_CONTINGENCIA_NFCE'
}

export enum TipoFuncaoSistemaEnum {
	NF_FUNCAO_MANUAL = 'NF_FUNCAO_MANUAL',
	NF_FUNCAO_IMPORT_PEDIDO = 'NF_FUNCAO_IMPORT_PEDIDO',
	NF_FUNCAO_IMPORT_DEVOLUCAO = 'NF_FUNCAO_IMPORT_DEVOLUCAO',
	NF_FUNCAO_IMPORT_TRANSFERENCIA = 'NF_FUNCAO_IMPORT_TRANSFERENCIA',
	NF_FUNCAO_AUTOMATICA_NFCE = 'NF_FUNCAO_AUTOMATICA_NFCE'
}

export enum TipoFinalidadeEmissaoEnum {
	NF_FINALIDADE_NORMAL = 'NF_FINALIDADE_NORMAL',
	NF_FINALIDADE_COMPLEMENTAR = 'NF_FINALIDADE_COMPLEMENTAR',
	NF_FINALIDADE_AJUSTE = 'NF_FINALIDADE_AJUSTE',
	NF_FINALIDADE_DEVOLUCAO = 'NF_FINALIDADE_DEVOLUCAO'
}

export enum TipoIdDestEnum {
	NF_ID_DEST_OPERACAO_INTERNA = 'NF_ID_DEST_OPERACAO_INTERNA',
	NF_ID_DEST_OPERACAO_INTERESTADUAL = 'NF_ID_DEST_OPERACAO_INTERESTADUAL',
	NF_ID_DEST_OPERACAO_COM_EXTERIOR = 'NF_ID_DEST_OPERACAO_COM_EXTERIOR'
}

export enum TipoFormatoImpressaoDanfeEnum {
	NF_DANFE_IMP_SEM_GERACAO = 'NF_DANFE_IMP_SEM_GERACAO',
	NF_DANFE_IMP_NORMAL_RETRATO = 'NF_DANFE_IMP_NORMAL_RETRATO',
	NF_DANFE_IMP_NORMAL_PAISAGEM = 'NF_DANFE_IMP_NORMAL_PAISAGEM',
	NF_DANFE_IMP_SIMPLIFICADO = 'NF_DANFE_IMP_SIMPLIFICADO',
	NF_DANFE_IMP_NFC_E = 'NF_DANFE_IMP_NFC_E',
	NF_DANFE_IMP_NFC_E_MENSAGEM_ELETRONICA = 'NF_DANFE_IMP_NFC_E_MENSAGEM_ELETRONICA'
}

export enum TipoFreteNfeEnum {
	NF_FRETE_0 = 'NF_FRETE_0',
	NF_FRETE_1 = 'NF_FRETE_1',
	NF_FRETE_2 = 'NF_FRETE_2',
	NF_FRETE_3 = 'NF_FRETE_3',
	NF_FRETE_4 = 'NF_FRETE_4',
	NF_FRETE_9 = 'NF_FRETE_9'
}

export enum TipoPresencaEnum {
	NF_PRESENCA_INTERNET = 'NF_PRESENCA_INTERNET',
	NF_PRESENCA_NFCE_DOMICILIO = 'NF_PRESENCA_NFCE_DOMICILIO',
	NF_PRESENCA_FORA_ESTABELECIMENTO = 'NF_PRESENCA_FORA_ESTABELECIMENTO',
	NF_PRESENCA_NAO_APLICA = 'NF_PRESENCA_NAO_APLICA',
	NF_PRESENCA_OP_PRESENCIAL = 'NF_PRESENCA_OP_PRESENCIAL',
	NF_PRESENCA_OP_NAO_PRESENCIAL = 'NF_PRESENCA_OP_NAO_PRESENCIAL',
	NF_PRESENCA_TELE = 'NF_PRESENCA_TELE'
}

export enum TipoPreenchimentoStringEnum {
	ESQUERDA = 'ESQUERDA',
	DIREITA = 'DIREITA',
	CENTRO = 'CENTRO'
}

export enum TipoBancoEnum {
	BANCO_246 = 'BANCO_246',
	BANCO_75 = 'BANCO_75',
	BANCO_121 = 'BANCO_121',
	BANCO_25 = 'BANCO_25',
	BANCO_641 = 'BANCO_641',
	BANCO_65 = 'BANCO_65',
	BANCO_96 = 'BANCO_96',
	BANCO_24 = 'BANCO_24',
	BANCO_318 = 'BANCO_318',
	BANCO_752 = 'BANCO_752',
	BANCO_107 = 'BANCO_107',
	BANCO_63 = 'BANCO_63',
	BANCO_36 = 'BANCO_36',
	BANCO_204 = 'BANCO_204',
	BANCO_394 = 'BANCO_394',
	BANCO_237 = 'BANCO_237',
	BANCO_218 = 'BANCO_218',
	BANCO_208 = 'BANCO_208',
	BANCO_473 = 'BANCO_473',
	BANCO_40 = 'BANCO_40',
	BANCO_739 = 'BANCO_739',
	BANCO_233 = 'BANCO_233',
	BANCO_745 = 'BANCO_745',
	BANCO_756 = 'BANCO_756',
	BANCO_748 = 'BANCO_748',
	BANCO_222 = 'BANCO_222',
	BANCO_505 = 'BANCO_505',
	BANCO_3 = 'BANCO_3',
	BANCO_83 = 'BANCO_83',
	BANCO_707 = 'BANCO_707',
	BANCO_1 = 'BANCO_1',
	BANCO_47 = 'BANCO_47',
	BANCO_37 = 'BANCO_37',
	BANCO_41 = 'BANCO_41',
	BANCO_4 = 'BANCO_4',
	BANCO_265 = 'BANCO_265',
	BANCO_224 = 'BANCO_224',
	BANCO_612 = 'BANCO_612',
	BANCO_12 = 'BANCO_12',
	BANCO_604 = 'BANCO_604',
	BANCO_653 = 'BANCO_653',
	BANCO_77 = 'BANCO_77',
	BANCO_249 = 'BANCO_249',
	BANCO_184 = 'BANCO_184',
	BANCO_29 = 'BANCO_29',
	BANCO_479 = 'BANCO_479',
	BANCO_376 = 'BANCO_376',
	BANCO_74 = 'BANCO_74',
	BANCO_217 = 'BANCO_217',
	BANCO_600 = 'BANCO_600',
	BANCO_389 = 'BANCO_389',
	BANCO_370 = 'BANCO_370',
	BANCO_746 = 'BANCO_746',
	BANCO_456 = 'BANCO_456',
	BANCO_169 = 'BANCO_169',
	BANCO_212 = 'BANCO_212',
	BANCO_623 = 'BANCO_623',
	BANCO_611 = 'BANCO_611',
	BANCO_643 = 'BANCO_643',
	BANCO_747 = 'BANCO_747',
	BANCO_633 = 'BANCO_633',
	BANCO_120 = 'BANCO_120',
	BANCO_422 = 'BANCO_422',
	BANCO_33 = 'BANCO_33',
	BANCO_743 = 'BANCO_743',
	BANCO_630 = 'BANCO_630',
	BANCO_366 = 'BANCO_366',
	BANCO_464 = 'BANCO_464',
	BANCO_82 = 'BANCO_82',
	BANCO_634 = 'BANCO_634',
	BANCO_655 = 'BANCO_655',
	BANCO_610 = 'BANCO_610',
	BANCO_119 = 'BANCO_119',
	BANCO_81 = 'BANCO_81',
	BANCO_21 = 'BANCO_21',
	BANCO_755 = 'BANCO_755',
	BANCO_250 = 'BANCO_250',
	BANCO_144 = 'BANCO_144',
	BANCO_17 = 'BANCO_17',
	BANCO_70 = 'BANCO_70',
	BANCO_104 = 'BANCO_104',
	BANCO_320 = 'BANCO_320',
	BANCO_477 = 'BANCO_477',
	BANCO_487 = 'BANCO_487',
	BANCO_64 = 'BANCO_64',
	BANCO_62 = 'BANCO_62',
	BANCO_269 = 'BANCO_269',
	BANCO_492 = 'BANCO_492',
	BANCO_652 = 'BANCO_652',
	BANCO_341 = 'BANCO_341',
	BANCO_488 = 'BANCO_488',
	BANCO_399 = 'BANCO_399',
	BANCO_128 = 'BANCO_128',
	BANCO_254 = 'BANCO_254',
	BANCO_125 = 'BANCO_125',
	BANCO_751 = 'BANCO_751',
	BANCO_118 = 'BANCO_118',
	BANCO_95 = 'BANCO_95',
	BANCO_129 = 'BANCO_129',
	BANCO_260 = 'BANCO_260',
	BANCO_735 = 'BANCO_735'
}

export enum TipoStatusBoletoIntegracaoEnum {
	BOLETO_STATUS_SALVO = 'BOLETO_STATUS_SALVO',
	BOLETO_STATUS_FALHA = 'BOLETO_STATUS_FALHA',
	BOLETO_STATUS_EMITIDO = 'BOLETO_STATUS_EMITIDO',
	BOLETO_STATUS_REJEITADO = 'REJEITADO',
	BOLETO_STATUS_REGISTRADO = 'BOLETO_STATUS_REGISTRADO',
	BOLETO_STATUS_LIQUIDADO = 'BOLETO_STATUS_LIQUIDADO',
	BOLETO_STATUS_BAIXADO = 'BOLETO_STATUS_BAIXADO',
	BOLETO_STATUS_DESCARTADO = 'BOLETO_STATUS_DESCARTADO',
	BOLETO_STATUS_PENDENTE_RETENTATIVA = 'BOLETO_STATUS_PENDENTE_RETENTATIVA'
}

export enum TipoAlteracaoRemessa {
	ALTERACAO_DATA_VENCIMENTO = '0',
	ALTERACAO_VALOR_TITULO = '1',
	CONCEDER_ABATIMENTO = '2',
	CANCELAR_ABATIMENTO_CONCEDIDO = '3',
	PEDIDO_PROTESTO = '4',
	SUSTAR_PROTESTO_BAIXAR_BOLETO = '5',
	SUSTAR_PROTESTE_MANTER_EM_CARTEIRA = '6',
	ALTERACAO_CARTEIRA = '7',
	INCLUIR_NEGATIVACAO = '8',
	EXCLUIR_NEGATIVACAO_MANTER_CARTEIRA = '9',
	EXCLUIR_NEGATIVACAO_BAIXAR_TITULO = '10'
}

export enum TipoComplementoEnum {
	COMANDA = 'COMANDA',
	MESA = 'MESA',
	BALCAO = 'BALCAO',
	DELIVERY = 'DELIVERY'
}

export enum TipoStatusRetornoBoletoEnum {
	BOLETO_STATUS_RETORNO_04 = 'BOLETO_STATUS_RETORNO_04',
	BOLETO_STATUS_RETORNO_05 = 'BOLETO_STATUS_RETORNO_05',
	BOLETO_STATUS_RETORNO_07 = 'BOLETO_STATUS_RETORNO_07',
	BOLETO_STATUS_RETORNO_08 = 'BOLETO_STATUS_RETORNO_08',
	BOLETO_STATUS_RETORNO_11 = 'BOLETO_STATUS_RETORNO_11',
	BOLETO_STATUS_RETORNO_12 = 'BOLETO_STATUS_RETORNO_12',
	BOLETO_STATUS_RETORNO_13 = 'BOLETO_STATUS_RETORNO_13',
	BOLETO_STATUS_RETORNO_14 = 'BOLETO_STATUS_RETORNO_14',
	BOLETO_STATUS_RETORNO_15 = 'BOLETO_STATUS_RETORNO_15',
	BOLETO_STATUS_RETORNO_17 = 'BOLETO_STATUS_RETORNO_17',
	BOLETO_STATUS_RETORNO_19 = 'BOLETO_STATUS_RETORNO_19',
	BOLETO_STATUS_RETORNO_02 = 'BOLETO_STATUS_RETORNO_02',
	BOLETO_STATUS_RETORNO_20 = 'BOLETO_STATUS_RETORNO_20',
	BOLETO_STATUS_RETORNO_23 = 'BOLETO_STATUS_RETORNO_23',
	BOLETO_STATUS_RETORNO_24 = 'BOLETO_STATUS_RETORNO_24',
	BOLETO_STATUS_RETORNO_25 = 'BOLETO_STATUS_RETORNO_25',
	BOLETO_STATUS_RETORNO_26 = 'BOLETO_STATUS_RETORNO_26',
	BOLETO_STATUS_RETORNO_27 = 'BOLETO_STATUS_RETORNO_27',
	BOLETO_STATUS_RETORNO_28 = 'BOLETO_STATUS_RETORNO_28',
	BOLETO_STATUS_RETORNO_29 = 'BOLETO_STATUS_RETORNO_29',
	BOLETO_STATUS_RETORNO_03 = 'BOLETO_STATUS_RETORNO_03',
	BOLETO_STATUS_RETORNO_30 = 'BOLETO_STATUS_RETORNO_30',
	BOLETO_STATUS_RETORNO_33 = 'BOLETO_STATUS_RETORNO_33',
	BOLETO_STATUS_RETORNO_34 = 'BOLETO_STATUS_RETORNO_34',
	BOLETO_STATUS_RETORNO_35 = 'BOLETO_STATUS_RETORNO_35',
	BOLETO_STATUS_RETORNO_36 = 'BOLETO_STATUS_RETORNO_36',
	BOLETO_STATUS_RETORNO_37 = 'BOLETO_STATUS_RETORNO_37',
	BOLETO_STATUS_RETORNO_38 = 'BOLETO_STATUS_RETORNO_38',
	BOLETO_STATUS_RETORNO_39 = 'BOLETO_STATUS_RETORNO_39',
	BOLETO_STATUS_RETORNO_40 = 'BOLETO_STATUS_RETORNO_40',
	BOLETO_STATUS_RETORNO_41 = 'BOLETO_STATUS_RETORNO_41',
	BOLETO_STATUS_RETORNO_42 = 'BOLETO_STATUS_RETORNO_42',
	BOLETO_STATUS_RETORNO_43 = 'BOLETO_STATUS_RETORNO_43',
	BOLETO_STATUS_RETORNO_44 = 'BOLETO_STATUS_RETORNO_44',
	BOLETO_STATUS_RETORNO_45 = 'BOLETO_STATUS_RETORNO_45',
	BOLETO_STATUS_RETORNO_46 = 'BOLETO_STATUS_RETORNO_46',
	BOLETO_STATUS_RETORNO_47 = 'BOLETO_STATUS_RETORNO_47',
	BOLETO_STATUS_RETORNO_48 = 'BOLETO_STATUS_RETORNO_48',
	BOLETO_STATUS_RETORNO_49 = 'BOLETO_STATUS_RETORNO_49',
	BOLETO_STATUS_RETORNO_50 = 'BOLETO_STATUS_RETORNO_50',
	BOLETO_STATUS_RETORNO_51 = 'BOLETO_STATUS_RETORNO_51',
	BOLETO_STATUS_RETORNO_52 = 'BOLETO_STATUS_RETORNO_52',
	BOLETO_STATUS_RETORNO_53 = 'BOLETO_STATUS_RETORNO_53',
	BOLETO_STATUS_RETORNO_54 = 'BOLETO_STATUS_RETORNO_54',
	BOLETO_STATUS_RETORNO_06 = 'BOLETO_STATUS_RETORNO_06',
	BOLETO_STATUS_RETORNO_73 = 'BOLETO_STATUS_RETORNO_73',
	BOLETO_STATUS_RETORNO_09 = 'BOLETO_STATUS_RETORNO_09',
	BOLETO_STATUS_RETORNO_99 = 'BOLETO_STATUS_RETORNO_99'
}

export enum TipoNfeAmbienteEnum {
	NFE_AMBIENTE_PRODUCAO = 'NFE_AMBIENTE_PRODUCAO',
	NFE_AMBIENTE_HOMOLOGACAO = 'NFE_AMBIENTE_HOMOLOGACAO'
}

export enum TipoVersaoNfeEnum {
	NFE_VERSAO_3_10 = 'NFE_VERSAO_3_10',
	NFE_VERSAO_4_00 = 'NFE_VERSAO_4_00'
}

export enum TipoOperacaoConsumidorFinalEnum {
	NF_IND_FINAL_NORMAL = 'NF_IND_FINAL_NORMAL',
	NF_IND_FINAL_CONSUMIDOR_FINAL = 'NF_IND_FINAL_CONSUMIDOR_FINAL'
}

export enum TipoProcessoEmissaoNfeEnum {
	NF_EMISSAO_APLICATIVO_CONTRIBUINTE = 'NF_EMISSAO_APLICATIVO_CONTRIBUINTE',
	NF_EMISSAO_AVULSA_FISCO = 'NF_EMISSAO_AVULSA_FISCO',
	NF_EMISSAO_AVULSA_CONTRIBUINTE_FISCO = 'NF_EMISSAO_AVULSA_CONTRIBUINTE_FISCO',
	NF_EMISSAO_CONTRIBUINTE_APLICATIVO_FISCO = 'NF_EMISSAO_CONTRIBUINTE_APLICATIVO_FISCO'
}

export enum TipoStatusRetornoNfe {
	NF_STATUS_RET_DENEGACAO_301 = 'NF_STATUS_RET_DENEGACAO_301',
	NF_STATUS_RET_DENEGACAO_302 = 'NF_STATUS_RET_DENEGACAO_302',
	NF_STATUS_RET_DENEGACAO_303 = 'NF_STATUS_RET_DENEGACAO_303',
	NF_STATUS_RET_PROC_100 = 'NF_STATUS_RET_PROC_100',
	NF_STATUS_RET_PROC_101 = 'NF_STATUS_RET_PROC_101',
	NF_STATUS_RET_PROC_102 = 'NF_STATUS_RET_PROC_102',
	NF_STATUS_RET_PROC_103 = 'NF_STATUS_RET_PROC_103',
	NF_STATUS_RET_PROC_104 = 'NF_STATUS_RET_PROC_104',
	NF_STATUS_RET_PROC_105 = 'NF_STATUS_RET_PROC_105',
	NF_STATUS_RET_PROC_106 = 'NF_STATUS_RET_PROC_106',
	NF_STATUS_RET_PROC_107 = 'NF_STATUS_RET_PROC_107',
	NF_STATUS_RET_PROC_108 = 'NF_STATUS_RET_PROC_108',
	NF_STATUS_RET_PROC_109 = 'NF_STATUS_RET_PROC_109',
	NF_STATUS_RET_PROC_110 = 'NF_STATUS_RET_PROC_110',
	NF_STATUS_RET_PROC_111 = 'NF_STATUS_RET_PROC_111',
	NF_STATUS_RET_PROC_112 = 'NF_STATUS_RET_PROC_112',
	NF_STATUS_RET_PROC_124 = 'NF_STATUS_RET_PROC_124',
	NF_STATUS_RET_PROC_128 = 'NF_STATUS_RET_PROC_128',
	NF_STATUS_RET_PROC_135 = 'NF_STATUS_RET_PROC_135',
	NF_STATUS_RET_PROC_136 = 'NF_STATUS_RET_PROC_136',
	NF_STATUS_RET_PROC_137 = 'NF_STATUS_RET_PROC_137',
	NF_STATUS_RET_PROC_138 = 'NF_STATUS_RET_PROC_138',
	NF_STATUS_RET_PROC_139 = 'NF_STATUS_RET_PROC_139',
	NF_STATUS_RET_PROC_140 = 'NF_STATUS_RET_PROC_140',
	NF_STATUS_RET_PROC_142 = 'NF_STATUS_RET_PROC_142',
	NF_STATUS_RET_PROC_150 = 'NF_STATUS_RET_PROC_150',
	NF_STATUS_RET_PROC_151 = 'NF_STATUS_RET_PROC_151',
	NF_STATUS_RET_REJEICAO_267 = 'NF_STATUS_RET_REJEICAO_267',
	NF_STATUS_RET_REJEICAO_268 = 'NF_STATUS_RET_REJEICAO_268',
	NF_STATUS_RET_REJEICAO_269 = 'NF_STATUS_RET_REJEICAO_269',
	NF_STATUS_RET_REJEICAO_270 = 'NF_STATUS_RET_REJEICAO_270',
	NF_STATUS_RET_REJEICAO_271 = 'NF_STATUS_RET_REJEICAO_271',
	NF_STATUS_RET_REJEICAO_272 = 'NF_STATUS_RET_REJEICAO_272',
	NF_STATUS_RET_REJEICAO_273 = 'NF_STATUS_RET_REJEICAO_273',
	NF_STATUS_RET_REJEICAO_274 = 'NF_STATUS_RET_REJEICAO_274',
	NF_STATUS_RET_REJEICAO_275 = 'NF_STATUS_RET_REJEICAO_275',
	NF_STATUS_RET_REJEICAO_276 = 'NF_STATUS_RET_REJEICAO_276',
	NF_STATUS_RET_REJEICAO_277 = 'NF_STATUS_RET_REJEICAO_277',
	NF_STATUS_RET_REJEICAO_278 = 'NF_STATUS_RET_REJEICAO_278',
	NF_STATUS_RET_REJEICAO_279 = 'NF_STATUS_RET_REJEICAO_279',
	NF_STATUS_RET_REJEICAO_280 = 'NF_STATUS_RET_REJEICAO_280',
	NF_STATUS_RET_REJEICAO_281 = 'NF_STATUS_RET_REJEICAO_281',
	NF_STATUS_RET_REJEICAO_282 = 'NF_STATUS_RET_REJEICAO_282',
	NF_STATUS_RET_REJEICAO_283 = 'NF_STATUS_RET_REJEICAO_283',
	NF_STATUS_RET_REJEICAO_284 = 'NF_STATUS_RET_REJEICAO_284',
	NF_STATUS_RET_REJEICAO_285 = 'NF_STATUS_RET_REJEICAO_285',
	NF_STATUS_RET_REJEICAO_286 = 'NF_STATUS_RET_REJEICAO_286',
	NF_STATUS_RET_REJEICAO_287 = 'NF_STATUS_RET_REJEICAO_287',
	NF_STATUS_RET_REJEICAO_288 = 'NF_STATUS_RET_REJEICAO_288',
	NF_STATUS_RET_REJEICAO_289 = 'NF_STATUS_RET_REJEICAO_289',
	NF_STATUS_RET_REJEICAO_290 = 'NF_STATUS_RET_REJEICAO_290',
	NF_STATUS_RET_REJEICAO_291 = 'NF_STATUS_RET_REJEICAO_291',
	NF_STATUS_RET_REJEICAO_292 = 'NF_STATUS_RET_REJEICAO_292',
	NF_STATUS_RET_REJEICAO_293 = 'NF_STATUS_RET_REJEICAO_293',
	NF_STATUS_RET_REJEICAO_294 = 'NF_STATUS_RET_REJEICAO_294',
	NF_STATUS_RET_REJEICAO_295 = 'NF_STATUS_RET_REJEICAO_295',
	NF_STATUS_RET_REJEICAO_296 = 'NF_STATUS_RET_REJEICAO_296',
	NF_STATUS_RET_REJEICAO_297 = 'NF_STATUS_RET_REJEICAO_297',
	NF_STATUS_RET_REJEICAO_298 = 'NF_STATUS_RET_REJEICAO_298',
	NF_STATUS_RET_REJEICAO_299 = 'NF_STATUS_RET_REJEICAO_299',
	NF_STATUS_RET_REJEICAO_304 = 'NF_STATUS_RET_REJEICAO_304',
	NF_STATUS_RET_REJEICAO_321 = 'NF_STATUS_RET_REJEICAO_321',
	NF_STATUS_RET_REJEICAO_323 = 'NF_STATUS_RET_REJEICAO_323',
	NF_STATUS_RET_REJEICAO_324 = 'NF_STATUS_RET_REJEICAO_324',
	NF_STATUS_RET_REJEICAO_325 = 'NF_STATUS_RET_REJEICAO_325',
	NF_STATUS_RET_REJEICAO_326 = 'NF_STATUS_RET_REJEICAO_326',
	NF_STATUS_RET_REJEICAO_327 = 'NF_STATUS_RET_REJEICAO_327',
	NF_STATUS_RET_REJEICAO_328 = 'NF_STATUS_RET_REJEICAO_328',
	NF_STATUS_RET_REJEICAO_329 = 'NF_STATUS_RET_REJEICAO_329',
	NF_STATUS_RET_REJEICAO_330 = 'NF_STATUS_RET_REJEICAO_330',
	NF_STATUS_RET_REJEICAO_331 = 'NF_STATUS_RET_REJEICAO_331',
	NF_STATUS_RET_REJEICAO_332 = 'NF_STATUS_RET_REJEICAO_332',
	NF_STATUS_RET_REJEICAO_333 = 'NF_STATUS_RET_REJEICAO_333',
	NF_STATUS_RET_REJEICAO_334 = 'NF_STATUS_RET_REJEICAO_334',
	NF_STATUS_RET_REJEICAO_335 = 'NF_STATUS_RET_REJEICAO_335',
	NF_STATUS_RET_REJEICAO_336 = 'NF_STATUS_RET_REJEICAO_336',
	NF_STATUS_RET_REJEICAO_337 = 'NF_STATUS_RET_REJEICAO_337',
	NF_STATUS_RET_REJEICAO_338 = 'NF_STATUS_RET_REJEICAO_338',
	NF_STATUS_RET_REJEICAO_339 = 'NF_STATUS_RET_REJEICAO_339',
	NF_STATUS_RET_REJEICAO_340 = 'NF_STATUS_RET_REJEICAO_340',
	NF_STATUS_RET_REJEICAO_341 = 'NF_STATUS_RET_REJEICAO_341',
	NF_STATUS_RET_REJEICAO_342 = 'NF_STATUS_RET_REJEICAO_342',
	NF_STATUS_RET_REJEICAO_343 = 'NF_STATUS_RET_REJEICAO_343',
	NF_STATUS_RET_REJEICAO_344 = 'NF_STATUS_RET_REJEICAO_344',
	NF_STATUS_RET_REJEICAO_345 = 'NF_STATUS_RET_REJEICAO_345',
	NF_STATUS_RET_REJEICAO_346 = 'NF_STATUS_RET_REJEICAO_346',
	NF_STATUS_RET_REJEICAO_347 = 'NF_STATUS_RET_REJEICAO_347',
	NF_STATUS_RET_REJEICAO_348 = 'NF_STATUS_RET_REJEICAO_348',
	NF_STATUS_RET_REJEICAO_349 = 'NF_STATUS_RET_REJEICAO_349',
	NF_STATUS_RET_REJEICAO_350 = 'NF_STATUS_RET_REJEICAO_350',
	NF_STATUS_RET_REJEICAO_351 = 'NF_STATUS_RET_REJEICAO_351',
	NF_STATUS_RET_REJEICAO_352 = 'NF_STATUS_RET_REJEICAO_352',
	NF_STATUS_RET_REJEICAO_353 = 'NF_STATUS_RET_REJEICAO_353',
	NF_STATUS_RET_REJEICAO_354 = 'NF_STATUS_RET_REJEICAO_354',
	NF_STATUS_RET_REJEICAO_355 = 'NF_STATUS_RET_REJEICAO_355',
	NF_STATUS_RET_REJEICAO_356 = 'NF_STATUS_RET_REJEICAO_356',
	NF_STATUS_RET_REJEICAO_357 = 'NF_STATUS_RET_REJEICAO_357',
	NF_STATUS_RET_REJEICAO_358 = 'NF_STATUS_RET_REJEICAO_358',
	NF_STATUS_RET_REJEICAO_359 = 'NF_STATUS_RET_REJEICAO_359',
	NF_STATUS_RET_REJEICAO_360 = 'NF_STATUS_RET_REJEICAO_360',
	NF_STATUS_RET_REJEICAO_361 = 'NF_STATUS_RET_REJEICAO_361',
	NF_STATUS_RET_REJEICAO_362 = 'NF_STATUS_RET_REJEICAO_362',
	NF_STATUS_RET_REJEICAO_364 = 'NF_STATUS_RET_REJEICAO_364',
	NF_STATUS_RET_REJEICAO_365 = 'NF_STATUS_RET_REJEICAO_365',
	NF_STATUS_RET_REJEICAO_366 = 'NF_STATUS_RET_REJEICAO_366',
	NF_STATUS_RET_REJEICAO_367 = 'NF_STATUS_RET_REJEICAO_367',
	NF_STATUS_RET_REJEICAO_368 = 'NF_STATUS_RET_REJEICAO_368',
	NF_STATUS_RET_REJEICAO_369 = 'NF_STATUS_RET_REJEICAO_369',
	NF_STATUS_RET_REJEICAO_370 = 'NF_STATUS_RET_REJEICAO_370',
	NF_STATUS_RET_REJEICAO_401 = 'NF_STATUS_RET_REJEICAO_401',
	NF_STATUS_RET_REJEICAO_402 = 'NF_STATUS_RET_REJEICAO_402',
	NF_STATUS_RET_REJEICAO_403 = 'NF_STATUS_RET_REJEICAO_403',
	NF_STATUS_RET_REJEICAO_404 = 'NF_STATUS_RET_REJEICAO_404',
	NF_STATUS_RET_REJEICAO_405 = 'NF_STATUS_RET_REJEICAO_405',
	NF_STATUS_RET_REJEICAO_406 = 'NF_STATUS_RET_REJEICAO_406',
	NF_STATUS_RET_REJEICAO_407 = 'NF_STATUS_RET_REJEICAO_407',
	NF_STATUS_RET_REJEICAO_408 = 'NF_STATUS_RET_REJEICAO_408',
	NF_STATUS_RET_REJEICAO_409 = 'NF_STATUS_RET_REJEICAO_409',
	NF_STATUS_RET_REJEICAO_410 = 'NF_STATUS_RET_REJEICAO_410',
	NF_STATUS_RET_REJEICAO_411 = 'NF_STATUS_RET_REJEICAO_411',
	NF_STATUS_RET_REJEICAO_416 = 'NF_STATUS_RET_REJEICAO_416',
	NF_STATUS_RET_REJEICAO_417 = 'NF_STATUS_RET_REJEICAO_417',
	NF_STATUS_RET_REJEICAO_418 = 'NF_STATUS_RET_REJEICAO_418',
	NF_STATUS_RET_REJEICAO_420 = 'NF_STATUS_RET_REJEICAO_420',
	NF_STATUS_RET_REJEICAO_450 = 'NF_STATUS_RET_REJEICAO_450',
	NF_STATUS_RET_REJEICAO_451 = 'NF_STATUS_RET_REJEICAO_451',
	NF_STATUS_RET_REJEICAO_452 = 'NF_STATUS_RET_REJEICAO_452',
	NF_STATUS_RET_REJEICAO_453 = 'NF_STATUS_RET_REJEICAO_453',
	NF_STATUS_RET_REJEICAO_454 = 'NF_STATUS_RET_REJEICAO_454',
	NF_STATUS_RET_REJEICAO_455 = 'NF_STATUS_RET_REJEICAO_455',
	NF_STATUS_RET_REJEICAO_461 = 'NF_STATUS_RET_REJEICAO_461',
	NF_STATUS_RET_REJEICAO_465 = 'NF_STATUS_RET_REJEICAO_465',
	NF_STATUS_RET_REJEICAO_466 = 'NF_STATUS_RET_REJEICAO_466',
	NF_STATUS_RET_REJEICAO_467 = 'NF_STATUS_RET_REJEICAO_467',
	NF_STATUS_RET_REJEICAO_468 = 'NF_STATUS_RET_REJEICAO_468',
	NF_STATUS_RET_REJEICAO_471 = 'NF_STATUS_RET_REJEICAO_471',
	NF_STATUS_RET_REJEICAO_476 = 'NF_STATUS_RET_REJEICAO_476',
	NF_STATUS_RET_REJEICAO_477 = 'NF_STATUS_RET_REJEICAO_477',
	NF_STATUS_RET_REJEICAO_478 = 'NF_STATUS_RET_REJEICAO_478',
	NF_STATUS_RET_REJEICAO_484 = 'NF_STATUS_RET_REJEICAO_484',
	NF_STATUS_RET_REJEICAO_485 = 'NF_STATUS_RET_REJEICAO_485',
	NF_STATUS_RET_REJEICAO_489 = 'NF_STATUS_RET_REJEICAO_489',
	NF_STATUS_RET_REJEICAO_490 = 'NF_STATUS_RET_REJEICAO_490',
	NF_STATUS_RET_REJEICAO_491 = 'NF_STATUS_RET_REJEICAO_491',
	NF_STATUS_RET_REJEICAO_492 = 'NF_STATUS_RET_REJEICAO_492',
	NF_STATUS_RET_REJEICAO_493 = 'NF_STATUS_RET_REJEICAO_493',
	NF_STATUS_RET_REJEICAO_494 = 'NF_STATUS_RET_REJEICAO_494',
	NF_STATUS_RET_REJEICAO_501 = 'NF_STATUS_RET_REJEICAO_501',
	NF_STATUS_RET_REJEICAO_502 = 'NF_STATUS_RET_REJEICAO_502',
	NF_STATUS_RET_REJEICAO_503 = 'NF_STATUS_RET_REJEICAO_503',
	NF_STATUS_RET_REJEICAO_504 = 'NF_STATUS_RET_REJEICAO_504',
	NF_STATUS_RET_REJEICAO_505 = 'NF_STATUS_RET_REJEICAO_505',
	NF_STATUS_RET_REJEICAO_506 = 'NF_STATUS_RET_REJEICAO_506',
	NF_STATUS_RET_REJEICAO_507 = 'NF_STATUS_RET_REJEICAO_507',
	NF_STATUS_RET_REJEICAO_508 = 'NF_STATUS_RET_REJEICAO_508',
	NF_STATUS_RET_REJEICAO_509 = 'NF_STATUS_RET_REJEICAO_509',
	NF_STATUS_RET_REJEICAO_510 = 'NF_STATUS_RET_REJEICAO_510',
	NF_STATUS_RET_REJEICAO_511 = 'NF_STATUS_RET_REJEICAO_511',
	NF_STATUS_RET_REJEICAO_512 = 'NF_STATUS_RET_REJEICAO_512',
	NF_STATUS_RET_REJEICAO_513 = 'NF_STATUS_RET_REJEICAO_513',
	NF_STATUS_RET_REJEICAO_514 = 'NF_STATUS_RET_REJEICAO_514',
	NF_STATUS_RET_REJEICAO_515 = 'NF_STATUS_RET_REJEICAO_515',
	NF_STATUS_RET_REJEICAO_516 = 'NF_STATUS_RET_REJEICAO_516',
	NF_STATUS_RET_REJEICAO_517 = 'NF_STATUS_RET_REJEICAO_517',
	NF_STATUS_RET_REJEICAO_518 = 'NF_STATUS_RET_REJEICAO_518',
	NF_STATUS_RET_REJEICAO_519 = 'NF_STATUS_RET_REJEICAO_519',
	NF_STATUS_RET_REJEICAO_520 = 'NF_STATUS_RET_REJEICAO_520',
	NF_STATUS_RET_REJEICAO_521 = 'NF_STATUS_RET_REJEICAO_521',
	NF_STATUS_RET_REJEICAO_522 = 'NF_STATUS_RET_REJEICAO_522',
	NF_STATUS_RET_REJEICAO_523 = 'NF_STATUS_RET_REJEICAO_523',
	NF_STATUS_RET_REJEICAO_524 = 'NF_STATUS_RET_REJEICAO_524',
	NF_STATUS_RET_REJEICAO_525 = 'NF_STATUS_RET_REJEICAO_525',
	NF_STATUS_RET_REJEICAO_527 = 'NF_STATUS_RET_REJEICAO_527',
	NF_STATUS_RET_REJEICAO_528 = 'NF_STATUS_RET_REJEICAO_528',
	NF_STATUS_RET_REJEICAO_529 = 'NF_STATUS_RET_REJEICAO_529',
	NF_STATUS_RET_REJEICAO_530 = 'NF_STATUS_RET_REJEICAO_530',
	NF_STATUS_RET_REJEICAO_531 = 'NF_STATUS_RET_REJEICAO_531',
	NF_STATUS_RET_REJEICAO_532 = 'NF_STATUS_RET_REJEICAO_532',
	NF_STATUS_RET_REJEICAO_533 = 'NF_STATUS_RET_REJEICAO_533',
	NF_STATUS_RET_REJEICAO_534 = 'NF_STATUS_RET_REJEICAO_534',
	NF_STATUS_RET_REJEICAO_535 = 'NF_STATUS_RET_REJEICAO_535',
	NF_STATUS_RET_REJEICAO_536 = 'NF_STATUS_RET_REJEICAO_536',
	NF_STATUS_RET_REJEICAO_537 = 'NF_STATUS_RET_REJEICAO_537',
	NF_STATUS_RET_REJEICAO_538 = 'NF_STATUS_RET_REJEICAO_538',
	NF_STATUS_RET_REJEICAO_539 = 'NF_STATUS_RET_REJEICAO_539',
	NF_STATUS_RET_REJEICAO_540 = 'NF_STATUS_RET_REJEICAO_540',
	NF_STATUS_RET_REJEICAO_541 = 'NF_STATUS_RET_REJEICAO_541',
	NF_STATUS_RET_REJEICAO_542 = 'NF_STATUS_RET_REJEICAO_542',
	NF_STATUS_RET_REJEICAO_543 = 'NF_STATUS_RET_REJEICAO_543',
	NF_STATUS_RET_REJEICAO_544 = 'NF_STATUS_RET_REJEICAO_544',
	NF_STATUS_RET_REJEICAO_545 = 'NF_STATUS_RET_REJEICAO_545',
	NF_STATUS_RET_REJEICAO_546 = 'NF_STATUS_RET_REJEICAO_546',
	NF_STATUS_RET_REJEICAO_547 = 'NF_STATUS_RET_REJEICAO_547',
	NF_STATUS_RET_REJEICAO_548 = 'NF_STATUS_RET_REJEICAO_548',
	NF_STATUS_RET_REJEICAO_549 = 'NF_STATUS_RET_REJEICAO_549',
	NF_STATUS_RET_REJEICAO_550 = 'NF_STATUS_RET_REJEICAO_550',
	NF_STATUS_RET_REJEICAO_551 = 'NF_STATUS_RET_REJEICAO_551',
	NF_STATUS_RET_REJEICAO_552 = 'NF_STATUS_RET_REJEICAO_552',
	NF_STATUS_RET_REJEICAO_553 = 'NF_STATUS_RET_REJEICAO_553',
	NF_STATUS_RET_REJEICAO_554 = 'NF_STATUS_RET_REJEICAO_554',
	NF_STATUS_RET_REJEICAO_555 = 'NF_STATUS_RET_REJEICAO_555',
	NF_STATUS_RET_REJEICAO_556 = 'NF_STATUS_RET_REJEICAO_556',
	NF_STATUS_RET_REJEICAO_557 = 'NF_STATUS_RET_REJEICAO_557',
	NF_STATUS_RET_REJEICAO_558 = 'NF_STATUS_RET_REJEICAO_558',
	NF_STATUS_RET_REJEICAO_559 = 'NF_STATUS_RET_REJEICAO_559',
	NF_STATUS_RET_REJEICAO_560 = 'NF_STATUS_RET_REJEICAO_560',
	NF_STATUS_RET_REJEICAO_561 = 'NF_STATUS_RET_REJEICAO_561',
	NF_STATUS_RET_REJEICAO_562 = 'NF_STATUS_RET_REJEICAO_562',
	NF_STATUS_RET_REJEICAO_563 = 'NF_STATUS_RET_REJEICAO_563',
	NF_STATUS_RET_REJEICAO_564 = 'NF_STATUS_RET_REJEICAO_564',
	NF_STATUS_RET_REJEICAO_565 = 'NF_STATUS_RET_REJEICAO_565',
	NF_STATUS_RET_REJEICAO_567 = 'NF_STATUS_RET_REJEICAO_567',
	NF_STATUS_RET_REJEICAO_568 = 'NF_STATUS_RET_REJEICAO_568',
	NF_STATUS_RET_REJEICAO_569 = 'NF_STATUS_RET_REJEICAO_569',
	NF_STATUS_RET_REJEICAO_570 = 'NF_STATUS_RET_REJEICAO_570',
	NF_STATUS_RET_REJEICAO_571 = 'NF_STATUS_RET_REJEICAO_571',
	NF_STATUS_RET_REJEICAO_572 = 'NF_STATUS_RET_REJEICAO_572',
	NF_STATUS_RET_REJEICAO_573 = 'NF_STATUS_RET_REJEICAO_573',
	NF_STATUS_RET_REJEICAO_574 = 'NF_STATUS_RET_REJEICAO_574',
	NF_STATUS_RET_REJEICAO_575 = 'NF_STATUS_RET_REJEICAO_575',
	NF_STATUS_RET_REJEICAO_576 = 'NF_STATUS_RET_REJEICAO_576',
	NF_STATUS_RET_REJEICAO_577 = 'NF_STATUS_RET_REJEICAO_577',
	NF_STATUS_RET_REJEICAO_578 = 'NF_STATUS_RET_REJEICAO_578',
	NF_STATUS_RET_REJEICAO_579 = 'NF_STATUS_RET_REJEICAO_579',
	NF_STATUS_RET_REJEICAO_580 = 'NF_STATUS_RET_REJEICAO_580',
	NF_STATUS_RET_REJEICAO_587 = 'NF_STATUS_RET_REJEICAO_587',
	NF_STATUS_RET_REJEICAO_588 = 'NF_STATUS_RET_REJEICAO_588',
	NF_STATUS_RET_REJEICAO_589 = 'NF_STATUS_RET_REJEICAO_589',
	NF_STATUS_RET_REJEICAO_590 = 'NF_STATUS_RET_REJEICAO_590',
	NF_STATUS_RET_REJEICAO_591 = 'NF_STATUS_RET_REJEICAO_591',
	NF_STATUS_RET_REJEICAO_592 = 'NF_STATUS_RET_REJEICAO_592',
	NF_STATUS_RET_REJEICAO_593 = 'NF_STATUS_RET_REJEICAO_593',
	NF_STATUS_RET_REJEICAO_594 = 'NF_STATUS_RET_REJEICAO_594',
	NF_STATUS_RET_REJEICAO_595 = 'NF_STATUS_RET_REJEICAO_595',
	NF_STATUS_RET_REJEICAO_596 = 'NF_STATUS_RET_REJEICAO_596',
	NF_STATUS_RET_REJEICAO_597 = 'NF_STATUS_RET_REJEICAO_597',
	NF_STATUS_RET_REJEICAO_598 = 'NF_STATUS_RET_REJEICAO_598',
	NF_STATUS_RET_REJEICAO_599 = 'NF_STATUS_RET_REJEICAO_599',
	NF_STATUS_RET_REJEICAO_601 = 'NF_STATUS_RET_REJEICAO_601',
	NF_STATUS_RET_REJEICAO_602 = 'NF_STATUS_RET_REJEICAO_602',
	NF_STATUS_RET_REJEICAO_603 = 'NF_STATUS_RET_REJEICAO_603',
	NF_STATUS_RET_REJEICAO_604 = 'NF_STATUS_RET_REJEICAO_604',
	NF_STATUS_RET_REJEICAO_605 = 'NF_STATUS_RET_REJEICAO_605',
	NF_STATUS_RET_REJEICAO_606 = 'NF_STATUS_RET_REJEICAO_606',
	NF_STATUS_RET_REJEICAO_607 = 'NF_STATUS_RET_REJEICAO_607',
	NF_STATUS_RET_REJEICAO_608 = 'NF_STATUS_RET_REJEICAO_608',
	NF_STATUS_RET_REJEICAO_609 = 'NF_STATUS_RET_REJEICAO_609',
	NF_STATUS_RET_REJEICAO_610 = 'NF_STATUS_RET_REJEICAO_610',
	NF_STATUS_RET_REJEICAO_611 = 'NF_STATUS_RET_REJEICAO_611',
	NF_STATUS_RET_REJEICAO_612 = 'NF_STATUS_RET_REJEICAO_612',
	NF_STATUS_RET_REJEICAO_613 = 'NF_STATUS_RET_REJEICAO_613',
	NF_STATUS_RET_REJEICAO_614 = 'NF_STATUS_RET_REJEICAO_614',
	NF_STATUS_RET_REJEICAO_615 = 'NF_STATUS_RET_REJEICAO_615',
	NF_STATUS_RET_REJEICAO_616 = 'NF_STATUS_RET_REJEICAO_616',
	NF_STATUS_RET_REJEICAO_617 = 'NF_STATUS_RET_REJEICAO_617',
	NF_STATUS_RET_REJEICAO_618 = 'NF_STATUS_RET_REJEICAO_618',
	NF_STATUS_RET_REJEICAO_619 = 'NF_STATUS_RET_REJEICAO_619',
	NF_STATUS_RET_REJEICAO_620 = 'NF_STATUS_RET_REJEICAO_620',
	NF_STATUS_RET_REJEICAO_621 = 'NF_STATUS_RET_REJEICAO_621',
	NF_STATUS_RET_REJEICAO_622 = 'NF_STATUS_RET_REJEICAO_622',
	NF_STATUS_RET_REJEICAO_623 = 'NF_STATUS_RET_REJEICAO_623',
	NF_STATUS_RET_REJEICAO_624 = 'NF_STATUS_RET_REJEICAO_624',
	NF_STATUS_RET_REJEICAO_625 = 'NF_STATUS_RET_REJEICAO_625',
	NF_STATUS_RET_REJEICAO_626 = 'NF_STATUS_RET_REJEICAO_626',
	NF_STATUS_RET_REJEICAO_627 = 'NF_STATUS_RET_REJEICAO_627',
	NF_STATUS_RET_REJEICAO_628 = 'NF_STATUS_RET_REJEICAO_628',
	NF_STATUS_RET_REJEICAO_629 = 'NF_STATUS_RET_REJEICAO_629',
	NF_STATUS_RET_REJEICAO_630 = 'NF_STATUS_RET_REJEICAO_630',
	NF_STATUS_RET_REJEICAO_631 = 'NF_STATUS_RET_REJEICAO_631',
	NF_STATUS_RET_REJEICAO_632 = 'NF_STATUS_RET_REJEICAO_632',
	NF_STATUS_RET_REJEICAO_633 = 'NF_STATUS_RET_REJEICAO_633',
	NF_STATUS_RET_REJEICAO_634 = 'NF_STATUS_RET_REJEICAO_634',
	NF_STATUS_RET_REJEICAO_635 = 'NF_STATUS_RET_REJEICAO_635',
	NF_STATUS_RET_REJEICAO_650 = 'NF_STATUS_RET_REJEICAO_650',
	NF_STATUS_RET_REJEICAO_651 = 'NF_STATUS_RET_REJEICAO_651',
	NF_STATUS_RET_REJEICAO_653 = 'NF_STATUS_RET_REJEICAO_653',
	NF_STATUS_RET_REJEICAO_654 = 'NF_STATUS_RET_REJEICAO_654',
	NF_STATUS_RET_REJEICAO_655 = 'NF_STATUS_RET_REJEICAO_655',
	NF_STATUS_RET_REJEICAO_656 = 'NF_STATUS_RET_REJEICAO_656',
	NF_STATUS_RET_REJEICAO_657 = 'NF_STATUS_RET_REJEICAO_657',
	NF_STATUS_RET_REJEICAO_658 = 'NF_STATUS_RET_REJEICAO_658',
	NF_STATUS_RET_REJEICAO_660 = 'NF_STATUS_RET_REJEICAO_660',
	NF_STATUS_RET_REJEICAO_661 = 'NF_STATUS_RET_REJEICAO_661',
	NF_STATUS_RET_REJEICAO_662 = 'NF_STATUS_RET_REJEICAO_662',
	NF_STATUS_RET_REJEICAO_663 = 'NF_STATUS_RET_REJEICAO_663',
	NF_STATUS_RET_REJEICAO_678 = 'NF_STATUS_RET_REJEICAO_678',
	NF_STATUS_RET_REJEICAO_679 = 'NF_STATUS_RET_REJEICAO_679',
	NF_STATUS_RET_REJEICAO_680 = 'NF_STATUS_RET_REJEICAO_680',
	NF_STATUS_RET_REJEICAO_681 = 'NF_STATUS_RET_REJEICAO_681',
	NF_STATUS_RET_REJEICAO_682 = 'NF_STATUS_RET_REJEICAO_682',
	NF_STATUS_RET_REJEICAO_683 = 'NF_STATUS_RET_REJEICAO_683',
	NF_STATUS_RET_REJEICAO_684 = 'NF_STATUS_RET_REJEICAO_684',
	NF_STATUS_RET_REJEICAO_685 = 'NF_STATUS_RET_REJEICAO_685',
	NF_STATUS_RET_REJEICAO_686 = 'NF_STATUS_RET_REJEICAO_686',
	NF_STATUS_RET_REJEICAO_687 = 'NF_STATUS_RET_REJEICAO_687',
	NF_STATUS_RET_REJEICAO_688 = 'NF_STATUS_RET_REJEICAO_688',
	NF_STATUS_RET_REJEICAO_689 = 'NF_STATUS_RET_REJEICAO_689',
	NF_STATUS_RET_REJEICAO_690 = 'NF_STATUS_RET_REJEICAO_690',
	NF_STATUS_RET_REJEICAO_691 = 'NF_STATUS_RET_REJEICAO_691',
	NF_STATUS_RET_REJEICAO_700 = 'NF_STATUS_RET_REJEICAO_700',
	NF_STATUS_RET_REJEICAO_701 = 'NF_STATUS_RET_REJEICAO_701',
	NF_STATUS_RET_REJEICAO_702 = 'NF_STATUS_RET_REJEICAO_702',
	NF_STATUS_RET_REJEICAO_703 = 'NF_STATUS_RET_REJEICAO_703',
	NF_STATUS_RET_REJEICAO_704 = 'NF_STATUS_RET_REJEICAO_704',
	NF_STATUS_RET_REJEICAO_705 = 'NF_STATUS_RET_REJEICAO_705',
	NF_STATUS_RET_REJEICAO_706 = 'NF_STATUS_RET_REJEICAO_706',
	NF_STATUS_RET_REJEICAO_707 = 'NF_STATUS_RET_REJEICAO_707',
	NF_STATUS_RET_REJEICAO_708 = 'NF_STATUS_RET_REJEICAO_708',
	NF_STATUS_RET_REJEICAO_709 = 'NF_STATUS_RET_REJEICAO_709',
	NF_STATUS_RET_REJEICAO_710 = 'NF_STATUS_RET_REJEICAO_710',
	NF_STATUS_RET_REJEICAO_711 = 'NF_STATUS_RET_REJEICAO_711',
	NF_STATUS_RET_REJEICAO_712 = 'NF_STATUS_RET_REJEICAO_712',
	NF_STATUS_RET_REJEICAO_713 = 'NF_STATUS_RET_REJEICAO_713',
	NF_STATUS_RET_REJEICAO_714 = 'NF_STATUS_RET_REJEICAO_714',
	NF_STATUS_RET_REJEICAO_715 = 'NF_STATUS_RET_REJEICAO_715',
	NF_STATUS_RET_REJEICAO_716 = 'NF_STATUS_RET_REJEICAO_716',
	NF_STATUS_RET_REJEICAO_717 = 'NF_STATUS_RET_REJEICAO_717',
	NF_STATUS_RET_REJEICAO_718 = 'NF_STATUS_RET_REJEICAO_718',
	NF_STATUS_RET_REJEICAO_719 = 'NF_STATUS_RET_REJEICAO_719',
	NF_STATUS_RET_REJEICAO_720 = 'NF_STATUS_RET_REJEICAO_720',
	NF_STATUS_RET_REJEICAO_721 = 'NF_STATUS_RET_REJEICAO_721',
	NF_STATUS_RET_REJEICAO_723 = 'NF_STATUS_RET_REJEICAO_723',
	NF_STATUS_RET_REJEICAO_724 = 'NF_STATUS_RET_REJEICAO_724',
	NF_STATUS_RET_REJEICAO_725 = 'NF_STATUS_RET_REJEICAO_725',
	NF_STATUS_RET_REJEICAO_726 = 'NF_STATUS_RET_REJEICAO_726',
	NF_STATUS_RET_REJEICAO_727 = 'NF_STATUS_RET_REJEICAO_727',
	NF_STATUS_RET_REJEICAO_728 = 'NF_STATUS_RET_REJEICAO_728',
	NF_STATUS_RET_REJEICAO_729 = 'NF_STATUS_RET_REJEICAO_729',
	NF_STATUS_RET_REJEICAO_730 = 'NF_STATUS_RET_REJEICAO_730',
	NF_STATUS_RET_REJEICAO_731 = 'NF_STATUS_RET_REJEICAO_731',
	NF_STATUS_RET_REJEICAO_732 = 'NF_STATUS_RET_REJEICAO_732',
	NF_STATUS_RET_REJEICAO_733 = 'NF_STATUS_RET_REJEICAO_733',
	NF_STATUS_RET_REJEICAO_734 = 'NF_STATUS_RET_REJEICAO_734',
	NF_STATUS_RET_REJEICAO_735 = 'NF_STATUS_RET_REJEICAO_735',
	NF_STATUS_RET_REJEICAO_736 = 'NF_STATUS_RET_REJEICAO_736',
	NF_STATUS_RET_REJEICAO_737 = 'NF_STATUS_RET_REJEICAO_737',
	NF_STATUS_RET_REJEICAO_738 = 'NF_STATUS_RET_REJEICAO_738',
	NF_STATUS_RET_REJEICAO_739 = 'NF_STATUS_RET_REJEICAO_739',
	NF_STATUS_RET_REJEICAO_740 = 'NF_STATUS_RET_REJEICAO_740',
	NF_STATUS_RET_REJEICAO_741 = 'NF_STATUS_RET_REJEICAO_741',
	NF_STATUS_RET_REJEICAO_742 = 'NF_STATUS_RET_REJEICAO_742',
	NF_STATUS_RET_REJEICAO_743 = 'NF_STATUS_RET_REJEICAO_743',
	NF_STATUS_RET_REJEICAO_745 = 'NF_STATUS_RET_REJEICAO_745',
	NF_STATUS_RET_REJEICAO_746 = 'NF_STATUS_RET_REJEICAO_746',
	NF_STATUS_RET_REJEICAO_748 = 'NF_STATUS_RET_REJEICAO_748',
	NF_STATUS_RET_REJEICAO_749 = 'NF_STATUS_RET_REJEICAO_749',
	NF_STATUS_RET_REJEICAO_750 = 'NF_STATUS_RET_REJEICAO_750',
	NF_STATUS_RET_REJEICAO_751 = 'NF_STATUS_RET_REJEICAO_751',
	NF_STATUS_RET_REJEICAO_752 = 'NF_STATUS_RET_REJEICAO_752',
	NF_STATUS_RET_REJEICAO_753 = 'NF_STATUS_RET_REJEICAO_753',
	NF_STATUS_RET_REJEICAO_754 = 'NF_STATUS_RET_REJEICAO_754',
	NF_STATUS_RET_REJEICAO_755 = 'NF_STATUS_RET_REJEICAO_755',
	NF_STATUS_RET_REJEICAO_756 = 'NF_STATUS_RET_REJEICAO_756',
	NF_STATUS_RET_REJEICAO_757 = 'NF_STATUS_RET_REJEICAO_757',
	NF_STATUS_RET_REJEICAO_758 = 'NF_STATUS_RET_REJEICAO_758',
	NF_STATUS_RET_REJEICAO_759 = 'NF_STATUS_RET_REJEICAO_759',
	NF_STATUS_RET_REJEICAO_760 = 'NF_STATUS_RET_REJEICAO_760',
	NF_STATUS_RET_REJEICAO_762 = 'NF_STATUS_RET_REJEICAO_762',
	NF_STATUS_RET_REJEICAO_763 = 'NF_STATUS_RET_REJEICAO_763',
	NF_STATUS_RET_REJEICAO_764 = 'NF_STATUS_RET_REJEICAO_764',
	NF_STATUS_RET_REJEICAO_765 = 'NF_STATUS_RET_REJEICAO_765',
	NF_STATUS_RET_REJEICAO_766 = 'NF_STATUS_RET_REJEICAO_766',
	NF_STATUS_RET_REJEICAO_767 = 'NF_STATUS_RET_REJEICAO_767',
	NF_STATUS_RET_REJEICAO_768 = 'NF_STATUS_RET_REJEICAO_768',
	NF_STATUS_RET_REJEICAO_769 = 'NF_STATUS_RET_REJEICAO_769',
	NF_STATUS_RET_REJEICAO_770 = 'NF_STATUS_RET_REJEICAO_770',
	NF_STATUS_RET_REJEICAO_771 = 'NF_STATUS_RET_REJEICAO_771',
	NF_STATUS_RET_REJEICAO_772 = 'NF_STATUS_RET_REJEICAO_772',
	NF_STATUS_RET_REJEICAO_773 = 'NF_STATUS_RET_REJEICAO_773',
	NF_STATUS_RET_REJEICAO_774 = 'NF_STATUS_RET_REJEICAO_774',
	NF_STATUS_RET_REJEICAO_775 = 'NF_STATUS_RET_REJEICAO_775',
	NF_STATUS_RET_REJEICAO_776 = 'NF_STATUS_RET_REJEICAO_776',
	NF_STATUS_RET_REJEICAO_777 = 'NF_STATUS_RET_REJEICAO_777',
	NF_STATUS_RET_REJEICAO_778 = 'NF_STATUS_RET_REJEICAO_778',
	NF_STATUS_RET_REJEICAO_779 = 'NF_STATUS_RET_REJEICAO_779',
	NF_STATUS_RET_REJEICAO_780 = 'NF_STATUS_RET_REJEICAO_780',
	NF_STATUS_RET_REJEICAO_781 = 'NF_STATUS_RET_REJEICAO_781',
	NF_STATUS_RET_REJEICAO_782 = 'NF_STATUS_RET_REJEICAO_782',
	NF_STATUS_RET_REJEICAO_783 = 'NF_STATUS_RET_REJEICAO_783',
	NF_STATUS_RET_REJEICAO_784 = 'NF_STATUS_RET_REJEICAO_784',
	NF_STATUS_RET_REJEICAO_785 = 'NF_STATUS_RET_REJEICAO_785',
	NF_STATUS_RET_REJEICAO_786 = 'NF_STATUS_RET_REJEICAO_786',
	NF_STATUS_RET_REJEICAO_787 = 'NF_STATUS_RET_REJEICAO_787',
	NF_STATUS_RET_REJEICAO_788 = 'NF_STATUS_RET_REJEICAO_788',
	NF_STATUS_RET_REJEICAO_789 = 'NF_STATUS_RET_REJEICAO_789',
	NF_STATUS_RET_REJEICAO_790 = 'NF_STATUS_RET_REJEICAO_790',
	NF_STATUS_RET_REJEICAO_791 = 'NF_STATUS_RET_REJEICAO_791',
	NF_STATUS_RET_REJEICAO_792 = 'NF_STATUS_RET_REJEICAO_792',
	NF_STATUS_RET_REJEICAO_793 = 'NF_STATUS_RET_REJEICAO_793',
	NF_STATUS_RET_REJEICAO_794 = 'NF_STATUS_RET_REJEICAO_794',
	NF_STATUS_RET_REJEICAO_795 = 'NF_STATUS_RET_REJEICAO_795',
	NF_STATUS_RET_REJEICAO_796 = 'NF_STATUS_RET_REJEICAO_796',
	NF_STATUS_RET_REJEICAO_999 = 'NF_STATUS_RET_REJEICAO_999',
	NF_STATUS_RET_REJEICAO_201 = 'NF_STATUS_RET_REJEICAO_201',
	NF_STATUS_RET_REJEICAO_202 = 'NF_STATUS_RET_REJEICAO_202',
	NF_STATUS_RET_REJEICAO_203 = 'NF_STATUS_RET_REJEICAO_203',
	NF_STATUS_RET_REJEICAO_204 = 'NF_STATUS_RET_REJEICAO_204',
	NF_STATUS_RET_REJEICAO_205 = 'NF_STATUS_RET_REJEICAO_205',
	NF_STATUS_RET_REJEICAO_206 = 'NF_STATUS_RET_REJEICAO_206',
	NF_STATUS_RET_REJEICAO_207 = 'NF_STATUS_RET_REJEICAO_207',
	NF_STATUS_RET_REJEICAO_208 = 'NF_STATUS_RET_REJEICAO_208',
	NF_STATUS_RET_REJEICAO_209 = 'NF_STATUS_RET_REJEICAO_209',
	NF_STATUS_RET_REJEICAO_210 = 'NF_STATUS_RET_REJEICAO_210',
	NF_STATUS_RET_REJEICAO_211 = 'NF_STATUS_RET_REJEICAO_211',
	NF_STATUS_RET_REJEICAO_212 = 'NF_STATUS_RET_REJEICAO_212',
	NF_STATUS_RET_REJEICAO_213 = 'NF_STATUS_RET_REJEICAO_213',
	NF_STATUS_RET_REJEICAO_214 = 'NF_STATUS_RET_REJEICAO_214',
	NF_STATUS_RET_REJEICAO_215 = 'NF_STATUS_RET_REJEICAO_215',
	NF_STATUS_RET_REJEICAO_216 = 'NF_STATUS_RET_REJEICAO_216',
	NF_STATUS_RET_REJEICAO_217 = 'NF_STATUS_RET_REJEICAO_217',
	NF_STATUS_RET_REJEICAO_218 = 'NF_STATUS_RET_REJEICAO_218',
	NF_STATUS_RET_REJEICAO_219 = 'NF_STATUS_RET_REJEICAO_219',
	NF_STATUS_RET_REJEICAO_220 = 'NF_STATUS_RET_REJEICAO_220',
	NF_STATUS_RET_REJEICAO_221 = 'NF_STATUS_RET_REJEICAO_221',
	NF_STATUS_RET_REJEICAO_222 = 'NF_STATUS_RET_REJEICAO_222',
	NF_STATUS_RET_REJEICAO_223 = 'NF_STATUS_RET_REJEICAO_223',
	NF_STATUS_RET_REJEICAO_224 = 'NF_STATUS_RET_REJEICAO_224',
	NF_STATUS_RET_REJEICAO_225 = 'NF_STATUS_RET_REJEICAO_225',
	NF_STATUS_RET_REJEICAO_226 = 'NF_STATUS_RET_REJEICAO_226',
	NF_STATUS_RET_REJEICAO_227 = 'NF_STATUS_RET_REJEICAO_227',
	NF_STATUS_RET_REJEICAO_228 = 'NF_STATUS_RET_REJEICAO_228',
	NF_STATUS_RET_REJEICAO_229 = 'NF_STATUS_RET_REJEICAO_229',
	NF_STATUS_RET_REJEICAO_230 = 'NF_STATUS_RET_REJEICAO_230',
	NF_STATUS_RET_REJEICAO_231 = 'NF_STATUS_RET_REJEICAO_231',
	NF_STATUS_RET_REJEICAO_232 = 'NF_STATUS_RET_REJEICAO_232',
	NF_STATUS_RET_REJEICAO_233 = 'NF_STATUS_RET_REJEICAO_233',
	NF_STATUS_RET_REJEICAO_234 = 'NF_STATUS_RET_REJEICAO_234',
	NF_STATUS_RET_REJEICAO_235 = 'NF_STATUS_RET_REJEICAO_235',
	NF_STATUS_RET_REJEICAO_236 = 'NF_STATUS_RET_REJEICAO_236',
	NF_STATUS_RET_REJEICAO_237 = 'NF_STATUS_RET_REJEICAO_237',
	NF_STATUS_RET_REJEICAO_238 = 'NF_STATUS_RET_REJEICAO_238',
	NF_STATUS_RET_REJEICAO_239 = 'NF_STATUS_RET_REJEICAO_239',
	NF_STATUS_RET_REJEICAO_240 = 'NF_STATUS_RET_REJEICAO_240',
	NF_STATUS_RET_REJEICAO_241 = 'NF_STATUS_RET_REJEICAO_241',
	NF_STATUS_RET_REJEICAO_242 = 'NF_STATUS_RET_REJEICAO_242',
	NF_STATUS_RET_REJEICAO_243 = 'NF_STATUS_RET_REJEICAO_243',
	NF_STATUS_RET_REJEICAO_244 = 'NF_STATUS_RET_REJEICAO_244',
	NF_STATUS_RET_REJEICAO_245 = 'NF_STATUS_RET_REJEICAO_245',
	NF_STATUS_RET_REJEICAO_246 = 'NF_STATUS_RET_REJEICAO_246',
	NF_STATUS_RET_REJEICAO_247 = 'NF_STATUS_RET_REJEICAO_247',
	NF_STATUS_RET_REJEICAO_248 = 'NF_STATUS_RET_REJEICAO_248',
	NF_STATUS_RET_REJEICAO_249 = 'NF_STATUS_RET_REJEICAO_249',
	NF_STATUS_RET_REJEICAO_250 = 'NF_STATUS_RET_REJEICAO_250',
	NF_STATUS_RET_REJEICAO_251 = 'NF_STATUS_RET_REJEICAO_251',
	NF_STATUS_RET_REJEICAO_252 = 'NF_STATUS_RET_REJEICAO_252',
	NF_STATUS_RET_REJEICAO_253 = 'NF_STATUS_RET_REJEICAO_253',
	NF_STATUS_RET_REJEICAO_254 = 'NF_STATUS_RET_REJEICAO_254',
	NF_STATUS_RET_REJEICAO_255 = 'NF_STATUS_RET_REJEICAO_255',
	NF_STATUS_RET_REJEICAO_256 = 'NF_STATUS_RET_REJEICAO_256',
	NF_STATUS_RET_REJEICAO_257 = 'NF_STATUS_RET_REJEICAO_257',
	NF_STATUS_RET_REJEICAO_258 = 'NF_STATUS_RET_REJEICAO_258',
	NF_STATUS_RET_REJEICAO_259 = 'NF_STATUS_RET_REJEICAO_259',
	NF_STATUS_RET_REJEICAO_260 = 'NF_STATUS_RET_REJEICAO_260',
	NF_STATUS_RET_REJEICAO_261 = 'NF_STATUS_RET_REJEICAO_261',
	NF_STATUS_RET_REJEICAO_262 = 'NF_STATUS_RET_REJEICAO_262',
	NF_STATUS_RET_REJEICAO_263 = 'NF_STATUS_RET_REJEICAO_263',
	NF_STATUS_RET_REJEICAO_264 = 'NF_STATUS_RET_REJEICAO_264',
	NF_STATUS_RET_REJEICAO_265 = 'NF_STATUS_RET_REJEICAO_265',
	NF_STATUS_RET_REJEICAO_266 = 'NF_STATUS_RET_REJEICAO_266'
}

export enum TipoBandeiraEnum {
	BANDEIRA_CARTAO_CREDITO_VISA = 'BANDEIRA_CARTAO_CREDITO_VISA',
	BANDEIRA_CARTAO_CREDITO_MASTERCARD = 'BANDEIRA_CARTAO_CREDITO_MASTERCARD',
	BANDEIRA_CARTAO_CREDITO_DINERS = 'BANDEIRA_CARTAO_CREDITO_DINERS',
	BANDEIRA_CARTAO_CREDITO_AMEX = 'BANDEIRA_CARTAO_CREDITO_AMEX',
	BANDEIRA_CARTAO_CREDITO_SOLLO = 'BANDEIRA_CARTAO_CREDITO_SOLLO',
	BANDEIRA_CARTAO_CREDITO_SIDECARD = 'BANDEIRA_CARTAO_CREDITO_SIDECARD',
	BANDEIRA_CARTAO_CREDITO_PRIVATE_LABEL = 'BANDEIRA_CARTAO_CREDITO_PRIVATE_LABEL',
	BANDEIRA_CARTAO_CREDITO_REDESHOP = 'BANDEIRA_CARTAO_CREDITO_REDESHOP',
	BANDEIRA_CARTAO_CREDITO_PAO_ACUCAR = 'BANDEIRA_CARTAO_CREDITO_PAO_ACUCAR',
	BANDEIRA_CARTAO_CREDITO_FINIVEST = 'BANDEIRA_CARTAO_CREDITO_FINIVEST',
	BANDEIRA_CARTAO_CREDITO_JCB = 'BANDEIRA_CARTAO_CREDITO_JCB',
	BANDEIRA_CARTAO_CREDITO_HIPERCARD = 'BANDEIRA_CARTAO_CREDITO_HIPERCARD',
	BANDEIRA_CARTAO_CREDITO_AURA = 'BANDEIRA_CARTAO_CREDITO_AURA',
	BANDEIRA_CARTAO_CREDITO_LOSANGO = 'BANDEIRA_CARTAO_CREDITO_LOSANGO',
	BANDEIRA_CARTAO_CREDITO_SOROCRED = 'BANDEIRA_CARTAO_CREDITO_SOROCRED',
	BANDEIRA_CARTAO_CREDITO_CABAL = 'BANDEIRA_CARTAO_CREDITO_CABAL',
	BANDEIRA_CARTAO_CREDITO_ELO = 'BANDEIRA_CARTAO_CREDITO_ELO',
	BANDEIRA_CARTAO_CREDITO_POLICARD = 'BANDEIRA_CARTAO_CREDITO_POLICARD',
	BANDEIRA_CARTAO_CREDITO_BANESCARD = 'BANDEIRA_CARTAO_CREDITO_BANESCARD',
	BANDEIRA_CARTAO_CREDITO_CETELEM = 'BANDEIRA_CARTAO_CREDITO_CETELEM',
	BANDEIRA_CARTAO_CREDITO_SICREDI = 'BANDEIRA_CARTAO_CREDITO_SICREDI',
	BANDEIRA_CARTAO_CREDITO_COOPERCRED = 'BANDEIRA_CARTAO_CREDITO_COOPERCRED',
	BANDEIRA_CARTAO_CREDITO_AVISTA = 'BANDEIRA_CARTAO_CREDITO_AVISTA',
	BANDEIRA_CARTAO_CREDITO_CREDISYSTEM = 'BANDEIRA_CARTAO_CREDITO_CREDISYSTEM',
	BANDEIRA_CARTAO_CREDITO_BENPARA = 'BANDEIRA_CARTAO_CREDITO_BENPARA',
	BANDEIRA_CARTAO_CREDITO_AMAZONCARD = 'BANDEIRA_CARTAO_CREDITO_AMAZONCARD',
	BANDEIRA_CARTAO_CREDITO_YAMADA = 'BANDEIRA_CARTAO_CREDITO_YAMADA',
	BANDEIRA_CARTAO_CREDITO_GOIASCARD = 'BANDEIRA_CARTAO_CREDITO_GOIASCARD',
	BANDEIRA_CARTAO_CREDITO_CREDPAR = 'BANDEIRA_CARTAO_CREDITO_CREDPAR',
	BANDEIRA_CARTAO_CREDITO_BOTICARIO = 'BANDEIRA_CARTAO_CREDITO_BOTICARIO',
	BANDEIRA_CARTAO_CREDITO_ASCARD = 'BANDEIRA_CARTAO_CREDITO_ASCARD',
	BANDEIRA_CARTAO_CREDITO_JETPAR = 'BANDEIRA_CARTAO_CREDITO_JETPAR',
	BANDEIRA_CARTAO_CREDITO_MAXXCARD = 'BANDEIRA_CARTAO_CREDITO_MAXXCARD',
	BANDEIRA_CARTAO_CREDITO_GARANTIDO = 'BANDEIRA_CARTAO_CREDITO_GARANTIDO',
	BANDEIRA_CARTAO_CREDITO_AMAZON_PRIME = 'BANDEIRA_CARTAO_CREDITO_AMAZON_PRIME',
	BANDEIRA_CARTAO_CREDITO_CREDZ = 'BANDEIRA_CARTAO_CREDITO_CREDZ',
	BANDEIRA_CARTAO_CREDITO_CREDISHOP = 'BANDEIRA_CARTAO_CREDITO_CREDISHOP',
	BANDEIRA_CARTAO_CREDITO_LIBERCARD = 'BANDEIRA_CARTAO_CREDITO_LIBERCARD',
	BANDEIRA_CARTAO_CREDITO_MAXXICARD = 'BANDEIRA_CARTAO_CREDITO_MAXXICARD',
	BANDEIRA_CARTAO_CREDITO_PEELA = 'BANDEIRA_CARTAO_CREDITO_PEELA',
	BANDEIRA_CARTAO_CREDITO_BANRISUL = 'BANDEIRA_CARTAO_CREDITO_BANRISUL',
	BANDEIRA_CARTAO_CREDITO_VERDECARD = 'BANDEIRA_CARTAO_CREDITO_VERDECARD',
	BANDEIRA_CARTAO_CREDITO_REFEISUL = 'BANDEIRA_CARTAO_CREDITO_REFEISUL',
	BANDEIRA_CARTAO_DEBITO_GENERICO = 'BANDEIRA_CARTAO_DEBITO_GENERICO',
	BANDEIRA_CARTAO_DEBITO_ABRAPETITE = 'BANDEIRA_CARTAO_DEBITO_ABRAPETITE',
	BANDEIRA_CARTAO_DEBITO_MAESTRO = 'BANDEIRA_CARTAO_DEBITO_MAESTRO',
	BANDEIRA_CARTAO_DEBITO_VISA_ELECTRON = 'BANDEIRA_CARTAO_DEBITO_VISA_ELECTRON',
	BANDEIRA_CARTAO_DEBITO_CABAL_03 = 'BANDEIRA_CARTAO_DEBITO_CABAL_03',
	BANDEIRA_CARTAO_DEBITO_PRIVATE_LABEL = 'BANDEIRA_CARTAO_DEBITO_PRIVATE_LABEL',
	BANDEIRA_CARTAO_DEBITO_CABAL_12 = 'BANDEIRA_CARTAO_DEBITO_CABAL_12',
	BANDEIRA_CARTAO_DEBITO_ELO_13 = 'BANDEIRA_CARTAO_DEBITO_ELO_13',
	BANDEIRA_CARTAO_DEBITO_ELO_32 = 'BANDEIRA_CARTAO_DEBITO_ELO_32',
	BANDEIRA_CARTAO_DEBITO_POLICARD = 'BANDEIRA_CARTAO_DEBITO_POLICARD',
	BANDEIRA_CARTAO_DEBITO_BANESCARD = 'BANDEIRA_CARTAO_DEBITO_BANESCARD',
	BANDEIRA_CARTAO_DEBITO_HIPERCAD = 'BANDEIRA_CARTAO_DEBITO_HIPERCAD',
	BANDEIRA_CARTAO_DEBITO_SICREDI = 'BANDEIRA_CARTAO_DEBITO_SICREDI',
	BANDEIRA_CARTAO_DEBITO_BENPARA = 'BANDEIRA_CARTAO_DEBITO_BENPARA',
	BANDEIRA_CARTAO_DEBITO_SOROCRED = 'BANDEIRA_CARTAO_DEBITO_SOROCRED',
	BANDEIRA_CARTAO_DEBITO_MAXXICARD = 'BANDEIRA_CARTAO_DEBITO_MAXXICARD',
	BANDEIRA_CARTAO_DEBITO_BANRISUL = 'BANDEIRA_CARTAO_DEBITO_BANRISUL',
	BANDEIRA_CARTAO_GIFT_PRE_PAGO = 'BANDEIRA_CARTAO_GIFT_PRE_PAGO',
	BANDEIRA_CARTAO_GIFT_SODEXO = 'BANDEIRA_CARTAO_GIFT_SODEXO',
	BANDEIRA_CARTAO_GIFT_MAXXICARD = 'BANDEIRA_CARTAO_GIFT_MAXXICARD',
	BANDEIRA_CARTAO_GIFT_PEELA = 'BANDEIRA_CARTAO_GIFT_PEELA',
	BANDEIRA_CARTAO_VOUCHER_GENERICO = 'BANDEIRA_CARTAO_VOUCHER_GENERICO',
	BANDEIRA_CARTAO_VOUCHER_SENFFNET_ALIMENT = 'BANDEIRA_CARTAO_VOUCHER_SENFFNET_ALIMENT',
	BANDEIRA_CARTAO_VOUCHER_GREEN_CARD_ALIME = 'BANDEIRA_CARTAO_VOUCHER_GREEN_CARD_ALIME',
	BANDEIRA_CARTAO_VOUCHER_TICKET = 'BANDEIRA_CARTAO_VOUCHER_TICKET',
	BANDEIRA_CARTAO_VOUCHER_VISA_VALE = 'BANDEIRA_CARTAO_VOUCHER_VISA_VALE',
	BANDEIRA_CARTAO_VOUCHER_SODEXO = 'BANDEIRA_CARTAO_VOUCHER_SODEXO',
	BANDEIRA_CARTAO_VOUCHER_NUTRICASH = 'BANDEIRA_CARTAO_VOUCHER_NUTRICASH',
	BANDEIRA_CARTAO_VOUCHER_GREENCARD = 'BANDEIRA_CARTAO_VOUCHER_GREENCARD',
	BANDEIRA_CARTAO_VOUCHER_PLANVALE = 'BANDEIRA_CARTAO_VOUCHER_PLANVALE',
	BANDEIRA_CARTAO_VOUCHER_BANQUET = 'BANDEIRA_CARTAO_VOUCHER_BANQUET',
	BANDEIRA_CARTAO_VOUCHER_VEROCHEQUE = 'BANDEIRA_CARTAO_VOUCHER_VEROCHEQUE',
	BANDEIRA_CARTAO_VOUCHER_SAPORE = 'BANDEIRA_CARTAO_VOUCHER_SAPORE',
	BANDEIRA_CARTAO_VOUCHER_BNB_CLUBE = 'BANDEIRA_CARTAO_VOUCHER_BNB_CLUBE',
	BANDEIRA_CARTAO_VOUCHER_VALECARD = 'BANDEIRA_CARTAO_VOUCHER_VALECARD',
	BANDEIRA_CARTAO_VOUCHER_CABAL = 'BANDEIRA_CARTAO_VOUCHER_CABAL',
	BANDEIRA_CARTAO_VOUCHER_ELO = 'BANDEIRA_CARTAO_VOUCHER_ELO',
	BANDEIRA_CARTAO_VOUCHER_DISCOVERY = 'BANDEIRA_CARTAO_VOUCHER_DISCOVERY',
	BANDEIRA_CARTAO_VOUCHER_GOODCARD = 'BANDEIRA_CARTAO_VOUCHER_GOODCARD',
	BANDEIRA_CARTAO_VOUCHER_POLICARD = 'BANDEIRA_CARTAO_VOUCHER_POLICARD',
	BANDEIRA_CARTAO_VOUCHER_CARDSYSTEM = 'BANDEIRA_CARTAO_VOUCHER_CARDSYSTEM',
	BANDEIRA_CARTAO_VOUCHER_BONUS_CBA = 'BANDEIRA_CARTAO_VOUCHER_BONUS_CBA',
	BANDEIRA_CARTAO_VOUCHER_ALELO = 'BANDEIRA_CARTAO_VOUCHER_ALELO',
	BANDEIRA_CARTAO_VOUCHER_BANESCARD = 'BANDEIRA_CARTAO_VOUCHER_BANESCARD',
	BANDEIRA_CARTAO_VOUCHER_ALELO_REFEICAO = 'BANDEIRA_CARTAO_VOUCHER_ALELO_REFEICAO',
	BANDEIRA_CARTAO_VOUCHER_ALELO_ALIMENTA = 'BANDEIRA_CARTAO_VOUCHER_ALELO_ALIMENTA',
	BANDEIRA_CARTAO_VOUCHER_ALELO_CULTURA = 'BANDEIRA_CARTAO_VOUCHER_ALELO_CULTURA',
	BANDEIRA_CARTAO_VOUCHER_TICKET_REFEICAO = 'BANDEIRA_CARTAO_VOUCHER_TICKET_REFEICAO',
	BANDEIRA_CARTAO_VOUCHER_TICKET_ALIMENTA = 'BANDEIRA_CARTAO_VOUCHER_TICKET_ALIMENTA',
	BANDEIRA_CARTAO_VOUCHER_TICKET_PARCEIRO = 'BANDEIRA_CARTAO_VOUCHER_TICKET_PARCEIRO',
	BANDEIRA_CARTAO_VOUCHER_TICKET_CULTURA = 'BANDEIRA_CARTAO_VOUCHER_TICKET_CULTURA',
	BANDEIRA_CARTAO_VOUCHER_SODEXO_REFEICAO = 'BANDEIRA_CARTAO_VOUCHER_SODEXO_REFEICAO',
	BANDEIRA_CARTAO_VOUCHER_SODEXO_ALIMENTA = 'BANDEIRA_CARTAO_VOUCHER_SODEXO_ALIMENTA',
	BANDEIRA_CARTAO_VOUCHER_SODEXO_PREMIUM = 'BANDEIRA_CARTAO_VOUCHER_SODEXO_PREMIUM',
	BANDEIRA_CARTAO_VOUCHER_SODEXO_CULTURA = 'BANDEIRA_CARTAO_VOUCHER_SODEXO_CULTURA',
	BANDEIRA_CARTAO_VOUCHER_SODEXO_COMB = 'BANDEIRA_CARTAO_VOUCHER_SODEXO_COMB',
	BANDEIRA_CARTAO_VOUCHER_PLANVALE_CULTURA = 'BANDEIRA_CARTAO_VOUCHER_PLANVALE_CULTURA',
	BANDEIRA_CARTAO_VOUCHER_NUTRICASH_CULT = 'BANDEIRA_CARTAO_VOUCHER_NUTRICASH_CULT',
	BANDEIRA_CARTAO_VOUCHER_TICKET_COMB = 'BANDEIRA_CARTAO_VOUCHER_TICKET_COMB',
	BANDEIRA_CARTAO_VOUCHER_VALECARD_CULTURA = 'BANDEIRA_CARTAO_VOUCHER_VALECARD_CULTURA',
	BANDEIRA_CARTAO_VOUCHER_SOROCRED = 'BANDEIRA_CARTAO_VOUCHER_SOROCRED',
	BANDEIRA_CARTAO_VOUCHER_VALEMULTI = 'BANDEIRA_CARTAO_VOUCHER_VALEMULTI',
	BANDEIRA_CARTAO_VOUCHER_VALEFROTA_COMB = 'BANDEIRA_CARTAO_VOUCHER_VALEFROTA_COMB',
	BANDEIRA_CARTAO_VOUCHER_COOPERCRED = 'BANDEIRA_CARTAO_VOUCHER_COOPERCRED',
	BANDEIRA_CARTAO_VOUCHER_VALEFACIL = 'BANDEIRA_CARTAO_VOUCHER_VALEFACIL',
	BANDEIRA_CARTAO_VOUCHER_VR_REFEICAO = 'BANDEIRA_CARTAO_VOUCHER_VR_REFEICAO',
	BANDEIRA_CARTAO_VOUCHER_VR_ALIMENTACAO = 'BANDEIRA_CARTAO_VOUCHER_VR_ALIMENTACAO',
	BANDEIRA_CARTAO_VOUCHER_VR_COMBUSTIVEL = 'BANDEIRA_CARTAO_VOUCHER_VR_COMBUSTIVEL',
	BANDEIRA_CARTAO_VOUCHER_VR_CULTURA = 'BANDEIRA_CARTAO_VOUCHER_VR_CULTURA',
	BANDEIRA_CARTAO_VOUCHER_BANRISUL_CULTURA = 'BANDEIRA_CARTAO_VOUCHER_BANRISUL_CULTURA',
	BANDEIRA_CARTAO_VOUCHER_VR_BENEFICIO = 'BANDEIRA_CARTAO_VOUCHER_VR_BENEFICIO',
	BANDEIRA_CARTAO_VOUCHER_PLANVALE_BENE = 'BANDEIRA_CARTAO_VOUCHER_PLANVALE_BENE',
	BANDEIRA_CARTAO_VOUCHER_PLANVALE_ALIMEN = 'BANDEIRA_CARTAO_VOUCHER_PLANVALE_ALIMEN',
	BANDEIRA_CARTAO_VOUCHER_PLANVALE_REFEIC = 'BANDEIRA_CARTAO_VOUCHER_PLANVALE_REFEIC',
	BANDEIRA_CARTAO_VOUCHER_PLANVALE_COMB = 'BANDEIRA_CARTAO_VOUCHER_PLANVALE_COMB',
	BANDEIRA_CARTAO_VOUCHER_PLANVALE_FARM = 'BANDEIRA_CARTAO_VOUCHER_PLANVALE_FARM',
	BANDEIRA_CARTAO_VOUCHER_MAXXICARD_COMB = 'BANDEIRA_CARTAO_VOUCHER_MAXXICARD_COMB',
	BANDEIRA_CARTAO_VOUCHER_LIBERCARD = 'BANDEIRA_CARTAO_VOUCHER_LIBERCARD',
	BANDEIRA_CARTAO_VOUCHER_UP = 'BANDEIRA_CARTAO_VOUCHER_UP',
	BANDEIRA_CARTAO_VOUCHER_UP_COMBUSTIVEL = 'BANDEIRA_CARTAO_VOUCHER_UP_COMBUSTIVEL',
	BANDEIRA_CARTAO_VOUCHER_UP_CULTURA = 'BANDEIRA_CARTAO_VOUCHER_UP_CULTURA',
	BANDEIRA_CARTAO_VOUCHER_ELO_COMBUSTIVEL = 'BANDEIRA_CARTAO_VOUCHER_ELO_COMBUSTIVEL',
	BANDEIRA_CARTAO_VOUCHER_VR = 'BANDEIRA_CARTAO_VOUCHER_VR',
	BANDEIRA_CARTAO_VOUCHER_REFEISUL_COMB = 'BANDEIRA_CARTAO_VOUCHER_REFEISUL_COMB',
	BANDEIRA_CARTAO_VOUCHER_REFEISUL_ALIMENT = 'BANDEIRA_CARTAO_VOUCHER_REFEISUL_ALIMENT'
}

export enum TipoServicoEnum {
	TIPO_SERVICO_DEBITO_AVISTA = 'TIPO_SERVICO_DEBITO_AVISTA',
	TIPO_SERVICO_DEBITO_VOUCHER = 'TIPO_SERVICO_DEBITO_VOUCHER',
	TIPO_SERVICO_CREDITO_AVISTA = 'TIPO_SERVICO_CREDITO_AVISTA',
	TIPO_SERVICO_CREDITO_PARC_ADMIN = 'TIPO_SERVICO_CREDITO_PARC_ADMIN',
	TIPO_SERVICO_CREDITO_PARC_LOJA = 'TIPO_SERVICO_CREDITO_PARC_LOJA'
}

export enum TipoMovimentacaoOperacaoEnum {
	RETIRADA = 'RETIRADA',
	ENTRADA = 'ENTRADA'
}

export enum TipoMovimentacaoLancamentoOperacaoEnum {
	REGISTRADO_PDV = 'REGISTRADO_PDV',
	LANCADO_OPERADOR = 'LANCADO_OPERADOR',
	CONFERIDO_SUPERVISOR = 'CONFERIDO_SUPERVISOR'
}

export enum TipoTituloEnum {
	TITULO_PAGAR = 'TITULO_PAGAR',
	TITULO_RECEBER = 'TITULO_RECEBER'
}

export enum TipoStatusTituloEnum {
	TITULO_STATUS_ABERTO = 'TITULO_STATUS_ABERTO',
	TITULO_STATUS_BLOQUEADO = 'TITULO_STATUS_BLOQUEADO',
	TITULO_STATUS_CANCELADO = 'TITULO_STATUS_CANCELADO',
	TITULO_STATUS_NAO_CONFIRMADO = 'TITULO_STATUS_NAO_CONFIRMADO',
	TITULO_STATUS_PAGO = 'TITULO_STATUS_PAGO',
	TITULO_STATUS_PAGO_PARCIALMENTE = 'TITULO_STATUS_PAGO_PARCIALMENTE',
	TITULO_STATUS_PROTESTADO = 'TITULO_STATUS_PROTESTADO',
	TITULO_STATUS_EXCLUIDO = 'TITULO_STATUS_EXCLUIDO',
	TITULO_STATUS_RECEBIDO = 'TITULO_STATUS_RECEBIDO',
	TITULO_STATUS_RECEBIDO_PARCIALMENTE = 'TITULO_STATUS_RECEBIDO_PARCIALMENTE',
	TITULO_STATUS_PERDA = 'TITULO_STATUS_PERDA',
	TITULO_STATUS_RECEBIDO_ACORDO = 'TITULO_STATUS_RECEBIDO_ACORDO'
}

export enum TipoViaTransporteEnum {
	MARITIMA = 'MARITIMA',
	ENTRADA_SAIDA_FICTA = 'ENTRADA_SAIDA_FICTA',
	FLUVIAL = 'FLUVIAL',
	LACUSTRE = 'LACUSTRE',
	AEREA = 'AEREA',
	POSTAL = 'POSTAL',
	FERROVIARIA = 'FERROVIARIA',
	RODOVIARIA = 'RODOVIARIA',
	CONDUTO_REDE_TRANSMISSAO = 'CONDUTO_REDE_TRANSMISSAO',
	MEIOS_PROPRIOS = 'MEIOS_PROPRIOS'
}

export enum TipoIntermedioEnum {
	IMPORTACAO_POR_CONTA_PROPRIA = 'IMPORTACAO_POR_CONTA_PROPRIA',
	IMPORTACAO_POR_CONTA_E_ORDEM = 'IMPORTACAO_POR_CONTA_E_ORDEM',
	IMPORTACAO_POR_ENCOMENDA = 'IMPORTACAO_POR_ENCOMENDA'
}

export enum TotalizadoresOperacaoEnum {
	VENDAS = 'VENDAS',
	TICKET_MEDIO = 'TICKET_MEDIO',
	RECEBIMENTO = 'RECEBIMENTO'
}

export enum TipoTituloHistoricoEnum {
	TITULO_HISTORICO_LANCAMENTO = 'TITULO_HISTORICO_LANCAMENTO',
	TITULO_HISTORICO_ALTERADO = 'TITULO_HISTORICO_ALTERADO',
	TITULO_HISTORICO_PAGAMENTO = 'TITULO_HISTORICO_PAGAMENTO',
	TITULO_HISTORICO_PARCIAL = 'TITULO_HISTORICO_PARCIAL',
	TITULO_HISTORICO_CANCELAMENTO = 'TITULO_HISTORICO_CANCELAMENTO'
}

export enum TipoTituloOrigemEnum {
	TITULO_ORIGEM_MANUAL = 'TITULO_ORIGEM_MANUAL',
	TITULO_ORIGEM_VENDA = 'TITULO_ORIGEM_VENDA',
	TITULO_ORIGEM_ENTRADA_NOTA = 'TITULO_ORIGEM_ENTRADA_NOTA',
	TITULO_ORIGEM_SAIDA_NOTA = 'TITULO_ORIGEM_SAIDA_NOTA'
}

export enum TipoTituloAnexoEnum {
	ANEXO_PDF = 'ANEXO_PDF',
	ANEXO_IMG = 'ANEXO_IMG',
	ANEXO_URL = 'ANEXO_URL'
}
export enum TipoGatClient {
	GAT_CLIENT_STATUS_ATIVO = 'GAT_CLIENT_STATUS_ATIVO',
	GAT_CLIENT_STATUS_PAUSADO = 'GAT_CLIENT_STATUS_PAUSADO',
	GAT_CLIENT_STATUS_EM_ERRO = 'GAT_CLIENT_STATUS_EM_ERRO'
}

export enum TipoContaEnum {
	CONTA_CORRENTE = 'CONTA_CORRENTE',
	CONTA_POUPANCA = 'CONTA_POUPANCA',
	CONTA_INVESTIMENTO = 'CONTA_INVESTIMENTO',
	CONTA_SALARIO = 'CONTA_SALARIO',
	CONTA_OUTRA = 'CONTA_OUTRA',
	CONTA_INTERNA = 'CONTA_INTERNA'
}

export enum TipoFormaPagamentoOrigemEnum {
	FORMA_PAGAMENTO_ORIGEM_TEF = 'FORMA_PAGAMENTO_ORIGEM_TEF',
	FORMA_PAGAMENTO_ORIGEM_MAQUINA = 'FORMA_PAGAMENTO_ORIGEM_MAQUINA',
	FORMA_PAGAMENTO_ORIGEM_MANUAL = 'FORMA_PAGAMENTO_ORIGEM_MANUAL',
	FORMA_PAGAMENTO_ORIGEM_TRANSACAO_BANCARIA = 'FORMA_PAGAMENTO_ORIGEM_BANCARIA',
	FORMA_PAGAMENTO_ORIGEM_ONLINE = 'FORMA_PAGAMENTO_ORIGEM_ONLINE'
}

export enum TipoFormaPagamentoTrocoEnum {
	FORMA_PAGAMENTO_TROCO_DINHEIRO = 'FORMA_PAGAMENTO_TROCO_DINHEIRO',
	FORMA_PAGAMENTO_TROCO_VALE_COMPRA = 'FORMA_PAGAMENTO_TROCO_VALE_COMPRA',
	FORMA_PAGAMENTO_TROCO_NAO_PERMITE = 'FORMA_PAGAMENTO_TROCO_NAO_PERMITE'
}

export enum TipoFormaPagamentoUtilizacaoEnum {
	FORMA_PAGAMENTO_RECEBIMENTOS = 'FORMA_PAGAMENTO_RECEBIMENTOS',
	FORMA_PAGAMENTO_PAGAMENTOS = 'FORMA_PAGAMENTO_PAGAMENTOS',
	FORMA_PAGAMENTO_RECEBIMENTOS_PAGAMENTOS = 'FORMA_PAGAMENTO_RECEBIMENTOS_PAGAMENTOS'
}

export enum TipoFormaPagamentoBandeiraEnum {
	BANDEIRA_AM = 'BANDEIRA_AM',
	BANDEIRA_BANRC = 'BANDEIRA_BANRC',
	BANDEIRA_BANRD = 'BANDEIRA_BANRD',
	BANDEIRA_BON = 'BANDEIRA_BON',
	BANDEIRA_CHE = 'BANDEIRA_CHE',
	BANDEIRA_CPRCAR = 'BANDEIRA_CPRCAR',
	BANDEIRA_DIN = 'BANDEIRA_DIN',
	BANDEIRA_DNR = 'BANDEIRA_DNR',
	BANDEIRA_DNREST = 'BANDEIRA_DNREST',
	BANDEIRA_ELO = 'BANDEIRA_ELO',
	BANDEIRA_GER_CT = 'BANDEIRA_GER_CT',
	BANDEIRA_GOODC = 'BANDEIRA_GOODC',
	BANDEIRA_GRNCAR = 'BANDEIRA_GRNCAR',
	BANDEIRA_GRNCPL = 'BANDEIRA_GRNCPL',
	BANDEIRA_HIPER = 'BANDEIRA_HIPER',
	BANDEIRA_JALRE = 'BANDEIRA_JALRE',
	BANDEIRA_JSORE = 'BANDEIRA_JSORE',
	BANDEIRA_JTIAL = 'BANDEIRA_JTIAL',
	BANDEIRA_JTIRE = 'BANDEIRA_JTIRE',
	BANDEIRA_MC = 'BANDEIRA_MC',
	BANDEIRA_MCMA = 'BANDEIRA_MCMA',
	BANDEIRA_MEREST = 'BANDEIRA_MEREST',
	BANDEIRA_PAY = 'BANDEIRA_PAY',
	BANDEIRA_RAM = 'BANDEIRA_RAM',
	BANDEIRA_RDREST = 'BANDEIRA_RDREST',
	BANDEIRA_REC = 'BANDEIRA_REC',
	BANDEIRA_RED = 'BANDEIRA_RED',
	BANDEIRA_RHIP = 'BANDEIRA_RHIP',
	BANDEIRA_RSELE = 'BANDEIRA_RSELE',
	BANDEIRA_RSODEX = 'BANDEIRA_RSODEX',
	BANDEIRA_TRE = 'BANDEIRA_TRE',
	BANDEIRA_TVER = 'BANDEIRA_TVER',
	BANDEIRA_VALECA = 'BANDEIRA_VALECA',
	BANDEIRA_VERDEC = 'BANDEIRA_VERDEC',
	BANDEIRA_VIREST = 'BANDEIRA_VIREST',
	BANDEIRA_VIS = 'BANDEIRA_VIS',
	BANDEIRA_VISE = 'BANDEIRA_VISE',
	BANDEIRA_VOUCHER = 'BANDEIRA_VOUCHER',
	BANDEIRA_VR_SMA = 'BANDEIRA_VR_SMA',
	BANDEIRA_VSREST = 'BANDEIRA_VSREST',
	BANDEIRA_VVREST = 'BANDEIRA_VVREST',
	BANDEIRA_IFOODPGMT = 'BANDEIRA_IFOODPGMT'
}

export enum TipoDebitoCreditoEnum {
	MOVIMENTACAO_DEBITO = 'MOVIMENTACAO_DEBITO',
	MOVIMENTACAO_CREDITO = 'MOVIMENTACAO_CREDITO'
}

export enum TipoContaDFPEnum {
	DFP_RECEITA_BRUTA = 'DFP_RECEITA_BRUTA',
	DFP_MATERIA_PRIMA = 'DFP_MATERIA_PRIMA',
	DFP_MAO_OBRA = 'DFP_MAO_OBRA',
	DFP_DESP_OPERACIONAIS = 'DFP_DESP_OPERACIONAIS',
	DFP_DESP_NAO_OPERACIONAIS = 'DFP_DESP_NAO_OPERACIONAIS',
	DFP_IMPOSTOS = 'DFP_IMPOSTOS',
	DFP_OUTROS = 'DFP_OUTROS'
}

export enum TipoRepetirTituloEnum {
	REPETIR_TITULO_DIARIO = 'REPETIR_TITULO_DIARIO',
	REPETIR_TITULO_SEMANAL = 'REPETIR_TITULO_SEMANAL',
	REPETIR_TITULO_QUINZENAL = 'REPETIR_TITULO_QUINZENAL',
	REPETIR_TITULO_MENSAL = 'REPETIR_TITULO_MENSAL',
	REPETIR_TITULO_TRIMESTRAL = 'REPETIR_TITULO_TRIMESTRAL',
	REPETIR_TITULO_SEMESTRAL = 'REPETIR_TITULO_SEMESTRAL',
	REPETIR_TITULO_ANUAL = 'REPETIR_TITULO_ANUAL',
	REPETIR_TITULO_NENHUM_INTERVAL = 'REPETIR_TITULO_NENHUM_INTERVAL'
}

export enum TipoEmpresaEnum {
	FRANQUIA = 'FRANQUIA',
	REPRESENTANTE = 'REPRESENTANTE',
	FILIAL = 'FILIAL',
	MATRIZ = 'MATRIZ'
}

export enum TipoNaturezaEnum {
	EMPRESA_NATUREZA_EMPRESARIAL = 'EMPRESA_NATUREZA_EMPRESARIAL',
	EMPRESA_NATUREZA_COOPERATIVA = 'EMPRESA_NATUREZA_COOPERATIVA',
	EMPRESA_NATUREZA_ENTIDADE = 'EMPRESA_NATUREZA_ENTIDADE'
}

export enum TipoAtividadeEnum {
	EMPRESA_ATIVIDADE_INDUSTRIA = 'EMPRESA_ATIVIDADE_INDUSTRIA',
	EMPRESA_ATIVIDADE_SERVICO = 'EMPRESA_ATIVIDADE_SERVICO',
	EMPRESA_ATIVIDADE_COMERCIO = 'EMPRESA_ATIVIDADE_COMERCIO',
	EMPRESA_ATIVIDADE_FINANCEIRA = 'EMPRESA_ATIVIDADE_FINANCEIRA',
	EMPRESA_ATIVIDADE_IMOBILIARIA = 'EMPRESA_ATIVIDADE_IMOBILIARIA',
	EMPRESA_ATIVIDADE_OUTROS = 'EMPRESA_ATIVIDADE_OUTROS'
}

export enum TipoIncidenciaTributarioEnum {
	EMPRESA_INCIDENCIA_TRIB_NAO_CUMULATIVA = 'EMPRESA_INCIDENCIA_TRIB_NAO_CUMULATIVA',
	EMPRESA_INCIDENCIA_TRIB_CUMULATIVA = 'EMPRESA_INCIDENCIA_TRIB_CUMULATIVA',
	EMPRESA_INCIDENCIA_TRIB_AMBAS = 'EMPRESA_INCIDENCIA_TRIB_AMBAS'
}

export enum TipoApropriacaoCreditoEnum {
	EMPRESA_CREDITO_DIRETA = 'EMPRESA_CREDITO_DIRETA',
	EMPRESA_CREDITO_PROPORCIONAL = 'EMPRESA_CREDITO_PROPORCIONAL'
}

export enum TipoContribuicaoApuradaPeriodoEnum {
	EMPRESA_CONTRIB_ALIQ_BASICA = 'EMPRESA_CONTRIB_ALIQ_BASICA',
	EMPRESA_CONTRIB_ALIQ_ESPECIFICA = 'EMPRESA_CONTRIB_ALIQ_ESPECIFICA'
}

export enum TipoNotaFiscalEntradaEnum {
	NF_ENTRADA_XML = 'NF_ENTRADA_XML',
	NF_ENTRADA_DFE = 'NF_ENTRADA_DFE',
	NF_ENTRADA_CHAVE = 'NF_ENTRADA_CHAVE'
}

export enum TipoContaLancamentoStatusEnum {
	CONTA_LANCAMENTO_STATUS_ATIVO = 'CONTA_LANCAMENTO_STATUS_ATIVO',
	CONTA_LANCAMENTO_STATUS_EXCLUIDO = 'CONTA_LANCAMENTO_STATUS_EXCLUIDO'
}

export enum TipoComportamentoPagamentoModalEnum {
	PAGAMENTO_NOVO = 'PAGAMENTO_NOVO',
	PAGAMENTO_PAGAR = 'PAGAMENTO_PAGAR',
	PAGAMENTO_VENCIDO = 'PAGAMENTO_VENCIDO',
	PAGAMENTO_PAGO = 'PAGAMENTO_PAGO',
	PAGAMENTO_EXCLUIDO = 'PAGAMENTO_EXCLUIDO'
}

export enum TipoComportamentoRecebimentoModalEnum {
	RECEBIMENTO_NOVO = 'RECEBIMENTO_NOVO',
	RECEBIMENTO_RECEBER = 'RECEBIMENTO_RECEBER',
	RECEBIMENTO_VENCIDO = 'RECEBIMENTO_VENCIDO',
	RECEBIMENTO_RECEBIDO = 'RECEBIMENTO_RECEBIDO',
	RECEBIMENTO_EXCLUIDO = 'RECEBIMENTO_EXCLUIDO',
	RECEBIMENTO_VISUALIZACAO = 'RECEBIMENTO_VISUALIZACAO',
	RECEBIMENTO_PERDA = 'RECEBIMENTO_PERDA',
	RECEBIMENTO_RECEBIDO_ACORDO = 'RECEBIMENTO_RECEBIDO_ACORDO',
	RECEBIMENTO_CANCELADO = 'RECEBIMENTO_CANCELADO'
}

export enum TipoVeiculoEnum {
	MOTOCICLETA = 'MOTOCICLETA',
	CARRO = 'CARRO',
	CAMINHAO = 'CAMINHAO',
	ONIBUS = 'ONIBUS'
}

export enum TipoFreteEnum {
	FRETE_KM = 'FRETE_KM',
	FRETE_BAIRRO = 'FRETE_BAIRRO'
}

export enum TipoOrigemCadastroEnum {
	ORIGEM_CADASTRO_XML = 'ORIGEM_CADASTRO_XML',
	ORIGEM_CADASTRO_MANUAL = 'ORIGEM_CADASTRO_MANUAL',
	ORIGEM_CADASTRO_INTEGRACAO_ECOMMERCE = 'ORIGEM_CADASTRO_INTEGRACAO_ECOMMERCE'
}

export enum TipoPessoaEntradaEnum {
	PESSOA_CLIENTE = 'PESSOA_CLIENTE',
	PESSOA_FORNECEDOR = 'PESSOA_FORNECEDOR'
}

export enum TipoStatusNotaFiscalEntradaEnum {
	NFE_ENTRADA_STATUS_CONFIRMADA = 'NFE_ENTRADA_STATUS_CONFIRMADA',
	NFE_ENTRADA_STATUS_EM_CADASTRO = 'NFE_ENTRADA_STATUS_EM_CADASTRO',
	NFE_ENTRADA_STATUS_PRE_CADASTRO = 'NFE_ENTRADA_STATUS_PRE_CADASTRO',
	NFE_ENTRADA_STATUS_PENDENTE = 'NFE_ENTRADA_STATUS_PENDENTE'
}

export enum TipoSedeEnum {
	SEDE_PROPRIA = 'SEDE_PROPRIA',
	SEDE_ALUGADA = 'SEDE_ALUGADA',
	SEDE_OUTROS = 'SEDE_OUTROS'
}

export enum TipoIndicadorIeDestEnum {
	CONTRIBUINTE_ICMS = 'CONTRIBUINTE_ICMS',
	CONTRIBUINTE_ISENTO = 'CONTRIBUINTE_ISENTO',
	NAO_CONTRIBUINTE = 'NAO_CONTRIBUINTE'
}

export enum TipoImpressoraEnum {
	IMP_JATO_LASER = 'IMP_JATO_LASER',
	IMP_MATRICIAL = 'IMP_MATRICIAL',
	IMP_NAO_FISCAL = 'IMP_NAO_FISCAL',
	IMP_FISCAL = 'IMP_FISCAL',
	IMP_ETIQUETA = 'IMP_ETIQUETA'
}

export enum TipoModeloImpressoraEnum {
	IMP_MOD_TM_SERIES = 'IMP_MOD_TM_SERIES',
	IMP_MOD_MP_4200_TH = 'IMP_MOD_MP_4200_TH',
	IMP_MOD_MP_4000_TH = 'IMP_MOD_MP_4000_TH',
	IMP_MOD_MP_2500_TH = 'IMP_MOD_MP_2500_TH',
	IMP_MOD_ELGIN_I9 = 'IMP_MOD_ELGIN_I9',
	IMP_MOD_DIEBOLD_IM833 = 'IMP_MOD_DIEBOLD_IM833',
	IMP_MOD_ELGIN_L42 = 'IMP_MOD_ELGIN_L42',
	IMP_MOD_ARGOX = 'IMP_MOD_ARGOX',
	IMP_MOD_ELGIN_L42_PRO = 'IMP_MOD_ELGIN_L42_PRO'
}

export enum TipoModoImpressaoEnum {
	IMPRESSORA_MODO_IMPRESSAO_NOME = 'IMPRESSORA_MODO_IMPRESSAO_NOME',
	IMPRESSORA_MODO_IMPRESSAO_PORTA = 'IMPRESSORA_MODO_IMPRESSAO_PORTA'
}

export enum TipoPapelEnum {
	IMPRESSORA_PAPEL_FC = 'IMPRESSORA_PAPEL_FC',
	IMPRESSORA_PAPEL_FCM = 'IMPRESSORA_PAPEL_FCM',
	IMPRESSORA_PAPEL_CARTA = 'IMPRESSORA_PAPEL_CARTA',
	IMPRESSORA_PAPEL_CARTA_METADE = 'IMPRESSORA_PAPEL_CARTA_METADE',
	IMPRESSORA_PAPEL_A4 = 'IMPRESSORA_PAPEL_A4',
	IMPRESSORA_PAPEL_A5 = 'IMPRESSORA_PAPEL_A5',
	IMPRESSORA_PAPEL_A6 = 'IMPRESSORA_PAPEL_A6',
	IMPRESSORA_PAPEL_BOBINA = 'IMPRESSORA_PAPEL_BOBINA'
}

export enum TipoPapelOrientacaoEnum {
	IMPRESSORA_PAPEL_ORIENTACAO_RETRATO = 'IMPRESSORA_PAPEL_ORIENTACAO_RETRATO',
	IMPRESSORA_PAPEL_ORIENTACAO_PAISAGEM = 'IMPRESSORA_PAPEL_ORIENTACAO_PAISAGEM'
}

export enum TipoTecladoEnum {
	TECLADO_NORMAL_ABNT = 'TECLADO_NORMAL_ABNT',
	TECLADO_GERTEC = 'TECLADO_GERTEC',
	TECLADO_KEYTEC = 'TECLADO_KEYTEC',
	TECLADO_SMAK = 'TECLADO_SMAK'
}

export enum TipoPessoaEnum {
	FISICA = 'FISICA',
	JURIDICA = 'JURIDICA',
	EXTERIOR = 'EXTERIOR'
}

export enum TipoEnderecoEletronicoEnum {
	ENDERECO_ELETRONICO_TWITTER = 'ENDERECO_ELETRONICO_TWITTER',
	ENDERECO_ELETRONICO_IFOOD = 'ENDERECO_ELETRONICO_IFOOD',
	ENDERECO_ELETRONICO_PEDIDO_ONLINE = 'ENDERECO_ELETRONICO_PEDIDO_ONLINE',
	ENDERECO_ELETRONICO_OUTRO = 'ENDERECO_ELETRONICO_OUTRO',
	ENDERECO_ELETRONICO_EMAIL = 'ENDERECO_ELETRONICO_EMAIL',
	ENDERECO_ELETRONICO_SITE = 'ENDERECO_ELETRONICO_SITE',
	ENDERECO_ELETRONICO_FACEBOOK = 'ENDERECO_ELETRONICO_FACEBOOK',
	ENDERECO_ELETRONICO_INSTAGRAM = 'ENDERECO_ELETRONICO_INSTAGRAM'
}

export enum TipoTelefoneEnum {
	TELEFONE_RESIDENCIAL = 'TELEFONE_RESIDENCIAL',
	TELEFONE_COMERCIAL = 'TELEFONE_COMERCIAL',
	TELEFONE_CELULAR = 'TELEFONE_CELULAR',
	TELEFONE_FAX = 'TELEFONE_FAX'
}

export enum TipoEnderecoEnum {
	RESIDENCIAL = 'RESIDENCIAL',
	TRABALHO = 'TRABALHO',
	COMERCIAL = 'COMERCIAL',
	COBRANCA = 'COBRANCA',
	ENTREGA = 'ENTREGA',
	OUTROS = 'OUTROS'
}

export enum TipoPessoaVinculoStatusEnum {
	PESSOA_VINCULO_STATUS_ATIVO = 'PESSOA_VINCULO_STATUS_ATIVO',
	PESSOA_VINCULO_STATUS_INATIVO = 'PESSOA_VINCULO_STATUS_INATIVO'
}

export enum TipoPessoaVinculoEnum {
	PESSOA_VINCULO_FILHO = 'PESSOA_VINCULO_FILHO',
	PESSOA_VINCULO_CONJUGE = 'PESSOA_VINCULO_CONJUGE',
	PESSOA_VINCULO_IRMAO = 'PESSOA_VINCULO_IRMAO',
	PESSOA_VINCULO_MAE = 'PESSOA_VINCULO_MAE',
	PESSOA_VINCULO_PAI = 'PESSOA_VINCULO_PAI',
	PESSOA_VINCULO_FUNCIONARIO = 'PESSOA_VINCULO_FUNCIONARIO',
	PESSOA_VINCULO_TERCEIROS = 'PESSOA_VINCULO_TERCEIROS',
	PESSOA_VINCULO_OUTROS = 'PESSOA_VINCULO_OUTROS'
}

export enum TipoSexoEnum {
	MASCULINO = 'MASCULINO',
	FEMININO = 'FEMININO'
}

export enum TipoEstadoCivilEnum {
	SOLTEIRO = 'SOLTEIRO',
	CASADO = 'CASADO',
	AMIGADO = 'AMIGADO',
	SEPARADO = 'SEPARADO',
	DIVORCIADO = 'DIVORCIADO',
	VIUVO = 'VIUVO'
}

export enum TipoStatusClienteEnum {
	CLIENTE_ATIVO = 'CLIENTE_ATIVO',
	CLIENTE_INATIVO = 'CLIENTE_INATIVO'
}

export enum TipoResidenciaEnum {
	PROPRIA = 'PROPRIA',
	ALUGADA = 'ALUGADA',
	CLIENTE_RESIDENCIA_OUTROS = 'CLIENTE_RESIDENCIA_OUTROS'
}

export enum TipoSetorEnum {
	SETOR_ADMINISTRACAO = 'SETOR_ADMINISTRACAO',
	SETOR_RH = 'SETOR_RH',
	SETOR_FINANCEIRO = 'SETOR_FINANCEIRO',
	SETOR_VENDAS = 'SETOR_VENDAS',
	SETOR_JURIDICO = 'SETOR_JURIDICO',
	SETOR_ESTOQUE = 'SETOR_ESTOQUE',
	SETOR_LOGISTICA = 'SETOR_LOGISTICA',
	SETOR_PRODUCAO = 'SETOR_PRODUCAO',
	SETOR_SUPORTE = 'SETOR_SUPORTE',
	SETOR_PROGRAMACAO = 'SETOR_PROGRAMACAO'
}

export enum TipoFuncaoEnum {
	FUNCAO_ADMINISTRADOR = 'FUNCAO_ADMINISTRADOR',
	FUNCAO_ADVOGADO = 'FUNCAO_ADVOGADO',
	FUNCAO_PROPRIETARIO = 'FUNCAO_PROPRIETARIO',
	FUNCAO_GERENTE = 'FUNCAO_GERENTE',
	FUNCAO_SUPERVISOR_VENDA = 'FUNCAO_SUPERVISOR_VENDA',
	FUNCAO_SUPERVISOR_FINANCEIRO = 'FUNCAO_SUPERVISOR_FINANCEIRO',
	FUNCAO_SUPERVISOR_ESTOQUE = 'FUNCAO_SUPERVISOR_ESTOQUE',
	FUNCAO_SUPERVISOR_TECNICO = 'FUNCAO_SUPERVISOR_TECNICO',
	FUNCAO_TECNICO = 'FUNCAO_TECNICO',
	FUNCAO_PROGRAMADOR = 'FUNCAO_PROGRAMADOR',
	FUNCAO_VENDEDOR = 'FUNCAO_VENDEDOR',
	FUNCAO_MOTORISTA = 'FUNCAO_MOTORISTA',
	FUNCAO_ENTREGADOR = 'FUNCAO_ENTREGADOR',
	FUNCAO_OPERADOR_CAIXA = 'FUNCAO_OPERADOR_CAIXA',
	FUNCAO_OPERADOR_TELEATENDIMENTO = 'FUNCAO_OPERADOR_TELEATENDIMENTO',
	FUNCAO_OPERADOR_ESTOQUE = 'FUNCAO_OPERADOR_ESTOQUE',
	FUNCAO_OPERADOR_FINANCEIRO = 'FUNCAO_OPERADOR_FINANCEIRO',
	FUNCAO_COZINHEIRO = 'FUNCAO_COZINHEIRO',
	FUNCAO_BALCONISTA = 'FUNCAO_BALCONISTA'
}

export enum TipoPessoaReferenciaEnum {
	PESSOA_REFERENCIA_PESSOAL = 'PESSOA_REFERENCIA_PESSOAL',
	PESSOA_REFERENCIA_COMERCIAL = 'PESSOA_REFERENCIA_COMERCIAL',
	PESSOA_REFERENCIA_PROFISSIONAL = 'PESSOA_REFERENCIA_PROFISSIONAL',
	PESSOA_REFERENCIA_OUTRA = 'PESSOA_REFERENCIA_OUTRA'
}

export enum TipoPessoaRestricaoEnum {
	PESSOA_RESTRICAO_SPC = 'PESSOA_RESTRICAO_SPC',
	PESSOA_RESTRICAO_INTERNA = 'PESSOA_RESTRICAO_INTERNA',
	PESSOA_RESTRICAO_CHEQUE = 'PESSOA_RESTRICAO_CHEQUE',
	PESSOA_RESTRICAO_OUTRA = 'PESSOA_RESTRICAO_OUTRA'
}

export enum TipoPessoaRestricaoBloqueioEnum {
	PESSOA_RES_BLOQ_TOTAL = 'PESSOA_RES_BLOQ_TOTAL',
	PESSOA_RES_BLOQ_LIBERACAO = 'PESSOA_RES_BLOQ_LIBERACAO',
	PESSOA_RES_BLOQ_DINHEIRO_CARTOES = 'PESSOA_RES_BLOQ_DINHEIRO_CARTOES',
	PESSOA_RES_BLOQ_DINHEIRO_CARTOES_BOLETOS = 'PESSOA_RES_BLOQ_DINHEIRO_CARTOES_BOLETOS'
}

export enum TipoCreditoIndicacaoEnum {
	TIPO_CREDITO_INDICACAO_DINHEIRO = 'TIPO_CREDITO_INDICACAO_DINHEIRO',
	TIPO_CREDITO_INDICACAO_PRODUTO = 'TIPO_CREDITO_INDICACAO_PRODUTO'
}

export enum GrupoUsuarioEnum {
	ADMINISTRADOR = 'ADMINISTRADOR',
	PROPRIETARIO = 'PROPRIETARIO',
	GERENTE = 'GERENTE',
	SUPERVISOR_VENDA = 'SUPERVISOR_VENDA',
	SUPERVISOR_FINANCEIRO = 'SUPERVISOR_FINANCEIRO',
	SUPERVISOR_ESTOQUE = 'SUPERVISOR_ESTOQUE',
	SUPERVISOR_TECNICO = 'SUPERVISOR_TECNICO',
	TECNICO = 'TECNICO',
	VENDEDOR = 'VENDEDOR',
	MOTORISTA = 'MOTORISTA',
	ENTREGADOR = 'ENTREGADOR',
	CONTADOR = 'CONTADOR',
	OPERADOR_CAIXA = 'OPERADOR_CAIXA',
	OPERADOR_TELEATENDIMENTO = 'OPERADOR_TELEATENDIMENTO',
	OPERADOR_ESTOQUE = 'OPERADOR_ESTOQUE',
	OPERADOR_FINANCEIRO = 'OPERADOR_FINANCEIRO',
	USUARIO = 'USUARIO'
}

export enum TipoMultaEnum {
	BOLETO_CONF_MULTA_DIA = 'BOLETO_CONF_MULTA_DIA',
	BOLETO_CONF_MULTA_PORCENTAGEM = 'BOLETO_CONF_MULTA_PORCENTAGEM',
	BOLETO_CONF_MULTA_ISENTO = 'BOLETO_CONF_MULTA_ISENTO'
}

export enum TipoJurosEnum {
	BOLETO_CONF_JUROS_DIA = 'BOLETO_CONF_JUROS_DIA',
	BOLETO_CONF_JUROS_PORCENTAGEM = 'BOLETO_CONF_JUROS_PORCENTAGEM',
	BOLETO_CONF_JUROS_ISENTO = 'BOLETO_CONF_JUROS_ISENTO'
}

export enum TipoDescontoEnum {
	BOLETO_CONF_DESCONTO_VALOR = 'BOLETO_CONF_DESCONTO_VALOR',
	BOLETO_CONF_DESCONTO_PERCENTUAL = 'BOLETO_CONF_DESCONTO_PERCENTUAL'
}

export enum TipoBaixaEnum {
	BOLETO_CONF_BAIXA_DEVOLVER = 'BOLETO_CONF_BAIXA_DEVOLVER',
	BOLETO_CONF_BAIXA_NAO_DEVOLVER = 'BOLETO_CONF_BAIXA_NAO_DEVOLVER'
}

export enum TipoProtestoEnum {
	BOLETO_CONF_PROTESTO_NAO_PROTESTAR = 'BOLETO_CONF_PROTESTO_NAO_PROTESTAR',
	BOLETO_CONF_PROTESTO_PROTESTAR = 'BOLETO_CONF_PROTESTO_PROTESTAR'
}

export enum TipoEventoEnum {
	INSERT = 'INSERT',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE'
}

export enum TipoDevolucaoMotivoEnum {
	DEVOLUCAO_MOTIVO_TROCA = 'DEVOLUCAO_MOTIVO_TROCA',
	DEVOLUCAO_MOTIVO_DEFEITO = 'DEVOLUCAO_MOTIVO_DEFEITO',
	DEVOLUCAO_MOTIVO_CONSERTO = 'DEVOLUCAO_MOTIVO_CONSERTO',
	DEVOLUCAO_MOTIVO_GARANTIA = 'DEVOLUCAO_MOTIVO_GARANTIA'
}

export enum TipoDevolucaoEnum {
	DEVOLUCAO_FORNECEDOR = 'DEVOLUCAO_FORNECEDOR',
	DEVOLUCAO_CLIENTE = 'DEVOLUCAO_CLIENTE'
}

export enum TipoDevolucaoEstornoEnum {
	DEVOLUCAO_CREDITO = 'DEVOLUCAO_CREDITO',
	DEVOLUCAO_DINHEIRO = 'DEVOLUCAO_DINHEIRO'
}

export enum TipoPessoaDevolucaoEnum {
	PESSOA_CLIENTE = 'PESSOA_CLIENTE',
	PESSOA_USUARIO = 'PESSOA_USUARIO',
	PESSOA_VENDEDOR = 'PESSOA_VENDEDOR',
	PESSOA_SUPERVIDOR = 'PESSOA_SUPERVIDOR',
	PESSOA_OPERADOR_CAIXA = 'PESSOA_OPERADOR_CAIXA',
	PESSOA_FORNECEDOR = 'PESSOA_FORNECEDOR'
}

export enum TipoDevolucaoVinculoEnum {
	DEVOLUCAO_VINCULO_PEDIDO = 'DEVOLUCAO_VINCULO_PEDIDO',
	DEVOLUCAO_VINCULO_NOTA_FISCAL = 'DEVOLUCAO_VINCULO_NOTA_FISCAL',
	DEVOLUCAO_VINCULO_NOTA_FISCAL_ENTRADA = 'DEVOLUCAO_VINCULO_NOTA_FISCAL_ENTRADA'
}

export enum TipoMovimentacaoEnum {
	ITEM_ESTOQUE_FLUXO_MOVIMENTACAO_ENTRADA = 'ITEM_ESTOQUE_FLUXO_MOVIMENTACAO_ENTRADA',
	ITEM_ESTOQUE_FLUXO_MOVIMENTACAO_SAIDA = 'ITEM_ESTOQUE_FLUXO_MOVIMENTACAO_SAIDA'
}

export enum TipoMotivoEnum {
	ITEM_ESTOQUE_FLUXO_MOTIVO_VENDA = 'ITEM_ESTOQUE_FLUXO_MOTIVO_VENDA',
	ITEM_ESTOQUE_FLUXO_MOTIVO_COMPRA = 'ITEM_ESTOQUE_FLUXO_MOTIVO_COMPRA',
	ITEM_ESTOQUE_FLUXO_MOTIVO_OUTROS = 'ITEM_ESTOQUE_FLUXO_MOTIVO_OUTROS',
	ITEM_ESTOQUE_FLUXO_MOTIVO_PRODUCAO = 'ITEM_ESTOQUE_FLUXO_MOTIVO_PRODUCAO',
	ITEM_ESTOQUE_FLUXO_MOTIVO_DEMEM = 'ITEM_ESTOQUE_FLUXO_MOTIVO_DEMEM',
	ITEM_ESTOQUE_FLUXO_MOTIVO_BENIF = 'ORIGEM_5_NACIONAL',
	ITEM_ESTOQUE_FLUXO_MOTIVO_SOBRA_PROD = 'ITEM_ESTOQUE_FLUXO_MOTIVO_SOBRA_PROD',
	ITEM_ESTOQUE_FLUXO_MOTIVO_FALTA = 'ITEM_ESTOQUE_FLUXO_MOTIVO_FALTA',
	ITEM_ESTOQUE_FLUXO_MOTIVO_INVENT = 'ITEM_ESTOQUE_FLUXO_MOTIVO_INVENT',
	ITEM_ESTOQUE_FLUXO_MOTIVO_BONIF = 'ITEM_ESTOQUE_FLUXO_MOTIVO_BONIF',
	ITEM_ESTOQUE_FLUXO_MOTIVO_TRANSF = 'ITEM_ESTOQUE_FLUXO_MOTIVO_TRANSF',
	ITEM_ESTOQUE_FLUXO_MOTIVO_PRE_VENDA = 'ITEM_ESTOQUE_FLUXO_MOTIVO_PRE_VENDA',
	ITEM_ESTOQUE_FLUXO_MOTIVO_MOBILE = 'ITEM_ESTOQUE_FLUXO_MOTIVO_MOBILE',
	ITEM_ESTOQUE_FLUXO_MOTIVO_CANC_COMPRA = 'ITEM_ESTOQUE_FLUXO_MOTIVO_CANC_COMPRA',
	ITEM_ESTOQUE_FLUXO_MOTIVO_CANC_BONIF = 'ITEM_ESTOQUE_FLUXO_MOTIVO_CANC_BONIF',
	ITEM_ESTOQUE_FLUXO_MOTIVO_CANC_TRANSF = 'ITEM_ESTOQUE_FLUXO_MOTIVO_CANC_TRANSF',
	ITEM_ESTOQUE_FLUXO_MOTIVO_RETIRADA = 'ITEM_ESTOQUE_FLUXO_MOTIVO_RETIRADA',
	ITEM_ESTOQUE_FLUXO_MOTIVO_RESERVA = 'ITEM_ESTOQUE_FLUXO_MOTIVO_RESERVA',
	ITEM_ESTOQUE_FLUXO_MOTIVO_DEVOLUCAO = 'ITEM_ESTOQUE_FLUXO_MOTIVO_DEVOLUCAO',
	ITEM_ESTOQUE_FLUXO_MOTIVO_CANCELAMENTO = 'ITEM_ESTOQUE_FLUXO_MOTIVO_CANCELAMENTO',
	ITEM_ESTOQUE_FLUXO_MOTIVO_TROCA = 'ITEM_ESTOQUE_FLUXO_MOTIVO_TROCA',
	ITEM_ESTOQUE_FLUXO_MOTIVO_PERDAS = 'ITEM_ESTOQUE_FLUXO_MOTIVO_PERDAS',
	ITEM_ESTOQUE_FLUXO_MOTIVO_OUTRAS = 'ITEM_ESTOQUE_FLUXO_MOTIVO_OUTRAS',
	ITEM_ESTOQUE_FLUXO_MOTIVO_FURTOS = 'ITEM_ESTOQUE_FLUXO_MOTIVO_FURTOS',
	ITEM_ESTOQUE_FLUXO_MOTIVO_CONSUMO = 'ITEM_ESTOQUE_FLUXO_MOTIVO_CONSUMO'
}

export enum TipoOrigemEstoqueFluxoEnum {
	VENDA = 'VENDA',
	DEVOLUCAO_FORNECEDOR = 'DEVOLUCAO_FORNECEDOR',
	DEVOLUCAO_CLIENTE = 'DEVOLUCAO_CLIENTE',
	AJUSTE_MANUAL = 'AJUSTE_MANUAL',
	AJUSTE_AUTOMATICO = 'AJUSTE_AUTOMATICO',
	TRANSFERENCIA_ESTOQUES = 'TRANSFERENCIA_ESTOQUES',
	TRANSFERENCIA_EMPRESAS = 'TRANSFERENCIA_EMPRESAS',
	ENTRADA_ITENS = 'ENTRADA_ITENS',
	NOTA_FISCAL_ENTRADA = 'NOTA_FISCAL_ENTRADA'
}

export enum TipoStatusTransferenciaLancamentoEnum {
	TRANSFERENCIA_LANCADA = 'TRANSFERENCIA_LANCADA',
	TRANSFERENCIA_NAO_LANCADA = 'TRANSFERENCIA_NAO_LANCADA'
}

export enum TipoFilaEnum {
	FILA_FLUXO_ESTOQUE = 'FILA_FLUXO_ESTOQUE',
	FILA_SALDO_CONTA_LANCAMENTO = 'FILA_SALDO_CONTA_LANCAMENTO',
	FILA_NFE_CONTINGENCIA = 'FILA_NFE_CONTINGENCIA'
}

export enum TipoFilaStatusEnum {
	FILA_STATUS_AGUARDANDO = 'FILA_STATUS_AGUARDANDO',
	FILA_STATUS_CONCLUIDA = 'FILA_STATUS_CONCLUIDA',
	FILA_STATUS_FALHA = 'FILA_STATUS_FALHA',
	FILA_STATUS_PAUSADA = 'FILA_STATUS_PAUSADA'
}

export enum TipoStatusNotaFiscalEnum {
	NF_STATUS_DENEGADA = 'NF_STATUS_DENEGADA',
	NF_STATUS_EM_CADASTRO = 'NF_STATUS_EM_CADASTRO',
	NF_STATUS_CANCELADA = 'NF_STATUS_CANCELADA',
	NF_STATUS_EM_ANALIZA = 'NF_STATUS_EM_ANALIZA',
	NF_STATUS_REJEITADA = 'NF_STATUS_REJEITADA',
	NF_STATUS_AUTORIZADA = 'NF_STATUS_AUTORIZADA',
	NF_STATUS_INUTILIZADA = 'NF_STATUS_INUTILIZADA',
	NF_STATUS_CONTINGENCIA = 'NF_STATUS_CONTINGENCIA',
	NF_AGUARDANDO_ENVIO = 'NF_AGUARDANDO_ENVIO'
}

export enum TipoAlteracaoEnum {
	ESTOQUE_ALTERACAO_ALTERAR = 'ESTOQUE_ALTERACAO_ALTERAR',
	ESTOQUE_ALTERACAO_AUMENTAR = 'ESTOQUE_ALTERACAO_AUMENTAR',
	ESTOQUE_ALTERACAO_DIMINUIR = 'ESTOQUE_ALTERACAO_DIMINUIR'
}

export enum TipoBalancaEnum {
	BALANCA_IMPRESSORA = 'BALANCA_IMPRESSORA',
	BALANCA_PDV = 'BALANCA_PDV'
}

export enum TipoBalancaModeloEnum {
	BALANCA_MODELO_TOLEDO = 'BALANCA_MODELO_TOLEDO',
	BALANCA_MODELO_FILIZOLA = 'BALANCA_MODELO_FILIZOLA',
	BALANCA_MODELO_URANO = 'BALANCA_MODELO_URANO',
	BALANCA_MODELO_OUTRA = 'BALANCA_MODELO_OUTRA',
	BALANCA_MODELO_URANO_TOP = 'BALANCA_MODELO_URANO_TOP',
	BALANCA_MODELO_URANO_TOP_MAX_2 = 'BALANCA_MODELO_URANO_TOP_MAX_2',
	BALANCA_MODELO_URANO_POP_Z = 'BALANCA_MODELO_URANO_POP_Z'
}

export enum TipoPromocaoEnum {
	PROMO_CLUBE = 'PROMO_CLUBE',
	PROMO_DIA_SEMANA = 'PROMO_DIA_SEMANA',
	PROMO_PERIODO = 'PROMO_PERIODO',
	PROMO_DESC_QUANTIDADE = 'PROMO_DESC_QUANTIDADE'
}

export enum TipoCalculoEnum {
	PROMOCAO_CALCULO_VALOR = 'PROMOCAO_CALCULO_VALOR',
	PROMOCAO_CALCULO_PORCENTAGEM = 'PROMOCAO_CALCULO_PORCENTAGEM'
}

export enum TipoSeparacaoEnum {
	ITEM_IMP_SEP_ITEM = 'ITEM_IMP_SEP_ITEM',
	ITEM_IMP_SEP_QTD = 'ITEM_IMP_SEP_QTD',
	ITEM_IMP_SEP_NAO_SEP = 'ITEM_IMP_SEP_NAO_SEP'
}

export enum TipoReciboSubItemEnum {
	RECIBO_SUBITEM_VALOR_NORMAL = 'RECIBO_SUBITEM_VALOR_NORMAL',
	RECIBO_SUBITEM_VALOR_PAI = 'RECIBO_SUBITEM_VALOR_PAI',
	RECIBO_SUBITEM_VALOR_FILHOS = 'RECIBO_SUBITEM_VALOR_FILHOS'
}

export enum TipoSubitemRelatorioEnum {
	RECIBO_SUBITEM_VALOR_NORMAL = 'RECIBO_SUBITEM_VALOR_NORMAL',
	RECIBO_SUBITEM_VALOR_PAI = 'RECIBO_SUBITEM_VALOR_PAI',
	RECIBO_SUBITEM_VALOR_FILHOS = 'RECIBO_SUBITEM_VALOR_FILHOS'
}

export enum TipoSugestaoEnum {
	LIGACAO_SUGESTAO = 'LIGACAO_SUGESTAO',
	LIGACAO_GENERICO = 'LIGACAO_GENERICO',
	LIGACAO_SIMILAR = 'LIGACAO_SIMILAR'
}

export enum TipoEstoqueEnum {
	ESTOQUE_PRINCIPAL = 'ESTOQUE_PRINCIPAL',
	ESTOQUE_DEPOSITO = 'ESTOQUE_DEPOSITO',
	ESTOQUE_DEFEITOS = 'ESTOQUE_DEFEITOS',
	ESTOQUE_ENCOMENDA = 'ESTOQUE_ENCOMENDA',
	ESTOQUE_RESERVA = 'ESTOQUE_RESERVA'
}

export enum TipoStatusGrupoEmbalagemEnum {
	GRUPO_EMBALAGEM_STATUS_PENDENTE = 'GRUPO_EMBALAGEM_STATUS_PENDENTE',
	GRUPO_EMBALAGEM_STATUS_UTILIZADO = 'GRUPO_EMBALAGEM_STATUS_UTILIZADO',
	GRUPO_EMBALAGEM_STATUS_UTILIZADO_PARC = 'GRUPO_EMBALAGEM_STATUS_UTILIZADO_PARC'
}

export enum TipoNotaFiscalPagamentoEnum {
	NF_PAGAMENTO_DINHEIRO = 'NF_PAGAMENTO_DINHEIRO',
	NF_PAGAMENTO_CHEQUE = 'NF_PAGAMENTO_CHEQUE',
	NF_PAGAMENTO_CART_CREDITO = 'NF_PAGAMENTO_CART_CREDITO',
	NF_PAGAMENTO_CART_DEBITO = 'NF_PAGAMENTO_CART_DEBITO',
	NF_PAGAMENTO_CRED_LOJA = 'NF_PAGAMENTO_CRED_LOJA',
	NF_PAGAMENTO_VALE_ALIMENT = 'NF_PAGAMENTO_VALE_ALIMENT',
	NF_PAGAMENTO_VALE_REFEICAO = 'NF_PAGAMENTO_VALE_REFEICAO',
	NF_PAGAMENTO_VALE_PRESENTE = 'NF_PAGAMENTO_VALE_PRESENTE',
	NF_PAGAMENTO_VALE_COMBU = 'NF_PAGAMENTO_VALE_COMBU',
	NF_PAGAMENTO_BOLETO = 'NF_PAGAMENTO_BOLETO',
	NF_PAGAMENTO_DEPOSITO = 'NF_PAGAMENTO_DEPOSITO',
	NF_PAGAMENTO_PIX = 'NF_PAGAMENTO_PIX',
	NF_PAGAMENTO_TRANSFERENCIA = 'NF_PAGAMENTO_TRANSFERENCIA',
	NF_PAGAMENTO_FIDELIDADE = 'NF_PAGAMENTO_FIDELIDADE',
	NF_PAGAMENTO_SEM_PAGAMENTO = 'NF_PAGAMENTO_SEM_PAGAMENTO',
	NF_PAGAMENTO_OUTROS = 'NF_PAGAMENTO_OUTROS',
	NF_PAGAMENTO_DUPLICATA_MERCANTIL = 'NF_PAGAMENTO_DUPLICATA_MERCANTIL'
}

export enum TipoNotaFiscalPagamentoBandeiraEnum {
	NF_PAGAMENTO_BANDEIRA_OUTROS = 'NF_PAGAMENTO_BANDEIRA_OUTROS',
	NF_PAGAMENTO_BANDEIRA_MASTERCARD = 'NF_PAGAMENTO_BANDEIRA_MASTERCARD',
	NF_PAGAMENTO_BANDEIRA_AMERICAN_EXPRESS = 'NF_PAGAMENTO_BANDEIRA_AMERICAN_EXPRESS',
	NF_PAGAMENTO_BANDEIRA_SOROCRED = 'NF_PAGAMENTO_BANDEIRA_SOROCRED',
	NF_PAGAMENTO_BANDEIRA_DINERS_CLUB = 'NF_PAGAMENTO_BANDEIRA_DINERS_CLUB',
	NF_PAGAMENTO_BANDEIRA_ELO = 'NF_PAGAMENTO_BANDEIRA_ELO',
	NF_PAGAMENTO_BANDEIRA_HIPERCARD = 'NF_PAGAMENTO_BANDEIRA_HIPERCARD',
	NF_PAGAMENTO_BANDEIRA_AURA = 'NF_PAGAMENTO_BANDEIRA_AURA',
	NF_PAGAMENTO_BANDEIRA_CABAL = 'NF_PAGAMENTO_BANDEIRA_CABAL',
	NF_PAGAMENTO_BANDEIRA_ALELO = 'NF_PAGAMENTO_BANDEIRA_ALELO',
	NF_PAGAMENTO_BANDEIRA_BANES_CARD = 'NF_PAGAMENTO_BANDEIRA_BANES_CARD',
	NF_PAGAMENTO_BANDEIRA_CALCARD = 'NF_PAGAMENTO_BANDEIRA_CALCARD',
	NF_PAGAMENTO_BANDEIRA_CREDZ = 'NF_PAGAMENTO_BANDEIRA_CREDZ',
	NF_PAGAMENTO_BANDEIRA_DISCOVER = 'NF_PAGAMENTO_BANDEIRA_DISCOVER',
	NF_PAGAMENTO_BANDEIRA_GOODCARD = 'NF_PAGAMENTO_BANDEIRA_GOODCARD',
	NF_PAGAMENTO_BANDEIRA_GREENCARD = 'NF_PAGAMENTO_BANDEIRA_GREENCARD',
	NF_PAGAMENTO_BANDEIRA_HIPER = 'NF_PAGAMENTO_BANDEIRA_HIPER',
	NF_PAGAMENTO_BANDEIRA_JCB = 'NF_PAGAMENTO_BANDEIRA_JCB',
	NF_PAGAMENTO_BANDEIRA_MAIS = 'NF_PAGAMENTO_BANDEIRA_MAIS',
	NF_PAGAMENTO_BANDEIRA_MAXVAN = 'NF_PAGAMENTO_BANDEIRA_MAXVAN',
	NF_PAGAMENTO_BANDEIRA_POLICARD = 'NF_PAGAMENTO_BANDEIRA_POLICARD',
	NF_PAGAMENTO_BANDEIRA_REDECOMPRAS = 'NF_PAGAMENTO_BANDEIRA_REDECOMPRAS',
	NF_PAGAMENTO_BANDEIRA_SODEXO = 'NF_PAGAMENTO_BANDEIRA_SODEXO',
	NF_PAGAMENTO_BANDEIRA_VALECARD = 'NF_PAGAMENTO_BANDEIRA_VALECARD',
	NF_PAGAMENTO_BANDEIRA_VEROCHEQUE = 'NF_PAGAMENTO_BANDEIRA_VEROCHEQUE',
	NF_PAGAMENTO_BANDEIRA_VR = 'NF_PAGAMENTO_BANDEIRA_VR',
	NF_PAGAMENTO_BANDEIRA_TICKET = 'NF_PAGAMENTO_BANDEIRA_TICKET',
	NF_PAGAMENTO_BANDEIRA_VISA = 'NF_PAGAMENTO_BANDEIRA_VISA'
}

export enum TipoStatusDFeEnum {
	DFE_STATUS_CRIADO = 'DFE_STATUS_CRIADO',
	DFE_STATUS_INUTILIZADO = 'DFE_STATUS_INUTILIZADO',
	DFE_STATUS_CANCELADO = 'DFE_STATUS_CANCELADO',
	DFE_STATUS_UTILIZADO = 'DFE_STATUS_UTILIZADO'
}

export enum TipoDocumentoDFeEnum {
	DFE_DOCUMENTO_EVENTO_CANCELAMENTO = 'DFE_DOCUMENTO_EVENTO_CANCELAMENTO',
	DFE_DOCUMENTO_EVENTO_OUTROS = 'DFE_DOCUMENTO_EVENTO_OUTROS',
	DFE_DOCUMENTO_NFE_SAIDA = 'DFE_DOCUMENTO_NFE_SAIDA',
	DFE_DOCUMENTO_NFE_ENTRADA = 'DFE_DOCUMENTO_NFE_ENTRADA',
	DFE_DOCUMENTO_OUTROS = 'DFE_DOCUMENTO_OUTROS'
}

export enum TipoPinpadEnum {
	PINPAD_GERTEC = 'PINPAD_GERTEC',
	PINPAD_VERIFONE = 'PINPAD_VERIFONE',
	PINPAD_INGENICO = 'PINPAD_INGENICO'
}

export enum TipoStatusTerminalEnum {
	PINPAD_GERTEC = 'PINPAD_GERTEC',
	PINPAD_VERIFONE = 'PINPAD_VERIFONE',
	PINPAD_INGENICO = 'PINPAD_INGENICO'
}

export enum TipoDescontoPedidoItemEnum {
	DESCONTO_GERAL = 'DESCONTO_GERAL',
	DESCONTO_ITEM = 'DESCONTO_ITEM',
	DESCONTO_ABATIMENTO = 'DESCONTO_ABATIMENTO',
	DESCONTO_PROMO_DESC_QUANTIDADE = 'DESCONTO_PROMO_DESC_QUANTIDADE',
	DESCONTO_PROMO_PERIODO = 'DESCONTO_PROMO_PERIODO',
	DESCONTO_PROMO_DIA_SEMANA = 'DESCONTO_PROMO_DIA_SEMANA',
	DESCONTO_VALE_COMPRA = 'DESCONTO_VALE_COMPRA'
}

export enum TipoPedidoPessoaEnum {
	JURIDICA = 'JURIDICA',
	FISICA = 'FISICA'
}

export enum TipoPedidoEnum {
	PEDIDO_VENDA = 'PEDIDO_VENDA',
	PEDIDO_PRE_VENDA = 'PEDIDO_PRE_VENDA',
	PEDIDO_ORCAMENTO = 'PEDIDO_ORCAMENTO'
}

export enum TipoDepartamentoEnum {
	DEPARTAMENTO_CONSUMO = 'DEPARTAMENTO_CONSUMO',
	DEPARTAMENTO_COLABORADOR = 'DEPARTAMENTO_COLABORADOR',
	DEPARTAMENTO_SETOR = 'DEPARTAMENTO_SETOR'
}

export enum TipoPerdaConsumoStatusEnum {
	PERDA_CONSUMO_STATUS_CADASTRO = 'PERDA_CONSUMO_STATUS_CADASTRO',
	PERDA_CONSUMO_STATUS_CONFIRMADO = 'PERDA_CONSUMO_STATUS_CONFIRMADO'
}

export enum TipoPerdaConsumoEnum {
	PERDA_CONSUMO_CONSUMO = 'PERDA_CONSUMO_CONSUMO',
	PERDA_CONSUMO_PERDA = 'PERDA_CONSUMO_PERDA'
}
// export enum TipoEmissorRGEnum {
//     POLICIA_CIVIL = 'Polícia Cívil',
//     SSP = '10 - SSP',
//     ORGANISMOS_MILITARES = '40- Organismos Militares',
//     COMANDO_AERONAUTICA = '41- Comandoda Aeronáutica(ExMinistériodaAeronáutica)',
//     COMANDO_EXERCITO = '42- Comandodo Exército(ExMinistériodoExército)',
//     COMANDO_MARINHA = '43- Comandoda Marinha(ExMinistériodaMarinha)',
//     POLICIA_FEDERAL = '44- Polícia Federal',
//     CARTEIRA_DE_IDENTIDADE_CLASSISTA = '60- Carteira de Identidade Classista',
//     CONSELHO_REGINAL_DE_ADMINISTRACAO = '61- Conselho Regional de Administração',
//     CONSELHO_REGINAL_DE_ASSIST_SOCIAL = '62- Conselho Regional de Assist.Social',
//     CONSELHO_REGINAL_DE_BIBLIOTECONOMIA = '63- Conselho Regional de Biblioteconomia',
//     CONSELHO_REGINAL_DE_CONTABILIDADE = '64- Conselho Regional de Contabilidade',
//     CONSELHO_REGINAL_DE_CORRETORES_IMOVEIS = '65- Conselho Regional de Corretores Imóveis',
//     CONSELHO_REGINAL_DE_ENFERMAGEM = '66- Conselho Regional de Enfermagem',
//     CONSELHO_REGINAL_DE_ENGENHARIA_ARQUITETURA = '67- Conselho Regional de Engenharia, Arquitetura e Agronomia',
//     CONSELHO_REGINAL_DE_ESTATISTICA = '68- Conselho Regional de Estatística',
//     CONSELHO_REGINAL_DE_FARMACIA = '69- Conselho Regional de Farmácia',
//     CONSELHOR_REGINAL_DE_FISIOTERAPIA_E_TERAPIA_OCUPACIONAL = '70- Conselho Regional de Fisioterapia e Terapia Ocupacional',
//     CONSELHO_REGINAL_DE_MEDICINA = '71- Conselho Regional de Medicina',
//     CONSELHO_REGINAL_DE_MEDICINA_VETERINARIA = '72- Conselho Regional de Medicina Veterinária',
//     ORDEM_DOS_MUSICOS_DO_BRASIL = '73- Ordem dos Músicos do Brasil',
//     CONSELHO_REGINAL_DE_NUTRICAO = '74- Conselho Regional de Nutrição',
//     CONSELHO_REGINAL_DE_ODONTOLOGIA = '75- Conselho Regional de Odontologia',
//     CONSELHO_REGINAL_DE_PROFISSIONAIS_DE_RELACOES_PUBLICAS = '76- Conselho Regional de Profissionais de Relações Públicas',
//     CONSELHO_REGINAL_DE_PSICOLOGIA = '77- Conselho Regional de Psicologia',
//     CONSELHO_REGINAL_DE_QUIMICA = '78- Conselho Regional de Química',
//     CONSELHO_REGINAL_DE_REPRESENTANTES_COMERCIAIS = '79- Conselho Regional de Representantes Comerciais',
//     ORDEM_DOS_ADVOGADOS_DO_BRASIL = '80- Ordem dos Advogados do Brasil',
//     OUTROS_EMISSORES = '81- Outros Emissores',
//     DOCUMENTO_ESTRANGEIRO = '82- Documento Estrangeiro'
// }

export enum TipoGerenciarItemFiltroDataEnum {
	ULTIMA_VENDA = 'ULTIMA_VENDA',
	ULTIMA_ATUALIZACAO = 'ULTIMA_ATUALIZACAO',
	SEM_MOVIMENTACAO = 'SEM_MOVIMENTACAO'
}

export enum TipoVinculoVeiculoEnum {
	FROTA_CLIENTE = 'FROTA_CLIENTE',
	FROTA_FORNECEDOR = 'FROTA_FORNECEDOR',
	FROTA_EMPRESA = 'FROTA_EMPRESA'
}

export enum TipoStatusPedidoCompraEnum {
	PEDIDO_COMPRA_EM_CADASTRO = 'PEDIDO_COMPRA_EM_CADASTRO',
	PEDIDO_COMPRA_ENVIADO = 'PEDIDO_COMPRA_ENVIADO',
	PEDIDO_COMPRA_CANCELADO = 'PEDIDO_COMPRA_CANCELADO',
	PEDIDO_COMPRA_RECEBIDO = 'PEDIDO_COMPRA_RECEBIDO'
}

export enum TipoStatusOperacaoEnum {
	OPERACAO_ABERTA = 'OPERACAO_ABERTA',
	OPERACAO_FECHADA = 'OPERACAO_FECHADA',
	OPERACAO_CONCLUIDA = 'OPERACAO_CONCLUIDA'
}

export enum CnabEnum {
	CNAB_240 = '240',
	CNAB_400 = '400'
}
export enum WebserviceEnum {
	WEBSERVICE_V1 = 'v1',
	WEBSERVICE_V2 = 'v2'
}

export enum TipoConvenioCarteiraCodigoEnum {
	CONVENIO_CARTEIRA_COBRANCA_SIMPLES = 'CONVENIO_CARTEIRA_COBRANCA_SIMPLES',
	CONVENIO_CARTEIRA_COBRANCA_VINCULADA = 'CONVENIO_CARTEIRA_COBRANCA_VINCULADA',
	CONVENIO_CARTEIRA_COBRANCA_CAUCIONADA = 'CONVENIO_CARTEIRA_COBRANCA_CAUCIONADA',
	CONVENIO_CARTEIRA_COBRANCA_DESCONTADA = 'CONVENIO_CARTEIRA_COBRANCA_DESCONTADA',
	CONVENIO_CARTEIRA_COBRANCA_VENDOR = 'CONVENIO_CARTEIRA_COBRANCA_VINCULADA'
}

export enum TipoMultaIntegracaoEnum {
	BOLETO_CONF_MULTA_VALOR = 'BOLETO_CONF_MULTA_VALOR',
	BOLETO_CONF_MULTA_VALOR_PERCENTUAL = 'BOLETO_CONF_MULTA_VALOR_PERCENTUAL',
	BOLETO_CONF_NAO_REGISTRA_MULTA = 'BOLETO_CONF_NAO_REGISTRA_MULTA'
}

export enum TipoJurosIntegracaoEnum {
	BOLETO_CONF_INTEGRA_JUROS_DIA = 'BOLETO_CONF_INTEGRA_JUROS_DIA',
	BOLETO_CONF_INTEGRA_JUROS_TAXA_MENSAL = 'BOLETO_CONF_INTEGRA_JUROS_TAXA_MENSAL',
	BOLETO_CONF_INTEGRA_JUROS_ISENTO = 'BOLETO_CONF_INTEGRA_JUROS_ISENTO'
}

export enum TipoDescontoIntegracaoEnum {
	BOLETO_CONF_INTEGRA_DESC_SEM_DESC = 'BOLETO_CONF_INTEGRA_DESC_SEM_DESC',
	BOLETO_CONF_INTEGRA_DESC_VALOR_FIXO = 'BOLETO_CONF_INTEGRA_DESC_VALOR_FIXO',
	BOLETO_CONF_INTEGRA_DESC_PERCENTUAL = 'BOLETO_CONF_INTEGRA_DESC_PERCENTUAL',
	BOLETO_CONF_INTEGRA_DESC_ANT_DIA_CORRIDO = 'BOLETO_CONF_INTEGRA_DESC_ANT_DIA_CORRIDO',
	BOLETO_CONF_INTEGRA_DESC_SOBR_VALOR_D_C = 'BOLETO_CONF_INTEGRA_DESC_SOBR_VALOR_D_C',
	BOLETO_CONF_INTEGRA_DESC_SOBR_VALOR_D_U = 'BOLETO_CONF_INTEGRA_DESC_SOBR_VALOR_D_U',
	BOLETO_CONF_INTEGRA_DESC_CANC_DESC = 'BOLETO_CONF_INTEGRA_DESC_CANC_DESC'
}

export enum TipoBaixaIntegracaoEnum {
	BOLETO_CONF_INTEGRA_BAIXA_DEVOLVER = 'BOLETO_CONF_INTEGRA_BAIXA_DEVOLVER',
	BOLETO_CONF_INTEGRA_BAIXA_NAO_DEVOLVER = 'BOLETO_CONF_INTEGRA_BAIXA_NAO_DEVOLVER',
	BOLETO_CONF_INTEGRA_BAIXA_CANCELAR = 'BOLETO_CONF_INTEGRA_BAIXA_CANCELAR'
}

export enum TipoProtestoIntegracaoEnum {
	BOLETO_CONF_INTEGRA_PROTESTO_D_CORRIDO = 'BOLETO_CONF_INTEGRA_PROTESTO_D_CORRIDO',
	BOLETO_CONF_INTEGRA_PROTESTO_DIA_UTIL = 'BOLETO_CONF_INTEGRA_PROTESTO_DIA_UTIL',
	BOLETO_CONF_INTEGRA_PROTESTO_N_PROTEST = 'BOLETO_CONF_INTEGRA_PROTESTO_N_PROTEST',
	BOLETO_CONF_INTEGRA_PROTESTO_FIM_FALI_U = 'BOLETO_CONF_INTEGRA_PROTESTO_FIM_FALI_U',
	BOLETO_CONF_INTEGR_PROTESTO_FIM_FALI_CO = 'BOLETO_CONF_INTEGR_PROTESTO_FIM_FALI_CO',
	BOLETO_CONF_INTEGRA_PROTESTO_NG_SEM_PRO = 'BOLETO_CONF_INTEGRA_PROTESTO_NG_SEM_PRO',
	BOLETO_CONF_INTEGRA_PROTESTO_CAN_PR_AUT = 'BOLETO_CONF_INTEGRA_PROTESTO_CAN_PR_AUT'
}

export enum TipoAceiteIntegracaoEnum {
	BOLETO_CONF_INTEGRA_ACEITE_ACEITO = 'BOLETO_CONF_INTEGRA_ACEITE_ACEITO',
	BOLETO_CONF_INTEGRA_ACEITE_N_ACEITO = 'BOLETO_CONF_INTEGRA_ACEITE_N_ACEITO'
}

export enum TipoEmissaoIntegracaoEnum {
	BOLETO_CONF_INTEGRA_EMISSAO_BANCO = 'BOLETO_CONF_INTEGRA_EMISSAO_BANCO',
	BOLETO_CONF_INTEGRA_EMISSAO_BENIFI = 'BOLETO_CONF_INTEGRA_EMISSAO_BENIFI'
}

export enum TipoRegistroIntegracaoEnum {
	BOLETO_CONF_INTEGRA_CATEG_SEM_REGISTRO = 'BOLETO_CONF_INTEGRA_CATEG_SEM_REGISTRO',
	BOLETO_CONF_INTEGRA_CATEG_COM_REGISTRO = 'BOLETO_CONF_INTEGRA_CATEG_COM_REGISTRO',
	BOLETO_CONF_INTEGRA_CATEG_IMP_BANCO = 'BOLETO_CONF_INTEGRA_CATEG_IMP_BANCO'
}

export enum TipoPostagemIntegracaoEnum {
	BOLETO_CONF_INTEGRA_POST_BANCO = 'BOLETO_CONF_INTEGRA_POST_BANCO',
	BOLETO_CONF_INTEGRA_POST_CEDENTE = 'BOLETO_CONF_INTEGRA_POST_CEDENTE'
}

export enum TransferenciaEnum {
	TRANSFERENCIA_CONSIGNACAO = 'TRANSFERENCIA_CONSIGNACAO',
	TRANSFERENCIA_ESTOQUE = 'TRANSFERENCIA_ESTOQUE',
	TRANSFERENCIA_BONIFICACAO = 'TRANSFERENCIA_BONIFICACAO',
	TRANSFERENCIA_ENTRADA_MANUAL = 'TRANSFERENCIA_ENTRADA_MANUAL',
	TRANSFERENCIA_ENTRADA_ONLINE = 'TRANSFERENCIA_ENTRADA_ONLINE'
}

export enum TipoTransferenciaEnum {
	TRANSFERENCIA_ENTRADA = 'TRANSFERENCIA_ENTRADA',
	TRANSFERENCIA_SAIDA = 'TRANSFERENCIA_SAIDA'
}

export enum TipoStatusTransferenciaEnum {
	TRANSFERENCIA_EM_CADASTRO = 'TRANSFERENCIA_EM_CADASTRO',
	TRANSFERENCIA_CONFIRMADA = 'TRANSFERENCIA_CONFIRMADA',
	TRANSFERENCIA_CANCELADA = 'TRANSFERENCIA_CANCELADA'
}

export enum TipoCodigoEtiquetaEnum {
	CODIGO_BARRAS = 'CODIGO_BARRAS',
	CODIGO_SEQUENCIAL = 'CODIGO_SEQUENCIAL',
	CODIGO_INTERNO = 'CODIGO_INTERNO'
}

export enum TipoStatusRetornoEnum {
	// NOTA_FISCAL_STATUS_RET_DENEGACAO
	NF_STATUS_RET_DENEGACAO_301 = 'NF_STATUS_RET_DENEGACAO_301',
	NF_STATUS_RET_DENEGACAO_302 = 'NF_STATUS_RET_DENEGACAO_302',
	NF_STATUS_RET_DENEGACAO_303 = 'NF_STATUS_RET_DENEGACAO_303',
	// NOTA_FISCAL_STATUS_RET_PROC
	NF_STATUS_RET_PROC_100 = 'NF_STATUS_RET_PROC_100',
	NF_STATUS_RET_PROC_101 = 'NF_STATUS_RET_PROC_101',
	NF_STATUS_RET_PROC_102 = 'NF_STATUS_RET_PROC_102',
	NF_STATUS_RET_PROC_103 = 'NF_STATUS_RET_PROC_103',
	NF_STATUS_RET_PROC_104 = 'NF_STATUS_RET_PROC_104',
	NF_STATUS_RET_PROC_105 = 'NF_STATUS_RET_PROC_105',
	NF_STATUS_RET_PROC_106 = 'NF_STATUS_RET_PROC_106',
	NF_STATUS_RET_PROC_107 = 'NF_STATUS_RET_PROC_107',
	NF_STATUS_RET_PROC_108 = 'NF_STATUS_RET_PROC_108',
	NF_STATUS_RET_PROC_109 = 'NF_STATUS_RET_PROC_109',
	NF_STATUS_RET_PROC_110 = 'NF_STATUS_RET_PROC_110',
	NF_STATUS_RET_PROC_111 = 'NF_STATUS_RET_PROC_111',
	NF_STATUS_RET_PROC_112 = 'NF_STATUS_RET_PROC_112',
	NF_STATUS_RET_PROC_124 = 'NF_STATUS_RET_PROC_124',
	NF_STATUS_RET_PROC_128 = 'NF_STATUS_RET_PROC_128',
	NF_STATUS_RET_PROC_135 = 'NF_STATUS_RET_PROC_135',
	NF_STATUS_RET_PROC_136 = 'NF_STATUS_RET_PROC_136',
	NF_STATUS_RET_PROC_137 = 'NF_STATUS_RET_PROC_137',
	NF_STATUS_RET_PROC_138 = 'NF_STATUS_RET_PROC_138',
	NF_STATUS_RET_PROC_139 = 'NF_STATUS_RET_PROC_139',
	NF_STATUS_RET_PROC_140 = 'NF_STATUS_RET_PROC_140',
	NF_STATUS_RET_PROC_142 = 'NF_STATUS_RET_PROC_142',
	NF_STATUS_RET_PROC_150 = 'NF_STATUS_RET_PROC_150',
	NF_STATUS_RET_PROC_151 = 'NF_STATUS_RET_PROC_151',
	// NOTA_FISCAL_STATUS_RET_REJEICAO
	NF_STATUS_RET_REJEICAO_201 = 'NF_STATUS_RET_REJEICAO_201',
	NF_STATUS_RET_REJEICAO_202 = 'NF_STATUS_RET_REJEICAO_202',
	NF_STATUS_RET_REJEICAO_203 = 'NF_STATUS_RET_REJEICAO_203',
	NF_STATUS_RET_REJEICAO_204 = 'NF_STATUS_RET_REJEICAO_204',
	NF_STATUS_RET_REJEICAO_205 = 'NF_STATUS_RET_REJEICAO_205',
	NF_STATUS_RET_REJEICAO_206 = 'NF_STATUS_RET_REJEICAO_206',
	NF_STATUS_RET_REJEICAO_207 = 'NF_STATUS_RET_REJEICAO_207',
	NF_STATUS_RET_REJEICAO_208 = 'NF_STATUS_RET_REJEICAO_208',
	NF_STATUS_RET_REJEICAO_209 = 'NF_STATUS_RET_REJEICAO_209',
	NF_STATUS_RET_REJEICAO_210 = 'NF_STATUS_RET_REJEICAO_210',
	NF_STATUS_RET_REJEICAO_211 = 'NF_STATUS_RET_REJEICAO_211',
	NF_STATUS_RET_REJEICAO_212 = 'NF_STATUS_RET_REJEICAO_212',
	NF_STATUS_RET_REJEICAO_213 = 'NF_STATUS_RET_REJEICAO_213',
	NF_STATUS_RET_REJEICAO_214 = 'NF_STATUS_RET_REJEICAO_214',
	NF_STATUS_RET_REJEICAO_215 = 'NF_STATUS_RET_REJEICAO_215',
	NF_STATUS_RET_REJEICAO_216 = 'NF_STATUS_RET_REJEICAO_216',
	NF_STATUS_RET_REJEICAO_217 = 'NF_STATUS_RET_REJEICAO_217',
	NF_STATUS_RET_REJEICAO_218 = 'NF_STATUS_RET_REJEICAO_218',
	NF_STATUS_RET_REJEICAO_219 = 'NF_STATUS_RET_REJEICAO_219',
	NF_STATUS_RET_REJEICAO_220 = 'NF_STATUS_RET_REJEICAO_220',
	NF_STATUS_RET_REJEICAO_221 = 'NF_STATUS_RET_REJEICAO_221',
	NF_STATUS_RET_REJEICAO_222 = 'NF_STATUS_RET_REJEICAO_222',
	NF_STATUS_RET_REJEICAO_223 = 'NF_STATUS_RET_REJEICAO_223',
	NF_STATUS_RET_REJEICAO_224 = 'NF_STATUS_RET_REJEICAO_224',
	NF_STATUS_RET_REJEICAO_225 = 'NF_STATUS_RET_REJEICAO_225',
	NF_STATUS_RET_REJEICAO_226 = 'NF_STATUS_RET_REJEICAO_226',
	NF_STATUS_RET_REJEICAO_227 = 'NF_STATUS_RET_REJEICAO_227',
	NF_STATUS_RET_REJEICAO_228 = 'NF_STATUS_RET_REJEICAO_228',
	NF_STATUS_RET_REJEICAO_229 = 'NF_STATUS_RET_REJEICAO_229',
	NF_STATUS_RET_REJEICAO_230 = 'NF_STATUS_RET_REJEICAO_230',
	NF_STATUS_RET_REJEICAO_231 = 'NF_STATUS_RET_REJEICAO_231',
	NF_STATUS_RET_REJEICAO_232 = 'NF_STATUS_RET_REJEICAO_232',
	NF_STATUS_RET_REJEICAO_233 = 'NF_STATUS_RET_REJEICAO_233',
	NF_STATUS_RET_REJEICAO_234 = 'NF_STATUS_RET_REJEICAO_234',
	NF_STATUS_RET_REJEICAO_235 = 'NF_STATUS_RET_REJEICAO_235',
	NF_STATUS_RET_REJEICAO_236 = 'NF_STATUS_RET_REJEICAO_236',
	NF_STATUS_RET_REJEICAO_237 = 'NF_STATUS_RET_REJEICAO_237',
	NF_STATUS_RET_REJEICAO_238 = 'NF_STATUS_RET_REJEICAO_238',
	NF_STATUS_RET_REJEICAO_239 = 'NF_STATUS_RET_REJEICAO_239',
	NF_STATUS_RET_REJEICAO_240 = 'NF_STATUS_RET_REJEICAO_240',
	NF_STATUS_RET_REJEICAO_241 = 'NF_STATUS_RET_REJEICAO_241',
	NF_STATUS_RET_REJEICAO_242 = 'NF_STATUS_RET_REJEICAO_242',
	NF_STATUS_RET_REJEICAO_243 = 'NF_STATUS_RET_REJEICAO_243',
	NF_STATUS_RET_REJEICAO_244 = 'NF_STATUS_RET_REJEICAO_244',
	NF_STATUS_RET_REJEICAO_245 = 'NF_STATUS_RET_REJEICAO_245',
	NF_STATUS_RET_REJEICAO_246 = 'NF_STATUS_RET_REJEICAO_246',
	NF_STATUS_RET_REJEICAO_247 = 'NF_STATUS_RET_REJEICAO_247',
	NF_STATUS_RET_REJEICAO_248 = 'NF_STATUS_RET_REJEICAO_248',
	NF_STATUS_RET_REJEICAO_249 = 'NF_STATUS_RET_REJEICAO_249',
	NF_STATUS_RET_REJEICAO_250 = 'NF_STATUS_RET_REJEICAO_250',
	NF_STATUS_RET_REJEICAO_251 = 'NF_STATUS_RET_REJEICAO_251',
	NF_STATUS_RET_REJEICAO_252 = 'NF_STATUS_RET_REJEICAO_252',
	NF_STATUS_RET_REJEICAO_253 = 'NF_STATUS_RET_REJEICAO_253',
	NF_STATUS_RET_REJEICAO_254 = 'NF_STATUS_RET_REJEICAO_254',
	NF_STATUS_RET_REJEICAO_255 = 'NF_STATUS_RET_REJEICAO_255',
	NF_STATUS_RET_REJEICAO_256 = 'NF_STATUS_RET_REJEICAO_256',
	NF_STATUS_RET_REJEICAO_257 = 'NF_STATUS_RET_REJEICAO_257',
	NF_STATUS_RET_REJEICAO_258 = 'NF_STATUS_RET_REJEICAO_258',
	NF_STATUS_RET_REJEICAO_259 = 'NF_STATUS_RET_REJEICAO_259',
	NF_STATUS_RET_REJEICAO_260 = 'NF_STATUS_RET_REJEICAO_260',
	NF_STATUS_RET_REJEICAO_261 = 'NF_STATUS_RET_REJEICAO_261',
	NF_STATUS_RET_REJEICAO_262 = 'NF_STATUS_RET_REJEICAO_262',
	NF_STATUS_RET_REJEICAO_263 = 'NF_STATUS_RET_REJEICAO_263',
	NF_STATUS_RET_REJEICAO_264 = 'NF_STATUS_RET_REJEICAO_264',
	NF_STATUS_RET_REJEICAO_265 = 'NF_STATUS_RET_REJEICAO_265',
	NF_STATUS_RET_REJEICAO_266 = 'NF_STATUS_RET_REJEICAO_266',
	NF_STATUS_RET_REJEICAO_267 = 'NF_STATUS_RET_REJEICAO_267',
	NF_STATUS_RET_REJEICAO_268 = 'NF_STATUS_RET_REJEICAO_268',
	NF_STATUS_RET_REJEICAO_269 = 'NF_STATUS_RET_REJEICAO_269',
	NF_STATUS_RET_REJEICAO_270 = 'NF_STATUS_RET_REJEICAO_270',
	NF_STATUS_RET_REJEICAO_271 = 'NF_STATUS_RET_REJEICAO_271',
	NF_STATUS_RET_REJEICAO_272 = 'NF_STATUS_RET_REJEICAO_272',
	NF_STATUS_RET_REJEICAO_273 = 'NF_STATUS_RET_REJEICAO_273',
	NF_STATUS_RET_REJEICAO_274 = 'NF_STATUS_RET_REJEICAO_274',
	NF_STATUS_RET_REJEICAO_275 = 'NF_STATUS_RET_REJEICAO_275',
	NF_STATUS_RET_REJEICAO_276 = 'NF_STATUS_RET_REJEICAO_276',
	NF_STATUS_RET_REJEICAO_277 = 'NF_STATUS_RET_REJEICAO_277',
	NF_STATUS_RET_REJEICAO_278 = 'NF_STATUS_RET_REJEICAO_278',
	NF_STATUS_RET_REJEICAO_279 = 'NF_STATUS_RET_REJEICAO_279',
	NF_STATUS_RET_REJEICAO_280 = 'NF_STATUS_RET_REJEICAO_280',
	NF_STATUS_RET_REJEICAO_281 = 'NF_STATUS_RET_REJEICAO_281',
	NF_STATUS_RET_REJEICAO_282 = 'NF_STATUS_RET_REJEICAO_282',
	NF_STATUS_RET_REJEICAO_283 = 'NF_STATUS_RET_REJEICAO_283',
	NF_STATUS_RET_REJEICAO_284 = 'NF_STATUS_RET_REJEICAO_284',
	NF_STATUS_RET_REJEICAO_285 = 'NF_STATUS_RET_REJEICAO_285',
	NF_STATUS_RET_REJEICAO_286 = 'NF_STATUS_RET_REJEICAO_286',
	NF_STATUS_RET_REJEICAO_287 = 'NF_STATUS_RET_REJEICAO_287',
	NF_STATUS_RET_REJEICAO_288 = 'NF_STATUS_RET_REJEICAO_288',
	NF_STATUS_RET_REJEICAO_289 = 'NF_STATUS_RET_REJEICAO_289',
	NF_STATUS_RET_REJEICAO_290 = 'NF_STATUS_RET_REJEICAO_290',
	NF_STATUS_RET_REJEICAO_291 = 'NF_STATUS_RET_REJEICAO_291',
	NF_STATUS_RET_REJEICAO_292 = 'NF_STATUS_RET_REJEICAO_292',
	NF_STATUS_RET_REJEICAO_293 = 'NF_STATUS_RET_REJEICAO_293',
	NF_STATUS_RET_REJEICAO_294 = 'NF_STATUS_RET_REJEICAO_294',
	NF_STATUS_RET_REJEICAO_295 = 'NF_STATUS_RET_REJEICAO_295',
	NF_STATUS_RET_REJEICAO_296 = 'NF_STATUS_RET_REJEICAO_296',
	NF_STATUS_RET_REJEICAO_297 = 'NF_STATUS_RET_REJEICAO_297',
	NF_STATUS_RET_REJEICAO_298 = 'NF_STATUS_RET_REJEICAO_298',
	NF_STATUS_RET_REJEICAO_299 = 'NF_STATUS_RET_REJEICAO_299',
	NF_STATUS_RET_REJEICAO_304 = 'NF_STATUS_RET_REJEICAO_304',
	NF_STATUS_RET_REJEICAO_321 = 'NF_STATUS_RET_REJEICAO_321',
	NF_STATUS_RET_REJEICAO_323 = 'NF_STATUS_RET_REJEICAO_323',
	NF_STATUS_RET_REJEICAO_324 = 'NF_STATUS_RET_REJEICAO_324',
	NF_STATUS_RET_REJEICAO_325 = 'NF_STATUS_RET_REJEICAO_325',
	NF_STATUS_RET_REJEICAO_326 = 'NF_STATUS_RET_REJEICAO_326',
	NF_STATUS_RET_REJEICAO_327 = 'NF_STATUS_RET_REJEICAO_327',
	NF_STATUS_RET_REJEICAO_328 = 'NF_STATUS_RET_REJEICAO_328',
	NF_STATUS_RET_REJEICAO_329 = 'NF_STATUS_RET_REJEICAO_329',
	NF_STATUS_RET_REJEICAO_330 = 'NF_STATUS_RET_REJEICAO_330',
	NF_STATUS_RET_REJEICAO_331 = 'NF_STATUS_RET_REJEICAO_331',
	NF_STATUS_RET_REJEICAO_332 = 'NF_STATUS_RET_REJEICAO_332',
	NF_STATUS_RET_REJEICAO_333 = 'NF_STATUS_RET_REJEICAO_333',
	NF_STATUS_RET_REJEICAO_334 = 'NF_STATUS_RET_REJEICAO_334',
	NF_STATUS_RET_REJEICAO_335 = 'NF_STATUS_RET_REJEICAO_335',
	NF_STATUS_RET_REJEICAO_336 = 'NF_STATUS_RET_REJEICAO_336',
	NF_STATUS_RET_REJEICAO_337 = 'NF_STATUS_RET_REJEICAO_337',
	NF_STATUS_RET_REJEICAO_338 = 'NF_STATUS_RET_REJEICAO_338',
	NF_STATUS_RET_REJEICAO_339 = 'NF_STATUS_RET_REJEICAO_339',
	NF_STATUS_RET_REJEICAO_340 = 'NF_STATUS_RET_REJEICAO_340',
	NF_STATUS_RET_REJEICAO_341 = 'NF_STATUS_RET_REJEICAO_341',
	NF_STATUS_RET_REJEICAO_342 = 'NF_STATUS_RET_REJEICAO_342',
	NF_STATUS_RET_REJEICAO_343 = 'NF_STATUS_RET_REJEICAO_343',
	NF_STATUS_RET_REJEICAO_344 = 'NF_STATUS_RET_REJEICAO_344',
	NF_STATUS_RET_REJEICAO_345 = 'NF_STATUS_RET_REJEICAO_345',
	NF_STATUS_RET_REJEICAO_346 = 'NF_STATUS_RET_REJEICAO_346',
	NF_STATUS_RET_REJEICAO_347 = 'NF_STATUS_RET_REJEICAO_347',
	NF_STATUS_RET_REJEICAO_348 = 'NF_STATUS_RET_REJEICAO_348',
	NF_STATUS_RET_REJEICAO_349 = 'NF_STATUS_RET_REJEICAO_349',
	NF_STATUS_RET_REJEICAO_350 = 'NF_STATUS_RET_REJEICAO_350',
	NF_STATUS_RET_REJEICAO_351 = 'NF_STATUS_RET_REJEICAO_351',
	NF_STATUS_RET_REJEICAO_352 = 'NF_STATUS_RET_REJEICAO_352',
	NF_STATUS_RET_REJEICAO_353 = 'NF_STATUS_RET_REJEICAO_353',
	NF_STATUS_RET_REJEICAO_354 = 'NF_STATUS_RET_REJEICAO_354',
	NF_STATUS_RET_REJEICAO_355 = 'NF_STATUS_RET_REJEICAO_355',
	NF_STATUS_RET_REJEICAO_356 = 'NF_STATUS_RET_REJEICAO_356',
	NF_STATUS_RET_REJEICAO_357 = 'NF_STATUS_RET_REJEICAO_357',
	NF_STATUS_RET_REJEICAO_358 = 'NF_STATUS_RET_REJEICAO_358',
	NF_STATUS_RET_REJEICAO_359 = 'NF_STATUS_RET_REJEICAO_359',
	NF_STATUS_RET_REJEICAO_360 = 'NF_STATUS_RET_REJEICAO_360',
	NF_STATUS_RET_REJEICAO_361 = 'NF_STATUS_RET_REJEICAO_361',
	NF_STATUS_RET_REJEICAO_362 = 'NF_STATUS_RET_REJEICAO_362',
	NF_STATUS_RET_REJEICAO_364 = 'NF_STATUS_RET_REJEICAO_364',
	NF_STATUS_RET_REJEICAO_365 = 'NF_STATUS_RET_REJEICAO_365',
	NF_STATUS_RET_REJEICAO_366 = 'NF_STATUS_RET_REJEICAO_366',
	NF_STATUS_RET_REJEICAO_367 = 'NF_STATUS_RET_REJEICAO_367',
	NF_STATUS_RET_REJEICAO_368 = 'NF_STATUS_RET_REJEICAO_368',
	NF_STATUS_RET_REJEICAO_369 = 'NF_STATUS_RET_REJEICAO_369',
	NF_STATUS_RET_REJEICAO_370 = 'NF_STATUS_RET_REJEICAO_370',
	NF_STATUS_RET_REJEICAO_401 = 'NF_STATUS_RET_REJEICAO_401',
	NF_STATUS_RET_REJEICAO_402 = 'NF_STATUS_RET_REJEICAO_402',
	NF_STATUS_RET_REJEICAO_403 = 'NF_STATUS_RET_REJEICAO_403',
	NF_STATUS_RET_REJEICAO_404 = 'NF_STATUS_RET_REJEICAO_404',
	NF_STATUS_RET_REJEICAO_405 = 'NF_STATUS_RET_REJEICAO_405',
	NF_STATUS_RET_REJEICAO_406 = 'NF_STATUS_RET_REJEICAO_406',
	NF_STATUS_RET_REJEICAO_407 = 'NF_STATUS_RET_REJEICAO_407',
	NF_STATUS_RET_REJEICAO_408 = 'NF_STATUS_RET_REJEICAO_408',
	NF_STATUS_RET_REJEICAO_409 = 'NF_STATUS_RET_REJEICAO_409',
	NF_STATUS_RET_REJEICAO_410 = 'NF_STATUS_RET_REJEICAO_410',
	NF_STATUS_RET_REJEICAO_411 = 'NF_STATUS_RET_REJEICAO_411',
	NF_STATUS_RET_REJEICAO_416 = 'NF_STATUS_RET_REJEICAO_416',
	NF_STATUS_RET_REJEICAO_417 = 'NF_STATUS_RET_REJEICAO_417',
	NF_STATUS_RET_REJEICAO_418 = 'NF_STATUS_RET_REJEICAO_418',
	NF_STATUS_RET_REJEICAO_420 = 'NF_STATUS_RET_REJEICAO_420',
	NF_STATUS_RET_REJEICAO_450 = 'NF_STATUS_RET_REJEICAO_450',
	NF_STATUS_RET_REJEICAO_451 = 'NF_STATUS_RET_REJEICAO_451',
	NF_STATUS_RET_REJEICAO_452 = 'NF_STATUS_RET_REJEICAO_452',
	NF_STATUS_RET_REJEICAO_453 = 'NF_STATUS_RET_REJEICAO_453',
	NF_STATUS_RET_REJEICAO_454 = 'NF_STATUS_RET_REJEICAO_454',
	NF_STATUS_RET_REJEICAO_455 = 'NF_STATUS_RET_REJEICAO_455',
	NF_STATUS_RET_REJEICAO_461 = 'NF_STATUS_RET_REJEICAO_461',
	NF_STATUS_RET_REJEICAO_465 = 'NF_STATUS_RET_REJEICAO_465',
	NF_STATUS_RET_REJEICAO_466 = 'NF_STATUS_RET_REJEICAO_466',
	NF_STATUS_RET_REJEICAO_467 = 'NF_STATUS_RET_REJEICAO_467',
	NF_STATUS_RET_REJEICAO_468 = 'NF_STATUS_RET_REJEICAO_468',
	NF_STATUS_RET_REJEICAO_471 = 'NF_STATUS_RET_REJEICAO_471',
	NF_STATUS_RET_REJEICAO_476 = 'NF_STATUS_RET_REJEICAO_476',
	NF_STATUS_RET_REJEICAO_477 = 'NF_STATUS_RET_REJEICAO_477',
	NF_STATUS_RET_REJEICAO_478 = 'NF_STATUS_RET_REJEICAO_478',
	NF_STATUS_RET_REJEICAO_484 = 'NF_STATUS_RET_REJEICAO_484',
	NF_STATUS_RET_REJEICAO_485 = 'NF_STATUS_RET_REJEICAO_485',
	NF_STATUS_RET_REJEICAO_489 = 'NF_STATUS_RET_REJEICAO_489',
	NF_STATUS_RET_REJEICAO_490 = 'NF_STATUS_RET_REJEICAO_490',
	NF_STATUS_RET_REJEICAO_491 = 'NF_STATUS_RET_REJEICAO_491',
	NF_STATUS_RET_REJEICAO_492 = 'NF_STATUS_RET_REJEICAO_492',
	NF_STATUS_RET_REJEICAO_493 = 'NF_STATUS_RET_REJEICAO_493',
	NF_STATUS_RET_REJEICAO_494 = 'NF_STATUS_RET_REJEICAO_494',
	NF_STATUS_RET_REJEICAO_501 = 'NF_STATUS_RET_REJEICAO_501',
	NF_STATUS_RET_REJEICAO_502 = 'NF_STATUS_RET_REJEICAO_502',
	NF_STATUS_RET_REJEICAO_503 = 'NF_STATUS_RET_REJEICAO_503',
	NF_STATUS_RET_REJEICAO_504 = 'NF_STATUS_RET_REJEICAO_504',
	NF_STATUS_RET_REJEICAO_505 = 'NF_STATUS_RET_REJEICAO_505',
	NF_STATUS_RET_REJEICAO_506 = 'NF_STATUS_RET_REJEICAO_506',
	NF_STATUS_RET_REJEICAO_507 = 'NF_STATUS_RET_REJEICAO_507',
	NF_STATUS_RET_REJEICAO_508 = 'NF_STATUS_RET_REJEICAO_508',
	NF_STATUS_RET_REJEICAO_509 = 'NF_STATUS_RET_REJEICAO_509',
	NF_STATUS_RET_REJEICAO_510 = 'NF_STATUS_RET_REJEICAO_510',
	NF_STATUS_RET_REJEICAO_511 = 'NF_STATUS_RET_REJEICAO_511',
	NF_STATUS_RET_REJEICAO_512 = 'NF_STATUS_RET_REJEICAO_512',
	NF_STATUS_RET_REJEICAO_513 = 'NF_STATUS_RET_REJEICAO_513',
	NF_STATUS_RET_REJEICAO_514 = 'NF_STATUS_RET_REJEICAO_514',
	NF_STATUS_RET_REJEICAO_515 = 'NF_STATUS_RET_REJEICAO_515',
	NF_STATUS_RET_REJEICAO_516 = 'NF_STATUS_RET_REJEICAO_516',
	NF_STATUS_RET_REJEICAO_517 = 'NF_STATUS_RET_REJEICAO_517',
	NF_STATUS_RET_REJEICAO_518 = 'NF_STATUS_RET_REJEICAO_518',
	NF_STATUS_RET_REJEICAO_519 = 'NF_STATUS_RET_REJEICAO_519',
	NF_STATUS_RET_REJEICAO_520 = 'NF_STATUS_RET_REJEICAO_520',
	NF_STATUS_RET_REJEICAO_521 = 'NF_STATUS_RET_REJEICAO_521',
	NF_STATUS_RET_REJEICAO_522 = 'NF_STATUS_RET_REJEICAO_522',
	NF_STATUS_RET_REJEICAO_523 = 'NF_STATUS_RET_REJEICAO_523',
	NF_STATUS_RET_REJEICAO_524 = 'NF_STATUS_RET_REJEICAO_524',
	NF_STATUS_RET_REJEICAO_525 = 'NF_STATUS_RET_REJEICAO_525',
	NF_STATUS_RET_REJEICAO_527 = 'NF_STATUS_RET_REJEICAO_527',
	NF_STATUS_RET_REJEICAO_528 = 'NF_STATUS_RET_REJEICAO_528',
	NF_STATUS_RET_REJEICAO_529 = 'NF_STATUS_RET_REJEICAO_529',
	NF_STATUS_RET_REJEICAO_530 = 'NF_STATUS_RET_REJEICAO_530',
	NF_STATUS_RET_REJEICAO_531 = 'NF_STATUS_RET_REJEICAO_531',
	NF_STATUS_RET_REJEICAO_532 = 'NF_STATUS_RET_REJEICAO_532',
	NF_STATUS_RET_REJEICAO_533 = 'NF_STATUS_RET_REJEICAO_533',
	NF_STATUS_RET_REJEICAO_534 = 'NF_STATUS_RET_REJEICAO_534',
	NF_STATUS_RET_REJEICAO_535 = 'NF_STATUS_RET_REJEICAO_535',
	NF_STATUS_RET_REJEICAO_536 = 'NF_STATUS_RET_REJEICAO_536',
	NF_STATUS_RET_REJEICAO_537 = 'NF_STATUS_RET_REJEICAO_537',
	NF_STATUS_RET_REJEICAO_538 = 'NF_STATUS_RET_REJEICAO_538',
	NF_STATUS_RET_REJEICAO_539 = 'NF_STATUS_RET_REJEICAO_539',
	NF_STATUS_RET_REJEICAO_540 = 'NF_STATUS_RET_REJEICAO_540',
	NF_STATUS_RET_REJEICAO_541 = 'NF_STATUS_RET_REJEICAO_541',
	NF_STATUS_RET_REJEICAO_542 = 'NF_STATUS_RET_REJEICAO_542',
	NF_STATUS_RET_REJEICAO_543 = 'NF_STATUS_RET_REJEICAO_543',
	NF_STATUS_RET_REJEICAO_544 = 'NF_STATUS_RET_REJEICAO_544',
	NF_STATUS_RET_REJEICAO_545 = 'NF_STATUS_RET_REJEICAO_545',
	NF_STATUS_RET_REJEICAO_546 = 'NF_STATUS_RET_REJEICAO_546',
	NF_STATUS_RET_REJEICAO_547 = 'NF_STATUS_RET_REJEICAO_547',
	NF_STATUS_RET_REJEICAO_548 = 'NF_STATUS_RET_REJEICAO_548',
	NF_STATUS_RET_REJEICAO_549 = 'NF_STATUS_RET_REJEICAO_549',
	NF_STATUS_RET_REJEICAO_550 = 'NF_STATUS_RET_REJEICAO_550',
	NF_STATUS_RET_REJEICAO_551 = 'NF_STATUS_RET_REJEICAO_551',
	NF_STATUS_RET_REJEICAO_552 = 'NF_STATUS_RET_REJEICAO_552',
	NF_STATUS_RET_REJEICAO_553 = 'NF_STATUS_RET_REJEICAO_553',
	NF_STATUS_RET_REJEICAO_554 = 'NF_STATUS_RET_REJEICAO_554',
	NF_STATUS_RET_REJEICAO_555 = 'NF_STATUS_RET_REJEICAO_555',
	NF_STATUS_RET_REJEICAO_556 = 'NF_STATUS_RET_REJEICAO_556',
	NF_STATUS_RET_REJEICAO_557 = 'NF_STATUS_RET_REJEICAO_557',
	NF_STATUS_RET_REJEICAO_558 = 'NF_STATUS_RET_REJEICAO_558',
	NF_STATUS_RET_REJEICAO_559 = 'NF_STATUS_RET_REJEICAO_559',
	NF_STATUS_RET_REJEICAO_560 = 'NF_STATUS_RET_REJEICAO_560',
	NF_STATUS_RET_REJEICAO_561 = 'NF_STATUS_RET_REJEICAO_561',
	NF_STATUS_RET_REJEICAO_562 = 'NF_STATUS_RET_REJEICAO_562',
	NF_STATUS_RET_REJEICAO_563 = 'NF_STATUS_RET_REJEICAO_563',
	NF_STATUS_RET_REJEICAO_564 = 'NF_STATUS_RET_REJEICAO_564',
	NF_STATUS_RET_REJEICAO_565 = 'NF_STATUS_RET_REJEICAO_565',
	NF_STATUS_RET_REJEICAO_567 = 'NF_STATUS_RET_REJEICAO_567',
	NF_STATUS_RET_REJEICAO_568 = 'NF_STATUS_RET_REJEICAO_568',
	NF_STATUS_RET_REJEICAO_569 = 'NF_STATUS_RET_REJEICAO_569',
	NF_STATUS_RET_REJEICAO_570 = 'NF_STATUS_RET_REJEICAO_570',
	NF_STATUS_RET_REJEICAO_571 = 'NF_STATUS_RET_REJEICAO_571',
	NF_STATUS_RET_REJEICAO_572 = 'NF_STATUS_RET_REJEICAO_572',
	NF_STATUS_RET_REJEICAO_573 = 'NF_STATUS_RET_REJEICAO_573',
	NF_STATUS_RET_REJEICAO_574 = 'NF_STATUS_RET_REJEICAO_574',
	NF_STATUS_RET_REJEICAO_575 = 'NF_STATUS_RET_REJEICAO_575',
	NF_STATUS_RET_REJEICAO_576 = 'NF_STATUS_RET_REJEICAO_576',
	NF_STATUS_RET_REJEICAO_577 = 'NF_STATUS_RET_REJEICAO_577',
	NF_STATUS_RET_REJEICAO_578 = 'NF_STATUS_RET_REJEICAO_578',
	NF_STATUS_RET_REJEICAO_579 = 'NF_STATUS_RET_REJEICAO_579',
	NF_STATUS_RET_REJEICAO_580 = 'NF_STATUS_RET_REJEICAO_580',
	NF_STATUS_RET_REJEICAO_587 = 'NF_STATUS_RET_REJEICAO_587',
	NF_STATUS_RET_REJEICAO_588 = 'NF_STATUS_RET_REJEICAO_588',
	NF_STATUS_RET_REJEICAO_589 = 'NF_STATUS_RET_REJEICAO_589',
	NF_STATUS_RET_REJEICAO_590 = 'NF_STATUS_RET_REJEICAO_590',
	NF_STATUS_RET_REJEICAO_591 = 'NF_STATUS_RET_REJEICAO_591',
	NF_STATUS_RET_REJEICAO_592 = 'NF_STATUS_RET_REJEICAO_592',
	NF_STATUS_RET_REJEICAO_593 = 'NF_STATUS_RET_REJEICAO_593',
	NF_STATUS_RET_REJEICAO_594 = 'NF_STATUS_RET_REJEICAO_594',
	NF_STATUS_RET_REJEICAO_595 = 'NF_STATUS_RET_REJEICAO_595',
	NF_STATUS_RET_REJEICAO_596 = 'NF_STATUS_RET_REJEICAO_596',
	NF_STATUS_RET_REJEICAO_597 = 'NF_STATUS_RET_REJEICAO_597',
	NF_STATUS_RET_REJEICAO_598 = 'NF_STATUS_RET_REJEICAO_598',
	NF_STATUS_RET_REJEICAO_599 = 'NF_STATUS_RET_REJEICAO_599',
	NF_STATUS_RET_REJEICAO_601 = 'NF_STATUS_RET_REJEICAO_601',
	NF_STATUS_RET_REJEICAO_602 = 'NF_STATUS_RET_REJEICAO_602',
	NF_STATUS_RET_REJEICAO_603 = 'NF_STATUS_RET_REJEICAO_603',
	NF_STATUS_RET_REJEICAO_604 = 'NF_STATUS_RET_REJEICAO_604',
	NF_STATUS_RET_REJEICAO_605 = 'NF_STATUS_RET_REJEICAO_605',
	NF_STATUS_RET_REJEICAO_606 = 'NF_STATUS_RET_REJEICAO_606',
	NF_STATUS_RET_REJEICAO_607 = 'NF_STATUS_RET_REJEICAO_607',
	NF_STATUS_RET_REJEICAO_608 = 'NF_STATUS_RET_REJEICAO_608',
	NF_STATUS_RET_REJEICAO_609 = 'NF_STATUS_RET_REJEICAO_609',
	NF_STATUS_RET_REJEICAO_610 = 'NF_STATUS_RET_REJEICAO_610',
	NF_STATUS_RET_REJEICAO_611 = 'NF_STATUS_RET_REJEICAO_611',
	NF_STATUS_RET_REJEICAO_612 = 'NF_STATUS_RET_REJEICAO_612',
	NF_STATUS_RET_REJEICAO_613 = 'NF_STATUS_RET_REJEICAO_613',
	NF_STATUS_RET_REJEICAO_614 = 'NF_STATUS_RET_REJEICAO_614',
	NF_STATUS_RET_REJEICAO_615 = 'NF_STATUS_RET_REJEICAO_615',
	NF_STATUS_RET_REJEICAO_616 = 'NF_STATUS_RET_REJEICAO_616',
	NF_STATUS_RET_REJEICAO_617 = 'NF_STATUS_RET_REJEICAO_617',
	NF_STATUS_RET_REJEICAO_618 = 'NF_STATUS_RET_REJEICAO_618',
	NF_STATUS_RET_REJEICAO_619 = 'NF_STATUS_RET_REJEICAO_619',
	NF_STATUS_RET_REJEICAO_620 = 'NF_STATUS_RET_REJEICAO_620',
	NF_STATUS_RET_REJEICAO_621 = 'NF_STATUS_RET_REJEICAO_621',
	NF_STATUS_RET_REJEICAO_622 = 'NF_STATUS_RET_REJEICAO_622',
	NF_STATUS_RET_REJEICAO_623 = 'NF_STATUS_RET_REJEICAO_623',
	NF_STATUS_RET_REJEICAO_624 = 'NF_STATUS_RET_REJEICAO_624',
	NF_STATUS_RET_REJEICAO_625 = 'NF_STATUS_RET_REJEICAO_625',
	NF_STATUS_RET_REJEICAO_626 = 'NF_STATUS_RET_REJEICAO_626',
	NF_STATUS_RET_REJEICAO_627 = 'NF_STATUS_RET_REJEICAO_627',
	NF_STATUS_RET_REJEICAO_628 = 'NF_STATUS_RET_REJEICAO_628',
	NF_STATUS_RET_REJEICAO_629 = 'NF_STATUS_RET_REJEICAO_629',
	NF_STATUS_RET_REJEICAO_630 = 'NF_STATUS_RET_REJEICAO_630',
	NF_STATUS_RET_REJEICAO_631 = 'NF_STATUS_RET_REJEICAO_631',
	NF_STATUS_RET_REJEICAO_632 = 'NF_STATUS_RET_REJEICAO_632',
	NF_STATUS_RET_REJEICAO_633 = 'NF_STATUS_RET_REJEICAO_633',
	NF_STATUS_RET_REJEICAO_634 = 'NF_STATUS_RET_REJEICAO_634',
	NF_STATUS_RET_REJEICAO_635 = 'NF_STATUS_RET_REJEICAO_635',
	NF_STATUS_RET_REJEICAO_650 = 'NF_STATUS_RET_REJEICAO_650',
	NF_STATUS_RET_REJEICAO_651 = 'NF_STATUS_RET_REJEICAO_651',
	NF_STATUS_RET_REJEICAO_653 = 'NF_STATUS_RET_REJEICAO_653',
	NF_STATUS_RET_REJEICAO_654 = 'NF_STATUS_RET_REJEICAO_654',
	NF_STATUS_RET_REJEICAO_655 = 'NF_STATUS_RET_REJEICAO_655',
	NF_STATUS_RET_REJEICAO_656 = 'NF_STATUS_RET_REJEICAO_656',
	NF_STATUS_RET_REJEICAO_657 = 'NF_STATUS_RET_REJEICAO_657',
	NF_STATUS_RET_REJEICAO_658 = 'NF_STATUS_RET_REJEICAO_658',
	NF_STATUS_RET_REJEICAO_660 = 'NF_STATUS_RET_REJEICAO_660',
	NF_STATUS_RET_REJEICAO_661 = 'NF_STATUS_RET_REJEICAO_661',
	NF_STATUS_RET_REJEICAO_662 = 'NF_STATUS_RET_REJEICAO_662',
	NF_STATUS_RET_REJEICAO_663 = 'NF_STATUS_RET_REJEICAO_663',
	NF_STATUS_RET_REJEICAO_678 = 'NF_STATUS_RET_REJEICAO_678',
	NF_STATUS_RET_REJEICAO_679 = 'NF_STATUS_RET_REJEICAO_679',
	NF_STATUS_RET_REJEICAO_680 = 'NF_STATUS_RET_REJEICAO_680',
	NF_STATUS_RET_REJEICAO_681 = 'NF_STATUS_RET_REJEICAO_681',
	NF_STATUS_RET_REJEICAO_682 = 'NF_STATUS_RET_REJEICAO_682',
	NF_STATUS_RET_REJEICAO_683 = 'NF_STATUS_RET_REJEICAO_683',
	NF_STATUS_RET_REJEICAO_684 = 'NF_STATUS_RET_REJEICAO_684',
	NF_STATUS_RET_REJEICAO_685 = 'NF_STATUS_RET_REJEICAO_685',
	NF_STATUS_RET_REJEICAO_686 = 'NF_STATUS_RET_REJEICAO_686',
	NF_STATUS_RET_REJEICAO_687 = 'NF_STATUS_RET_REJEICAO_687',
	NF_STATUS_RET_REJEICAO_688 = 'NF_STATUS_RET_REJEICAO_688',
	NF_STATUS_RET_REJEICAO_689 = 'NF_STATUS_RET_REJEICAO_689',
	NF_STATUS_RET_REJEICAO_690 = 'NF_STATUS_RET_REJEICAO_690',
	NF_STATUS_RET_REJEICAO_691 = 'NF_STATUS_RET_REJEICAO_691',
	NF_STATUS_RET_REJEICAO_700 = 'NF_STATUS_RET_REJEICAO_700',
	NF_STATUS_RET_REJEICAO_701 = 'NF_STATUS_RET_REJEICAO_701',
	NF_STATUS_RET_REJEICAO_702 = 'NF_STATUS_RET_REJEICAO_702',
	NF_STATUS_RET_REJEICAO_703 = 'NF_STATUS_RET_REJEICAO_703',
	NF_STATUS_RET_REJEICAO_704 = 'NF_STATUS_RET_REJEICAO_704',
	NF_STATUS_RET_REJEICAO_705 = 'NF_STATUS_RET_REJEICAO_705',
	NF_STATUS_RET_REJEICAO_706 = 'NF_STATUS_RET_REJEICAO_706',
	NF_STATUS_RET_REJEICAO_707 = 'NF_STATUS_RET_REJEICAO_707',
	NF_STATUS_RET_REJEICAO_708 = 'NF_STATUS_RET_REJEICAO_708',
	NF_STATUS_RET_REJEICAO_709 = 'NF_STATUS_RET_REJEICAO_709',
	NF_STATUS_RET_REJEICAO_710 = 'NF_STATUS_RET_REJEICAO_710',
	NF_STATUS_RET_REJEICAO_711 = 'NF_STATUS_RET_REJEICAO_711',
	NF_STATUS_RET_REJEICAO_712 = 'NF_STATUS_RET_REJEICAO_712',
	NF_STATUS_RET_REJEICAO_713 = 'NF_STATUS_RET_REJEICAO_713',
	NF_STATUS_RET_REJEICAO_714 = 'NF_STATUS_RET_REJEICAO_714',
	NF_STATUS_RET_REJEICAO_715 = 'NF_STATUS_RET_REJEICAO_715',
	NF_STATUS_RET_REJEICAO_716 = 'NF_STATUS_RET_REJEICAO_716',
	NF_STATUS_RET_REJEICAO_717 = 'NF_STATUS_RET_REJEICAO_717',
	NF_STATUS_RET_REJEICAO_718 = 'NF_STATUS_RET_REJEICAO_718',
	NF_STATUS_RET_REJEICAO_719 = 'NF_STATUS_RET_REJEICAO_719',
	NF_STATUS_RET_REJEICAO_720 = 'NF_STATUS_RET_REJEICAO_720',
	NF_STATUS_RET_REJEICAO_721 = 'NF_STATUS_RET_REJEICAO_721',
	NF_STATUS_RET_REJEICAO_723 = 'NF_STATUS_RET_REJEICAO_723',
	NF_STATUS_RET_REJEICAO_724 = 'NF_STATUS_RET_REJEICAO_724',
	NF_STATUS_RET_REJEICAO_725 = 'NF_STATUS_RET_REJEICAO_725',
	NF_STATUS_RET_REJEICAO_726 = 'NF_STATUS_RET_REJEICAO_726',
	NF_STATUS_RET_REJEICAO_727 = 'NF_STATUS_RET_REJEICAO_727',
	NF_STATUS_RET_REJEICAO_728 = 'NF_STATUS_RET_REJEICAO_728',
	NF_STATUS_RET_REJEICAO_729 = 'NF_STATUS_RET_REJEICAO_729',
	NF_STATUS_RET_REJEICAO_730 = 'NF_STATUS_RET_REJEICAO_730',
	NF_STATUS_RET_REJEICAO_731 = 'NF_STATUS_RET_REJEICAO_731',
	NF_STATUS_RET_REJEICAO_732 = 'NF_STATUS_RET_REJEICAO_732',
	NF_STATUS_RET_REJEICAO_733 = 'NF_STATUS_RET_REJEICAO_733',
	NF_STATUS_RET_REJEICAO_734 = 'NF_STATUS_RET_REJEICAO_734',
	NF_STATUS_RET_REJEICAO_735 = 'NF_STATUS_RET_REJEICAO_735',
	NF_STATUS_RET_REJEICAO_736 = 'NF_STATUS_RET_REJEICAO_736',
	NF_STATUS_RET_REJEICAO_737 = 'NF_STATUS_RET_REJEICAO_737',
	NF_STATUS_RET_REJEICAO_738 = 'NF_STATUS_RET_REJEICAO_738',
	NF_STATUS_RET_REJEICAO_739 = 'NF_STATUS_RET_REJEICAO_739',
	NF_STATUS_RET_REJEICAO_740 = 'NF_STATUS_RET_REJEICAO_740',
	NF_STATUS_RET_REJEICAO_741 = 'NF_STATUS_RET_REJEICAO_741',
	NF_STATUS_RET_REJEICAO_742 = 'NF_STATUS_RET_REJEICAO_742',
	NF_STATUS_RET_REJEICAO_743 = 'NF_STATUS_RET_REJEICAO_743',
	NF_STATUS_RET_REJEICAO_745 = 'NF_STATUS_RET_REJEICAO_745',
	NF_STATUS_RET_REJEICAO_746 = 'NF_STATUS_RET_REJEICAO_746',
	NF_STATUS_RET_REJEICAO_748 = 'NF_STATUS_RET_REJEICAO_748',
	NF_STATUS_RET_REJEICAO_749 = 'NF_STATUS_RET_REJEICAO_749',
	NF_STATUS_RET_REJEICAO_750 = 'NF_STATUS_RET_REJEICAO_750',
	NF_STATUS_RET_REJEICAO_751 = 'NF_STATUS_RET_REJEICAO_751',
	NF_STATUS_RET_REJEICAO_752 = 'NF_STATUS_RET_REJEICAO_752',
	NF_STATUS_RET_REJEICAO_753 = 'NF_STATUS_RET_REJEICAO_753',
	NF_STATUS_RET_REJEICAO_754 = 'NF_STATUS_RET_REJEICAO_754',
	NF_STATUS_RET_REJEICAO_755 = 'NF_STATUS_RET_REJEICAO_755',
	NF_STATUS_RET_REJEICAO_756 = 'NF_STATUS_RET_REJEICAO_756',
	NF_STATUS_RET_REJEICAO_757 = 'NF_STATUS_RET_REJEICAO_757',
	NF_STATUS_RET_REJEICAO_758 = 'NF_STATUS_RET_REJEICAO_758',
	NF_STATUS_RET_REJEICAO_759 = 'NF_STATUS_RET_REJEICAO_759',
	NF_STATUS_RET_REJEICAO_760 = 'NF_STATUS_RET_REJEICAO_760',
	NF_STATUS_RET_REJEICAO_762 = 'NF_STATUS_RET_REJEICAO_762',
	NF_STATUS_RET_REJEICAO_763 = 'NF_STATUS_RET_REJEICAO_763',
	NF_STATUS_RET_REJEICAO_764 = 'NF_STATUS_RET_REJEICAO_764',
	NF_STATUS_RET_REJEICAO_765 = 'NF_STATUS_RET_REJEICAO_765',
	NF_STATUS_RET_REJEICAO_766 = 'NF_STATUS_RET_REJEICAO_766',
	NF_STATUS_RET_REJEICAO_767 = 'NF_STATUS_RET_REJEICAO_767',
	NF_STATUS_RET_REJEICAO_768 = 'NF_STATUS_RET_REJEICAO_768',
	NF_STATUS_RET_REJEICAO_769 = 'NF_STATUS_RET_REJEICAO_769',
	NF_STATUS_RET_REJEICAO_770 = 'NF_STATUS_RET_REJEICAO_770',
	NF_STATUS_RET_REJEICAO_771 = 'NF_STATUS_RET_REJEICAO_771',
	NF_STATUS_RET_REJEICAO_772 = 'NF_STATUS_RET_REJEICAO_772',
	NF_STATUS_RET_REJEICAO_773 = 'NF_STATUS_RET_REJEICAO_773',
	NF_STATUS_RET_REJEICAO_774 = 'NF_STATUS_RET_REJEICAO_774',
	NF_STATUS_RET_REJEICAO_775 = 'NF_STATUS_RET_REJEICAO_775',
	NF_STATUS_RET_REJEICAO_776 = 'NF_STATUS_RET_REJEICAO_776',
	NF_STATUS_RET_REJEICAO_777 = 'NF_STATUS_RET_REJEICAO_777',
	NF_STATUS_RET_REJEICAO_778 = 'NF_STATUS_RET_REJEICAO_778',
	NF_STATUS_RET_REJEICAO_779 = 'NF_STATUS_RET_REJEICAO_779',
	NF_STATUS_RET_REJEICAO_780 = 'NF_STATUS_RET_REJEICAO_780',
	NF_STATUS_RET_REJEICAO_781 = 'NF_STATUS_RET_REJEICAO_781',
	NF_STATUS_RET_REJEICAO_782 = 'NF_STATUS_RET_REJEICAO_782',
	NF_STATUS_RET_REJEICAO_783 = 'NF_STATUS_RET_REJEICAO_783',
	NF_STATUS_RET_REJEICAO_784 = 'NF_STATUS_RET_REJEICAO_784',
	NF_STATUS_RET_REJEICAO_785 = 'NF_STATUS_RET_REJEICAO_785',
	NF_STATUS_RET_REJEICAO_786 = 'NF_STATUS_RET_REJEICAO_786',
	NF_STATUS_RET_REJEICAO_787 = 'NF_STATUS_RET_REJEICAO_787',
	NF_STATUS_RET_REJEICAO_788 = 'NF_STATUS_RET_REJEICAO_788',
	NF_STATUS_RET_REJEICAO_789 = 'NF_STATUS_RET_REJEICAO_789',
	NF_STATUS_RET_REJEICAO_790 = 'NF_STATUS_RET_REJEICAO_790',
	NF_STATUS_RET_REJEICAO_791 = 'NF_STATUS_RET_REJEICAO_791',
	NF_STATUS_RET_REJEICAO_792 = 'NF_STATUS_RET_REJEICAO_792',
	NF_STATUS_RET_REJEICAO_793 = 'NF_STATUS_RET_REJEICAO_793',
	NF_STATUS_RET_REJEICAO_794 = 'NF_STATUS_RET_REJEICAO_794',
	NF_STATUS_RET_REJEICAO_795 = 'NF_STATUS_RET_REJEICAO_795',
	NF_STATUS_RET_REJEICAO_796 = 'NF_STATUS_RET_REJEICAO_796',
	NF_STATUS_RET_REJEICAO_999 = 'NF_STATUS_RET_REJEICAO_999'
}

export enum TipoModeloEtiquetaEnum {
	ETIQUETA_1_X_1_89mmX26mm_GONDOLA = 'ETIQUETA_1_X_1_89mmX26mm_GONDOLA ',
	ETIQUETA_2_X_1_40mmX24mm = 'ETIQUETA_2_X_1_40mmX24mm',
	ETIQUETA_2_X_1_50mmX25mm = 'ETIQUETA_2_X_1_50mmX25mm',
	ETIQUETA_2_X_1_68mmX45mm_CONFECCAO = 'ETIQUETA_2_X_1_68mmX45mm_CONFECCAO',
	ETIQUETA_2_X_1_68mmX45mm_CONFECCAO_LOGO = 'ETIQUETA_2_X_1_68mmX45mm_CONFECCAO_LOGO',
	ETIQUETA_3_X_1_28mmX19mm = 'ETIQUETA_3_X_1_28mmX19mm',
	ETIQUETA_3_X_1_33mmX22mm_HORIZONTAL = 'ETIQUETA_3_X_1_33mmX22mm_HORIZONTAL',
	ETIQUETA_3_X_1_33mmX22mm_HORIZONTAL_SEM_ESPACO = 'ETIQUETA_3_X_1_33mmX22mm_HORIZONTAL_SEM_ESPACO',
	ETIQUETA_3_X_1_33mmX22mm_HORIZONTAL_SIMPLES_SEM_ESPACO = 'ETIQUETA_3_X_1_33mmX22mm_HORIZONTAL_SIMPLES_SEM_ESPACO',
	ETIQUETA_3_X_1_60mmX35mm_CONFECCAO = 'ETIQUETA_3_X_1_60mmX35mm_CONFECCAO',
	ETIQUETA_3_X_1_60mmX35mm_CONFECCAO_COM_LOGO = 'ETIQUETA_3_X_1_60mmX35mm_CONFECCAO_COM_LOGO',
	ETIQUETA_3_X_1_33mmX22mm_HORIZONTAL_NOME_EMPRESA = 'ETIQUETA_3_X_1_33mmX22mm_HORIZONTAL_NOME_EMPRESA',
	ETIQUETA_5_X_13 = 'ETIQUETA_5_X_13',
	ETIQUETA_5_X_13_38mmX21mm = 'ETIQUETA_5_X_13_38mmX21mm',
	ETIQUETA_GONDULA_100mmX32mm = 'ETIQUETA_GONDULA_100mmX32mm',
	ETIQUETA_GONDULA_75mmX35mm = 'ETIQUETA_GONDULA_75mmX35mm',
	ETIQUETA_GONDULA_PADRAO = 'ETIQUETA_GONDULA_PADRAO',
	ETIQUETA_GONDULA_DESCONTO_QUANTIDADE = 'ETIQUETA_GONDULA_DESCONTO_QUANTIDADE',
	ETIQUETA_GONDULA_SENHA_DINAMICA = 'ETIQUETA_GONDULA_SENHA_DINAMICA',
	ETIQUETA_TAREFA_EQUIPAMENTO = 'ETIQUETA_TAREFA_EQUIPAMENTO',
	ETIQUETA_TAREFA_ITEM = 'ETIQUETA_TAREFA_ITEM',
	ETIQUETA_JM1 = 'ETIQUETA_JM1',
	ETIQUETA_JM2 = 'ETIQUETA_JM2',
	ETIQUETA_JM3 = 'ETIQUETA_JM3',
	ETIQUETA_3_X_10_JATO_DE_TINTA = 'ETIQUETA_3_X_10_JATO_DE_TINTA',
	ETIQUETA_3_X_11_JATO_DE_TINTA = 'ETIQUETA_3_X_11_JATO_DE_TINTA'
}

export enum TipoObservacaoEnum {
	OBSERVACAO_MOTIVO_PAGAMENTO = 'OBSERVACAO_MOTIVO_PAGAMENTO',
	OBSERVACAO_PRODUTOS = 'OBSERVACAO_PRODUTOS',
	OBSERVACAO_MOTIVO_CANCELAMENTO_NFCE = 'OBSERVACAO_MOTIVO_CANCELAMENTO_NFCE',
	OBSERVACAO_TITULO_TAREFA = 'OBSERVACAO_TITULO_TAREFA'
}

export enum TipoOrigemMovimentacaoCreditoEnum {
	CREDITO_ANTECIPACAO_VALOR = 'CREDITO_ANTECIPACAO_VALOR',
	CREDITO_TROCO_VALE_COMPRA = 'CREDITO_TROCO_VALE_COMPRA',
	CREDITO_DEVOLUCAO_ITEM = 'CREDITO_DEVOLUCAO_ITEM',
	CREDITO_CANCELAMENTO_VENDA = 'CREDITO_CANCELAMENTO_VENDA',
	CREDITO_CORRECAO_MANUAL_PAGAMENTO = 'CREDITO_CORRECAO_MANUAL_PAGAMENTO',
	DEBITO_CORRECAO_MANUAL_PAGAMENTO = 'DEBITO_CORRECAO_MANUAL_PAGAMENTO',
	DEBITO_VENDA = 'DEBITO_VENDA'
}

export enum TipoPessoaPedidoEnum {
	PESSOA_PEDIDO_CLIENTE = 'PESSOA_PEDIDO_CLIENTE',
	PESSOA_PEDIDO_VENDEDOR = 'PESSOA_PEDIDO_VENDEDOR',
	PESSOA_PEDIDO_TECNICO = 'PESSOA_PEDIDO_TECNICO'
}

export enum TipoVendaEstoqueNegativoEnum {
	MOSTRAR_AVISO_ESTOQUE = 'MOSTRAR_AVISO_ESTOQUE',
	MOSTRAR_AVISO_ESTOQUE_SENHA = 'MOSTRAR_AVISO_ESTOQUE_SENHA',
	NAO_MOSTRAR_AVISO_ESTOQUE = 'NAO_MOSTRAR_AVISO_ESTOQUE'
}
