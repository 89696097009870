import { PessoaTabelaVendaInput } from '../inputs/PessoaTabelaVendaInput';
import { Pessoa } from './Pessoa';
import { TabelaVenda } from './TabelaVenda';

export class PessoaTabelaVenda {
	pessoa!: Pessoa;

	tabelaVenda!: TabelaVenda;

	padrao!: boolean;

	createdAt!: string;

	updatedAt!: string;
}

export function pessoaTabelaVendaToInput(pessoaTabelaVenda: PessoaTabelaVenda): PessoaTabelaVendaInput {
	const converter: any = { ...pessoaTabelaVenda };

	delete converter.pessoa;
	delete converter.tabelaVenda;

	const input: PessoaTabelaVendaInput = { ...converter };

	if (pessoaTabelaVenda.pessoa) input.pessoaId = pessoaTabelaVenda.pessoa.id;
	if (pessoaTabelaVenda.tabelaVenda) input.tabelaVendaId = pessoaTabelaVenda.tabelaVenda.id;

	return input;
}
