import { FrenteLoja } from 'src/modules/entities/FrenteLoja';
// import { TabelaVenda } from 'src/modules/entities/TabelaVenda';
import { Tipo } from 'src/modules/entities/Tipo';
import { Operacao } from 'src/modules/entities/Operacao';
import { OperadorProps, ThemeProps } from '../types/StateTypes';
import { MASTER_ID } from 'src/util/ConstantesUtil';
import { GrupoEmbalagemRetornavel } from 'src/modules/entities/GrupoEmbalagemRetornavel';
import { TabelaVenda } from 'app/src-electron/api/modules/entities/TabelaVenda';
import { Pessoa } from 'src/modules/entities/Pessoa';
import { Impressora } from 'src/modules/entities/Impressora';
import { Estoque } from '../../modules/entities/Estoque';

export interface StateInterfaceInicial {
	operador: OperadorProps;
	operacao: Operacao | null;
	operacoes: Operacao[] | null;
	operacaoCodigo: number | null;
	fullScreen: boolean;
	identificacao: string | null;
	codigoUltimoPedido: number | null;
	trocoUltimoPedido: number | null;
	cliente: string | null;
	vendedor: any;
	pdv: string | null;
	tabela?: TabelaVenda;
	tabelas: TabelaVenda[];
	masterId: string;
	modoConsulta: boolean;
	grupoEmbalagemRetornavel: GrupoEmbalagemRetornavel | null;
	itemCodigoEmbalagensConsultadas: any;
	mensagemFinalizar: string | null;
	editandoLinha: boolean;
	primeiroItemVenda: boolean;
	operacaoAberta: boolean;
	tipos: Tipo;
	frenteLoja: FrenteLoja;
	showBuscaPessoa: boolean;
	dark: boolean;
	servico: boolean;
	permiteAlterarValorUn: boolean;
	porcentagemMaxAlterarValUn: number;
	vendaMedicamento: boolean;
	token: string;
	theme: ThemeProps;
	cnpjTef: string | null;
	corBorda: string;
	modo: string;
	quantidadeItemFixa: boolean;
	isFiltroBuscaItemCodigo: boolean;
	sistemaAtualizado: boolean;
	urlApiGraphql: string;
	urlImpressao: string;
	solicitarVendedor: boolean;
	solicitarVendedorESenha: boolean;
	imprimirRecibo: boolean;
	quantidadeVias: number;
	quantidadeViasPadrao: number;
	impressora: Impressora | null;
	estoquePrincipal: Estoque | null;
}
function state(): StateInterfaceInicial {
	const pessoa = new Pessoa();
	const frenteLoja = new FrenteLoja();
	return {
		operador: {
			codigo: 0,
			pessoa: {
				...pessoa,
				nome: 'Suporte'
			},
			token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwZXNzb2EiOnsiaWQiOiJlNjlhMDViZC1kMmE0LTQ1NzctYjdkZC0zN2VjZDJkNWIxZDUiLCJub21lIjoiTUFTVEVSIEpDTEFOIn0sImNvZGlnbyI6MCwidXN1YXJpb0VtcHJlc2FMb2dhZG8iOnsiZW1wcmVzYSI6eyJwZXNzb2EiOnsiaWQiOiIxOGViMDdmMS0xYjJjLTQ5ZTEtODg2MC0xOWYzNmNkNTFkMDgiLCJub21lIjoiSkNMQU4gREVTRU5WT0xWSU1FTlRPIERFIFNPRlRXQVJFIExUREEifX19LCJpYXQiOjE2NDUxMDI5MzAsImV4cCI6MTY0NzY5NDkzMH0.OIFwy1lTAnzmCuSGwzzG8x7469ou58QXp_RHIUBQE9c'
		},
		token: '',
		urlApiGraphql: '',
		urlImpressao: '',
		corBorda: 'corAzul',
		operacao: null,
		operacoes: null,
		operacaoCodigo: null,
		editandoLinha: false,
		fullScreen: false,
		modoConsulta: false,
		mensagemFinalizar: null,
		grupoEmbalagemRetornavel: null,
		estoquePrincipal: null,
		itemCodigoEmbalagensConsultadas: {},
		codigoUltimoPedido: null,
		trocoUltimoPedido: null,
		imprimirRecibo: true,
		cnpjTef: null,
		quantidadeVias: 1,
		quantidadeViasPadrao: 1,
		primeiroItemVenda: true,
		operacaoAberta: true,
		sistemaAtualizado: true,
		vendaMedicamento: false,
		pdv: null,
		tabela: {},
		tabelas: [],
		cliente: null,
		vendedor: null,
		identificacao: 'Não identificado',
		masterId: MASTER_ID,
		tipos: {
			id: 'tipos',
			sigla: '',
			grupo: '',
			nome: '',
			labelTipo: ''
		},
		frenteLoja,
		showBuscaPessoa: false,

		dark: true,
		servico: false,
		permiteAlterarValorUn: false,
		porcentagemMaxAlterarValUn: 2,
		theme: {
			dark: true,
			darkIntensidade: 8,
			primary: '#027be3',
			secondary: '#26a69a',
			negative: '#f44336',
			positive: '#21ba45',
			warning: '#f2c037',
			accent: '#9c27b0',
			info: '#31ccec'
		},
		modo: 'venda',
		quantidadeItemFixa: false,
		isFiltroBuscaItemCodigo: true,
		solicitarVendedor: false,
		solicitarVendedorESenha: false,
		impressora: null
	};
}

export default state;
