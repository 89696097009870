import { PessoaFormaPagamentoParcelaInput } from 'src/modules/inputs/PessoaFormaPagamentoParcelaInput';
import { FormaPagamento } from './FormaPagamento';
import { FormaPagamentoParcela } from './FormaPagamentoParcela';
import { Pessoa } from './Pessoa';

export interface PessoaFormaPagamentoParcela {
	id: string;

	formaPagamento: FormaPagamento;

	formaPagamentoParcela: FormaPagamentoParcela;

	pessoa: Pessoa;

	createdAt: string;

	updatedAt: string;

	excluido: boolean;
}

export function pessoaFormaPagamentoParcelaToInput(
	pessoaFormaPagamentoParcela: PessoaFormaPagamentoParcela
): PessoaFormaPagamentoParcelaInput {
	const converter: any = { ...pessoaFormaPagamentoParcela };

	delete converter.formaPagamento;
	delete converter.formaPagamentoParcela;
	delete converter.pessoa;

	const input: PessoaFormaPagamentoParcelaInput = { ...converter };

	if (pessoaFormaPagamentoParcela.formaPagamento?.id) input.formaPagamentoId = pessoaFormaPagamentoParcela.formaPagamento.id;
	if (pessoaFormaPagamentoParcela.formaPagamentoParcela?.id) {
		input.formaPagamentoId = pessoaFormaPagamentoParcela.formaPagamentoParcela.id;
	}
	if (pessoaFormaPagamentoParcela.pessoa?.id) input.formaPagamentoId = pessoaFormaPagamentoParcela.pessoa.id;

	return input;
}
