import AppService from 'src/services/AppService';
import Storage from '../util/LocalStorageUtil';

export default class InitCtrl {
	$vue = null;
	$store = null;
	$router = null;
	appService = null;
	storage = new Storage();

	constructor(vue) {
		this.$vue = vue;
		this.$store = vue.$store;
		this.$router = vue.$router;
		this.appService = new AppService(vue);
	}

	async init() {
		const api = await this.storage.get('GRAPHQL_API_URL');
		const terminalId = await this.storage.get('terminalId');
		const empresaId = await this.storage.get('empresaId');

		const token = await this.storage.get('token');
		await this.$store.dispatch('geral/setToken', token);

		const solicitarVendedor = await this.storage.get('solicitarVendedor');
		await this.$store.dispatch('geral/setSolicitarVendedor', solicitarVendedor);

		const solicitarVendedorESenha = await this.storage.get('solicitarVendedorESenha');
		await this.$store.dispatch('geral/setSolicitarVendedorESenha', solicitarVendedorESenha);

		const primeiroItemVenda = await this.storage.get('primeiroItemVenda');
		if (primeiroItemVenda === undefined || primeiroItemVenda === null) {
			await this.storage.set('primeiroItemVenda', true);
			await this.$store.dispatch('geral/changePrimeiroItemVenda', true);
		} else {
			await this.$store.dispatch('geral/changePrimeiroItemVenda', primeiroItemVenda);
		}

		const modoConsulta = await this.storage.get('modoConsulta');
		if (modoConsulta === undefined || modoConsulta === null) {
			const operacao = await this.$vue.$getOperacao();
			if (operacao) {
				await this.$vue.$setModoConsulta(false);
				await this.$store.dispatch('geral/changeModoConsulta', false);
			} else {
				await this.$vue.$setModoConsulta(true);
				await this.$store.dispatch('geral/changeModoConsulta', true);
			}
		} else {
			await this.$store.dispatch('geral/changeModoConsulta', modoConsulta);
		}

		const quantidadeItemFixa = await this.storage.get('quantidadeItemFixa');
		if (quantidadeItemFixa === undefined || quantidadeItemFixa === null) {
			await this.storage.set('quantidadeItemFixa', false);
			await this.$store.dispatch('geral/changeQuantidadeItemFixa', false);
		} else {
			await this.$store.dispatch('geral/changeQuantidadeItemFixa', quantidadeItemFixa);
		}

		const isFiltroBuscaItemCodigo = await this.storage.get('isFiltroBuscaItemCodigo');
		if (isFiltroBuscaItemCodigo === undefined || isFiltroBuscaItemCodigo === null) {
			await this.storage.set('isFiltroBuscaItemCodigo', true);
			await this.$store.dispatch('geral/changeIsFiltroBuscaItemCodigo', true);
		} else {
			await this.$store.dispatch('geral/changeIsFiltroBuscaItemCodigo', isFiltroBuscaItemCodigo);
		}

		return { api, terminalId, empresaId };
	}

	async startAppService() {
		try {
			await this.appService.init();
			if (process.env.MODE === 'electron') {
				if (process.env.NODE_ENV === 'production') {
					window.windowAPI.fullScreen();
					await this.$vue.$setFullScreen(true);
					await this.$store.dispatch('geral/changeFullscreen', true);
				}
			}
		} catch (e) {
			console.error(e);
			throw new Error('Erro ao iniciar o AppService');
		}
	}
}
