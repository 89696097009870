import { TipoOrigemCadastroEnum, TipoPessoaEnum } from '../enum/TipoEnum';
import { PessoaInput } from '../inputs/PessoaInput';
import { Empresa } from './Empresa';
import { GrupoEmpresarial } from './GrupoEmpresarial';
import { GrupoPessoa } from './GrupoPessoa';
import { PessoaConta, pessoaContaToInput } from './PessoaConta';
import { PessoaEndereco, pessoaEnderecoToInput } from './PessoaEndereco';
import { PessoaEnderecoEletronico, pessoaEnderecoEletronicoToInput } from './PessoaEnderecoEletronico';
import { PessoaFisica, pessoaFisicaToInput } from './PessoaFisica';
import { PessoaIndicacao, pessoaIndicacaoToInput } from './PessoaIndicacao';
import { PessoaJuridica, pessoaJuridicaToInput } from './PessoaJuridica';
import { PessoaLimiteCredito, pessoaLimiteCreditoToInput } from './PessoaLimiteCredito';
import { PessoaObservacao, pessoaObservacaoToInput } from './PessoaObservacao';
import { PessoaReferencia, pessoaReferenciaToInput } from './PessoaReferencia';
import { PessoaRestricao, pessoaRestricaoToInput } from './PessoaRestricao';
import { PessoaTabelaVenda, pessoaTabelaVendaToInput } from './PessoaTabelaVenda';
import { PessoaTelefone, pessoaTelefoneToInput } from './PessoaTelefone';
import { PessoaVinculo, pessoaVinculoToInput } from './PessoaVinculo';
import { Tipo } from './Tipo';
import { Usuario, usuarioToInput } from './Usuario';

export class Pessoa {
	id: string;

	codigo: number;

	empresa: Empresa;

	grupoEmpresarial: GrupoEmpresarial;

	grupoPessoa: GrupoPessoa;

	nome: string;

	nomeAlternativo: string;

	tipoPessoa: Tipo;

	dataCadastro: string;

	pessoaFisica: PessoaFisica;

	pessoaJuridica: PessoaJuridica;

	pessoaEnderecos: PessoaEndereco[];

	pessoaTelefonePrincipal: PessoaTelefone;

	pessoaTelefones: PessoaTelefone[];

	pessoaObservacoes: PessoaObservacao[];

	pessoaContas: PessoaConta[];

	pessoaEnderecoEletronicos: PessoaEnderecoEletronico[];

	pessoaVinculos: PessoaVinculo[];

	pessoaTabelaVendas: PessoaTabelaVenda[];

	pessoaLimiteCreditos: PessoaLimiteCredito[];

	pessoaReferencias: PessoaReferencia[];

	pessoaRestricoes: PessoaRestricao[];

	pessoaComissao: PessoaIndicacao;

	usuario: Usuario;

	avatar: string;

	avatarUrl: string;

	createdAt: string;

	updatedAt: string;

	tipoOrigemCadastro: Tipo;

	labelCodigoPessoa: string;
}

export function pessoaToInput(pessoa: Pessoa): PessoaInput {
	const converter: any = { ...pessoa };

	delete converter.grupoEmpresarial;
	delete converter.grupoPessoa;
	delete converter.pessoaComissao;
	delete converter.usuario;
	delete converter.pessoaFisica;
	delete converter.pessoaJuridica;

	const input: PessoaInput = { ...converter };

	if (pessoa.grupoEmpresarial) input.grupoEmpresarialId = pessoa.grupoEmpresarial.id;
	if (pessoa.grupoPessoa) input.grupoPessoaId = pessoa.grupoPessoa.id;
	if (pessoa.tipoPessoa) input.tipoPessoaId = pessoa.tipoPessoa.id as TipoPessoaEnum;
	if (pessoa.tipoOrigemCadastro) input.tipoOrigemCadastroId = pessoa.tipoOrigemCadastro.id as TipoOrigemCadastroEnum;

	input.pessoaTelefones = [];
	input.pessoaObservacoes = [];
	input.pessoaEnderecoEletronicos = [];
	input.pessoaEnderecos = [];
	input.pessoaContas = [];
	input.pessoaVinculos = [];
	input.pessoaTabelaVendas = [];
	input.pessoaLimiteCreditos = [];
	input.pessoaReferencias = [];
	input.pessoaRestricoes = [];

	if (pessoa.pessoaComissao) {
		input.pessoaComissao = pessoaIndicacaoToInput(pessoa.pessoaComissao);
	}

	if (pessoa.usuario) {
		input.usuario = usuarioToInput(pessoa.usuario);
	}

	if (pessoa.pessoaFisica) {
		input.pessoaFisica = pessoaFisicaToInput(pessoa.pessoaFisica);
	}

	if (pessoa.pessoaJuridica) {
		input.pessoaJuridica = pessoaJuridicaToInput(pessoa.pessoaJuridica);
	}

	if (pessoa.pessoaTelefones && pessoa.pessoaTelefones.length > 0) {
		for (let index = 0; index < pessoa.pessoaTelefones.length; index++) {
			const pessoaTelefone = pessoa.pessoaTelefones[index];
			const pessoaTelefoneInput = pessoaTelefoneToInput(pessoaTelefone);
			input.pessoaTelefones.push(pessoaTelefoneInput);
		}
	}
	if (pessoa.pessoaObservacoes && pessoa.pessoaObservacoes.length > 0) {
		for (let index = 0; index < pessoa.pessoaObservacoes.length; index++) {
			const pessoaObservacao = pessoa.pessoaObservacoes[index];
			const pessoaObservacaoInput = pessoaObservacaoToInput(pessoaObservacao);
			input.pessoaObservacoes.push(pessoaObservacaoInput);
		}
	}
	if (pessoa.pessoaEnderecoEletronicos && pessoa.pessoaEnderecoEletronicos.length > 0) {
		for (let index = 0; index < pessoa.pessoaEnderecoEletronicos.length; index++) {
			const pessoaEnderecoEletronico = pessoa.pessoaEnderecoEletronicos[index];
			const pessoaEnderecoEletronicoInput = pessoaEnderecoEletronicoToInput(pessoaEnderecoEletronico);
			input.pessoaEnderecoEletronicos.push(pessoaEnderecoEletronicoInput);
		}
	}
	if (pessoa.pessoaEnderecos && pessoa.pessoaEnderecos.length > 0) {
		for (let index = 0; index < pessoa.pessoaEnderecos.length; index++) {
			const pessoaEndereco = pessoa.pessoaEnderecos[index];
			const pessoaEnderecoInput = pessoaEnderecoToInput(pessoaEndereco);
			input.pessoaEnderecos.push(pessoaEnderecoInput);
		}
	}
	if (pessoa.pessoaContas && pessoa.pessoaContas.length > 0) {
		for (let index = 0; index < pessoa.pessoaContas.length; index++) {
			const pessoaConta = pessoa.pessoaContas[index];
			const pessoaContaInput = pessoaContaToInput(pessoaConta);
			input.pessoaContas.push(pessoaContaInput);
		}
	}
	if (pessoa.pessoaVinculos && pessoa.pessoaVinculos.length > 0) {
		for (let index = 0; index < pessoa.pessoaVinculos.length; index++) {
			const pessoaVinculo = pessoa.pessoaVinculos[index];
			const pessoaVinculoInput = pessoaVinculoToInput(pessoaVinculo);
			input.pessoaVinculos.push(pessoaVinculoInput);
		}
	}
	if (pessoa.pessoaTabelaVendas && pessoa.pessoaTabelaVendas.length > 0) {
		for (let index = 0; index < pessoa.pessoaTabelaVendas.length; index++) {
			const pessoaTabelaVenda = pessoa.pessoaTabelaVendas[index];
			const pessoaTabelaVendaInput = pessoaTabelaVendaToInput(pessoaTabelaVenda);
			input.pessoaTabelaVendas.push(pessoaTabelaVendaInput);
		}
	}
	if (pessoa.pessoaLimiteCreditos && pessoa.pessoaLimiteCreditos.length > 0) {
		for (let index = 0; index < pessoa.pessoaLimiteCreditos.length; index++) {
			const pessoaLimiteCredito = pessoa.pessoaLimiteCreditos[index];
			const pessoaLimiteCreditoInput = pessoaLimiteCreditoToInput(pessoaLimiteCredito);
			input.pessoaLimiteCreditos.push(pessoaLimiteCreditoInput);
		}
	}
	if (pessoa.pessoaReferencias && pessoa.pessoaReferencias.length > 0) {
		for (let index = 0; index < pessoa.pessoaReferencias.length; index++) {
			const pessoaReferencia = pessoa.pessoaReferencias[index];
			const pessoaReferenciaInput = pessoaReferenciaToInput(pessoaReferencia);
			input.pessoaReferencias.push(pessoaReferenciaInput);
		}
	}
	if (pessoa.pessoaRestricoes && pessoa.pessoaRestricoes.length > 0) {
		for (let index = 0; index < pessoa.pessoaRestricoes.length; index++) {
			const pessoaRestricao = pessoa.pessoaRestricoes[index];
			const pessoaRestricaoInput = pessoaRestricaoToInput(pessoaRestricao);
			input.pessoaRestricoes.push(pessoaRestricaoInput);
		}
	}

	return input;
}
