<template>
	<div v-if="iniciar">
		<router-view />
	</div>
</template>

<script>
import AppService from './services/AppService';
import InitCtrl from 'src/controllers/InitCtrl';
import ConfiguracaoInicialService from './services/ConfiguracaoInicialService';

export default {
	name: 'App',
	data() {
		return {
			initCtrl: new InitCtrl(this),
			appService: new AppService(this),
			empresaConfiguracao: new ConfiguracaoInicialService(),
			iniciar: false
		};
	},
	async beforeMount() {
		const { api, terminalId, empresaId } = await this.initCtrl.init();
		if (api && terminalId && empresaId) {
			try {
				this.$q.loading.show({ message: 'Carregando Frente Loja...' });
				await this.initCtrl.startAppService();
				this.iniciar = true;
			} catch (e) {
				console.error(e);
				this.iniciar = true;
				this.$router.push({ path: '/', replace: true });
			} finally {
				this.$q.loading.hide();
			}
		} else {
			this.iniciar = true;
			this.$router.push({ path: '/', replace: true });
		}
	}
};
</script>
