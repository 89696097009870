import { Teclado } from 'src/modules/entities/Teclado';
import configuracoesTeclado from '../configuracoes/teclas/configuracoesTeclado';
import QRCode from 'qrcode';
import { formatarDate } from './DataUtil';
import Storage from 'src/util/LocalStorageUtil';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { TipoNotaFiscalPagamentoBandeiraEnum } from 'src/modules/enum/TipoEnum';
import { v4 as uuidV4 } from 'uuid';

export function floatToStringCasasDecimais(valor: number, casasDecimais = 3) {
	const valorConvertido = new Intl.NumberFormat('pt-BR', {
		currency: 'BRL',
		signDisplay: 'auto',
		minimumFractionDigits: casasDecimais,
		maximumFractionDigits: casasDecimais
	}).format(valor);

	return valorConvertido;
}

export function tratarCaracteresDB(text: string): string {
	if (text) {
		text = text.replace(/'/g, "''");
		return text;
	}
	return '';
}

export function formatarDinheiro(valor: number, mostraMoeda = true) {
	if (!valor) {
		valor = 0;
	}
	let valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
	if (!mostraMoeda) {
		valorFormatado = removerSiglaDinheiro(valorFormatado);
	}
	if (valor === 0) {
		valorFormatado = (mostraMoeda ? 'R$ ' : '') + '0,00';
	}
	return `${valorFormatado}`;
}

export function getCodigoTef() {
	return new Date().valueOf().toString();
}

export function preencherCodigoEan(codigo: number): string {
	let codigoEan = String(codigo);
	if (codigoEan.length < 13) {
		codigoEan = codigoEan.padStart(13, '0');
	}
	return codigoEan;
}

export function arredondarDecimal(valor: number, decimal = 2): number {
	return Number(valor.toFixed(decimal));
}

export function parseObject(obj: any) {
	return JSON.parse(JSON.stringify(obj));
}

export function mascaraTelefone(texto: string) {
	if (texto) {
		const ddd = texto.substring(0, 2);
		let telefone = texto.substring(2, texto.length);
		if (telefone.length > 8) {
			telefone = `${telefone.substring(0, 5)}-${telefone.substring(5, telefone.length)}`;
		} else {
			telefone = `${telefone.substring(0, 4)}-${telefone.substring(4, telefone.length)}`;
		}
		return `(${ddd}) ${telefone}`;
	}
	return '(00) 00000000';
}

export async function gerarQrCode(texto: string, width: number) {
	try {
		return await QRCode.toDataURL(texto, {
			width: width
		});
	} catch (err: any) {
		throw new Error(err);
	}
}

export function possuiLetra(texto: string): boolean {
	return /[a-z]/i.test(texto);
}

export function getSomenteNumeros(valor: string): string {
	// @ts-ignore
	return `${valor}`.match(/[0-9]+/g).join([]);
}
export function getSomenteNumero(valor: string): number {
	// @ts-ignore
	return Number(`${valor}`.match(/[0-9]+/g).join([]));
}
export function getBandeiraCartaoCredito(numero: any) {
	const blocos = {
		uatp: [4, 5, 6],
		amex: [4, 6, 5],
		diners: [4, 6, 4],
		discover: [4, 4, 4, 4],
		mastercard: [4, 4, 4, 4],
		dankort: [4, 4, 4, 4],
		instapayment: [4, 4, 4, 4],
		jcb15: [4, 6, 5],
		jcb: [4, 4, 4, 4],
		maestro: [4, 4, 4, 4],
		visa: [4, 4, 4, 4],
		mir: [4, 4, 4, 4],
		unionPay: [4, 4, 4, 4],
		general: [4, 4, 4, 4]
	};
	const regexs = {
		// comeca com 1; 15 , not comeca com 1800 (jcb card)
		uatp: /^(?!1800)1\d{0,14}/,
		// comeca com 34/37; 15
		amex: /^3[47]\d{0,13}/,
		// comeca com 6011/65/644-649; 16
		discover: /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/,
		// comeca com 300-305/309 or 36/38/39; 14
		diners: /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/,
		// comeca com 51-55/2221–2720; 16
		mastercard: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,
		// comeca com 5019/4175/4571; 16
		dankort: /^(5019|4175|4571)\d{0,12}/,
		// comeca com 637-639; 16
		instapayment: /^63[7-9]\d{0,13}/,
		// comeca com 2131/1800; 15
		jcb15: /^(?:2131|1800)\d{0,11}/,
		// comeca com 2131/1800/35; 16
		jcb: /^(?:35\d{0,2})\d{0,12}/,
		// comeca com 50/56-58/6304/67; 16
		maestro: /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/,
		// comeca com 22; 16
		mir: /^220[0-4]\d{0,12}/,
		// comeca com 4; 16
		visa: /^4\d{0,15}/,
		// comeca com 62/81; 16
		unionPay: /^(62|81)\d{0,14}/
	};

	for (const regex in regexs) {
		// @ts-ignore
		if (regexs[regex].test(numero)) {
			return {
				bandeira: regex,
				// @ts-ignore
				layout: blocos[regex]
			};
		}
	}

	return {
		bandeira: 'nao-encontrada',
		layout: blocos.general
	};
}

export function formatarCpfCnpj(valor: string): string | void {
	if (valor) {
		if (possuiLetra(valor)) {
			return valor;
		} else {
			if (valor.length === 14) {
				valor = valor.replace(/^(\d{12})(\d)/, '$1-$2');
				valor = valor.replace(/^(\d{8})(\d)/, '$1/$2');
				valor = valor.replace(/^(\d{5})(\d)/, '$1.$2');
				valor = valor.replace(/^(\d{2})(\d)/, '$1.$2');
			} else {
				valor = valor.replace(/^(\d{9})(\d)/, '$1-$2');
				valor = valor.replace(/^(\d{6})(\d)/, '$1.$2');
				valor = valor.replace(/^(\d{3})(\d)/, '$1.$2');
			}
			return valor;
		}
	}
}

export function formataDinheiro(valor: number, mostraMoeda = true) {
	let valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
	if (!mostraMoeda) {
		valorFormatado = valorFormatado.substring(3, valorFormatado.length);
	}
	return `${valorFormatado}`;
}

export function formataValor(valor: { toLocaleString: (arg0: string, arg1: { style: string; currency: string }) => any }) {
	let valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
	valorFormatado = `${valorFormatado.replace(',', '*').replace('.', ',').replace('*', '.')}`;
	valorFormatado = valorFormatado.substring(3, valorFormatado.length);
	return `${valorFormatado}`;
}

export function getQuantidadeFormatada(qtd: number) {
	return Number(qtd).toFixed(3).replace('.', ',');
}
export function isEmptyObject(obj: any): boolean {
	return Object.keys(obj).length === 0 && obj.constructor === Object;
}
export function truncarValor(value: number, n: number) {
	const v = value.toFixed(6).toString().split('.');
	if (n <= 0) return v[0];
	let f = v[1] || '';
	if (f.length > n) return parseFloat(`${v[0]}.${f.substring(0, n)}`);
	while (f.length < n) f += '0';
	return parseFloat(`${v[0]}.${f}`);
}

export function isInteger(valor: any) {
	return Number.isInteger(Number(valor));
}

export function isFloat(n: number) {
	return n === +n && n !== (n | 0);
}

export function getStringToNumber(valorString: string) {
	let valorRetorno = removerSiglaDinheiro(valorString);
	if (typeof valorRetorno === 'string' && valorRetorno.includes(',')) {
		valorRetorno = valorRetorno.replace(',', '*').replace('.', '').replace('*', '.');
	}
	return Number(valorRetorno);
}

export function getTipoBandeiraNotaFiscalByTef(tipoId: string) {
	if (!tipoId) return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_OUTROS;
	if (
		tipoId === 'BANDEIRA_CARTAO_CREDITO_VISA' ||
		tipoId === 'BANDEIRA_CARTAO_DEBITO_VISA_ELECTRON' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_VISA_VALE'
	) {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_VISA;
	} else if (tipoId === 'BANDEIRA_CARTAO_CREDITO_MASTERCARD' || tipoId === 'BANDEIRA_CARTAO_DEBITO_MAESTRO') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_MASTERCARD;
	} else if (tipoId === 'BANDEIRA_CARTAO_CREDITO_AMEX') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_AMERICAN_EXPRESS;
	} else if (
		tipoId === 'BANDEIRA_CARTAO_DEBITO_SOROCRED' ||
		tipoId === 'BANDEIRA_CARTAO_CREDITO_SOROCRED' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_SOROCRED'
	) {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_SOROCRED;
	} else if (tipoId === 'BANDEIRA_CARTAO_CREDITO_DINERS') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_DINERS_CLUB;
	} else if (
		tipoId === 'BANDEIRA_CARTAO_CREDITO_ELO' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_ELO' ||
		tipoId === 'BANDEIRA_CARTAO_DEBITO_ELO_13' ||
		tipoId === 'BANDEIRA_CARTAO_DEBITO_ELO_32' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_ELO_COMBUSTIVEL'
	) {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_ELO;
	} else if (tipoId === 'BANDEIRA_CARTAO_DEBITO_HIPERCAD' || tipoId === 'BANDEIRA_CARTAO_CREDITO_HIPERCARD') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_HIPERCARD;
	} else if (tipoId === 'BANDEIRA_CARTAO_CREDITO_AURA') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_AURA;
	} else if (
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_ALELO_ALIMENTA' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_ALELO' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_ALELO_CULTURA' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_ALELO_REFEICAO'
	) {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_ALELO;
	} else if (tipoId === 'BANDEIRA_CARTAO_CREDITO_BANESCARD') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_BANES_CARD;
	} else if (
		tipoId === 'BANDEIRA_CARTAO_CREDITO_CABAL' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_CABAL' ||
		tipoId === 'BANDEIRA_CARTAO_DEBITO_CABAL_03' ||
		tipoId === 'BANDEIRA_CARTAO_DEBITO_CABAL_12'
	) {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_CABAL;
	} else if (tipoId === 'BANDEIRA_CARTAO_CREDITO_CREDZ') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_CREDZ;
	} else if (tipoId === 'BANDEIRA_CARTAO_VOUCHER_DISCOVERY') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_DISCOVER;
	} else if (tipoId === 'BANDEIRA_CARTAO_VOUCHER_GOODCARD') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_GOODCARD;
	} else if (tipoId === 'BANDEIRA_CARTAO_VOUCHER_GREENCARD' || tipoId === 'BANDEIRA_CARTAO_VOUCHER_GREEN_CARD_ALIME') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_GREENCARD;
	} else if (tipoId === 'BANDEIRA_CARTAO_CREDITO_JCB') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_JCB;
	} else if (
		tipoId === 'BANDEIRA_CARTAO_DEBITO_POLICARD' ||
		tipoId === 'BANDEIRA_CARTAO_CREDITO_POLICARD' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_POLICARD'
	) {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_POLICARD;
	} else if (
		tipoId === 'BANDEIRA_CARTAO_GIFT_SODEXO' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_SODEXO' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_SODEXO_ALIMENTA' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_SODEXO_COMB' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_SODEXO_CULTURA' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_SODEXO_PREMIUM' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_SODEXO_REFEICAO'
	) {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_SODEXO;
	} else if (tipoId === 'BANDEIRA_CARTAO_VOUCHER_VALECARD' || tipoId === 'BANDEIRA_CARTAO_VOUCHER_VALECARD_CULTURA') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_VALECARD;
	} else if (tipoId === 'BANDEIRA_CARTAO_VOUCHER_VEROCHEQUE') {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_VEROCHEQUE;
	} else if (
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_VR_ALIMENTACAO' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_VR_BENEFICIO' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_VR_COMBUSTIVEL' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_VR_CULTURA' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_VR_REFEICAO'
	) {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_VR;
	} else if (
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_TICKET' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_TICKET_ALIMENTA' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_TICKET_PARCEIRO' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_TICKET_REFEICAO' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_TICKET_COMB' ||
		tipoId === 'BANDEIRA_CARTAO_VOUCHER_TICKET_CULTURA'
	) {
		return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_TICKET;
	}

	return TipoNotaFiscalPagamentoBandeiraEnum.NF_PAGAMENTO_BANDEIRA_OUTROS;
}

export function formatLogs(valor: string) {
	const logs = valor
		.replace(/error/g, '##')
		.replace(/info/g, '##')
		.split('##')
		// @ts-ignore
		.join('[', ']', '##')
		.replace(/ \[\[\]/g, '##')
		.replace(/\r\n/g, '##')
		.replace(/\n/g, '')
		.replace(/\s+/g, ' ')
		.split('##');
	let stringTratada = '';
	const styleTextArea =
		'style="width: 100%; height: 18.75rem; outline: none; background: #121212; color: white; padding: 1rem; font-size: 14px"';
	const styleP = 'style="width: auto;	word-wrap: break-word; margin: 0; background: #121212; color: white"';
	for (let index = 0; index < logs.length; index++) {
		const log = logs[index];
		let validJson = undefined;
		try {
			validJson = JSON.stringify(JSON.parse(log), null, 2);
		} catch (_) {}
		if (validJson) {
			if (validJson.length > 2) {
				stringTratada += `<textarea readonly ${styleTextArea}>${validJson}</textarea>`;
			} else {
				stringTratada += `<p ${styleP}>${log}</p>`;
			}
		} else {
			stringTratada += `<p ${styleP}>${log}</p>`;
		}
	}
	return stringTratada;
}

export function removeRepeatedSpaces(str: string) {
	return str.replace(/\s+/g, ' ').trim();
}

export function getValorTipado(valor: string) {
	if (valor && valor.length > 0) {
		return JSON.parse(valor);
	}
}

export function getValorData(valor: string) {
	if (valor && valor.length > 0) {
		const data = new Date(valor);
		return formatarDate(data);
	}
}

export function getValorTruncado(valor: number, casasDecimais: number) {
	let numPower = Math.pow(10, casasDecimais);
	return ~~(valor * numPower) / numPower;
}

export function contarDecimais(valor: number, usarDecimal = false) {
	// valor deve ser sempre number
	if (valor) {
		if (Math.floor(valor) === valor) {
			return usarDecimal ? 1 : 0;
		} else {
			if (valor.toString().split('.')[1].length > 0) {
				return valor.toString().split('.')[1].length;
			}
			return usarDecimal ? 1 : 0;
		}
	} else {
		return usarDecimal ? 1 : 0;
	}
}

export function getConfiguracaoTeclas(frenteLoja: { terminal: { teclado: Teclado } | null } | null) {
	const configuracoesTeclas = configuracoesTeclado;
	if (frenteLoja && frenteLoja != null && frenteLoja.terminal && frenteLoja.terminal != null) {
		configuracoesTeclas.ENTER = 'Enter';
		configuracoesTeclas.ESC = 'Escape';
		configuracoesTeclas.BACKSPACE = 'Backspace';

		configuracoesTeclas.CONSULTA_ITEM = 'KeyA';
		configuracoesTeclas.BALANCA_CAPTURA = 'KeyB';
		configuracoesTeclas.CPF_CNPJ = 'KeyC';
		configuracoesTeclas.DESCONTO_GERAL = 'KeyD';
		configuracoesTeclas.EDITAR = 'KeyE';
		configuracoesTeclas.TECLA_FUNCAO = 'KeyF';
		configuracoesTeclas.ABRE_GAVETA = 'KeyG';
		configuracoesTeclas.VENDEDOR = 'KeyH';
		configuracoesTeclas.BUSCA_ITEM = 'KeyI';
		//J
		configuracoesTeclas.REPETE_ITEM = 'KeyK';
		//L
		configuracoesTeclas.TECLA_MENU = 'KeyM';
		configuracoesTeclas.NOVO_PEDIDO = 'KeyN';
		configuracoesTeclas.OBSERVACAO_PEDIDO = 'KeyO';
		configuracoesTeclas.BUSCA_CLIENTE = 'KeyP';
		configuracoesTeclas.TROCA_TABELA_VENDA = 'KeyQ';
		configuracoesTeclas.RECEBIMENTO = 'KeyR';
		configuracoesTeclas.CANCELA_ITEM = 'KeyS';
		configuracoesTeclas.TROCA_BUSCA_CODIGO_REFERENCIA = 'KeyT';
		configuracoesTeclas.EM_CADASTRO = 'KeyU';
		configuracoesTeclas.ENTRADA_VALOR = 'KeyV';
		//W
		configuracoesTeclas.ALTERA_QUANTIDADE = 'KeyX';
		//Y
		//Z

		configuracoesTeclas.SETA_ESQUERDA = 'ArrowLeft';
		configuracoesTeclas.SETA_CIMA = 'ArrowUp';
		configuracoesTeclas.SETA_DIREITA = 'ArrowRight';
		configuracoesTeclas.SETA_BAIXO = 'ArrowDown';

		configuracoesTeclas.DINHEIRO = 'F1';
		configuracoesTeclas.DEBITO = 'F2';
		configuracoesTeclas.CREDITO = 'F3';
		configuracoesTeclas.BOLETO = 'F4';
		configuracoesTeclas.CARTEIRA_DIGITAL = 'F5';
		configuracoesTeclas.ALIMENTACAO = 'F6';
		configuracoesTeclas.REFEICAO = 'F7';
		configuracoesTeclas.OPERACAO_BANCARIA = 'F8';
		configuracoesTeclas.VALE_COMPRA = 'F9';
		configuracoesTeclas.CONVENIO = 'F10';
		configuracoesTeclas.CORTESIA = 'F11';
		configuracoesTeclas.CHEQUE = 'F12';

		configuracoesTeclas.FILTRO_AVANCADO = false;
		configuracoesTeclas.PESQUISA = '';
		configuracoesTeclas.TECLA_RECARGA = '';
		configuracoesTeclas.FIDELIDADE = '';
	}
	return configuracoesTeclas;
}

export function getTeclaNome(code: string) {
	if (!code || code == null) {
		return undefined;
	}

	switch (code) {
		case 'Backspace':
			return 'BACKSPACE';
		case 'Tab':
			return 'TAB';
		case 'Enter':
			return 'ENTER';
		case 'ShiftLeft':
			return 'SHIFT';
		case 'ShiftRight':
			return 'SHIFT';
		case 'ControlLeft':
			return 'CTRL';
		case 'AltLeft':
			return 'ALT';
		case 'CapsLock':
			return 'CAPS LOCK';
		case 'Escape':
			return 'ESCAPE';
		case 'Space':
			return '(SPACE)';
		case 'PageUp':
			return 'PAGE UP';
		case 'PageDown':
			return 'PAGE DOWN';
		case 'End':
			return 'END';
		case 'Home':
			return 'HOME';
		case 'ArrowLeft':
			return 'LEFT ARROW';
		case 'ArrowUp':
			return 'UP ARROW';
		case 'ArrowRight':
			return 'RIGHT ARROW';
		case 'ArrowDown':
			return 'DOWN ARROW';
		case 'Insert':
			return 'INSERT';
		case 'Delete':
			return 'DELETE';
		case 'Digit0':
			return '0';
		case 'Digit1':
			return '1';
		case 'Digit2':
			return '2';
		case 'Digit3':
			return '3';
		case 'Digit4':
			return '4';
		case 'Digit5':
			return '5';
		case 'Digit6':
			return '6';
		case 'Digit7':
			return '7';
		case 'Digit8':
			return '8';
		case 'Digit9':
			return '9';
		case 'KeyA':
			return 'CTRL + A';
		case 'KeyB':
			return 'CTRL + B';
		case 'KeyC':
			return 'CTRL + C';
		case 'KeyD':
			return 'D';
		case 'KeyE':
			return 'CTRL + E';
		case 'KeyF':
			return 'CTRL + F';
		case 'KeyG':
			return 'CTRL + G';
		case 'KeyH':
			return 'CTRL + H';
		case 'KeyI':
			return 'CTRL + I';
		case 'KeyJ':
			return 'CTRL + J';
		case 'KeyK':
			return 'CTRL + K';
		case 'KeyL':
			return 'CTRL + L';
		case 'KeyM':
			return 'CTRL + M';
		case 'KeyN':
			return 'CTRL + N';
		case 'KeyO':
			return 'CTRL + O';
		case 'KeyP':
			return 'CTRL + P';
		case 'KeyQ':
			return 'CTRL + Q';
		case 'KeyR':
			return 'CTRL + R';
		case 'KeyS':
			return 'CTRL + S';
		case 'KeyT':
			return 'CTRL + T';
		case 'KeyU':
			return 'CTRL + U';
		case 'KeyV':
			return 'CTRL + V';
		case 'KeyW':
			return 'CTRL + W';
		case 'KeyX':
			return 'CTRL + X';
		case 'KeyY':
			return 'CTRL + Y';
		case 'KeyZ':
			return 'CTRL + Z';
		case 'Numpad0':
			return 'NUMPAD 0';
		case 'Numpad1':
			return 'NUMPAD 1';
		case ' Numpad2':
			return 'NUMPAD 2';
		case 'Numpad3':
			return 'NUMPAD 3';
		case 'Numpad4':
			return 'NUMPAD 4';
		case 'Numpad5':
			return 'NUMPAD 5';
		case 'Numpad6':
			return 'NUMPAD 6';
		case 'Numpad7':
			return 'NUMPAD 7';
		case 'Numpad8':
			return 'NUMPAD 8';
		case 'Numpad9':
			return 'NUMPAD 9';
		case 'NumpadMultiply':
			return 'MULTIPLY';
		case 'NumpadAdd':
			return 'ADD';
		case 'NumpadSubtract':
			return 'SUBTRACT';
		case 'NumpadDecimal':
			return 'DECIMAL POINT';
		case 'NumpadDivide':
			return 'DIVIDE';
		case 'F1':
			return 'F1';
		case 'F2':
			return 'F2';
		case 'F3':
			return 'F3';
		case 'F4':
			return 'F4';
		case 'F5':
			return 'F5';
		case 'F6':
			return 'F6';
		case 'F7':
			return 'F7';
		case 'F8':
			return 'F8';
		case 'F9':
			return 'F9';
		case 'F10':
			return 'F10';
		case 'F11':
			return 'F11';
		case 'F12':
			return 'F12';
		default:
			return undefined;
	}
}

export function isEan13Valido(ean: number): boolean {
	if (!ean || ean === null || `${ean}`.length < 13) {
		return false;
	}

	const eanPos = `${ean}`.split('');
	let soma = 0;
	for (let index = 0; index < eanPos.length - 1; index++) {
		if (index % 2 == 0) {
			soma = soma + Number(eanPos[index]) * 1;
		} else {
			soma = soma + Number(eanPos[index]) * 3;
		}
	}

	const somaDivisao = Math.trunc(soma / 10);
	const somaTemp = somaDivisao + 1;
	const somaMultiplicacao = somaTemp * 10;
	let codigoDv = somaMultiplicacao - soma;
	if (codigoDv % 10 === 0) {
		codigoDv = 0;
	}

	return !!(Number(eanPos[eanPos.length - 1]) === Number(codigoDv));
}

export function removerSiglaDinheiro(valor: string) {
	valor = valor.replace('%', '');
	valor = valor.replace('R$', '');
	valor = valor.replace('r$', '');
	valor = valor.replace(' ', '');
	return valor;
}

export function getValorNumber(valor: string) {
	return Number(valor);
}

export function popularEntidadeEditada(objetoCadastro: any, objetoEditado: any, campoStr: any, isCampoId = false) {
	const campoStrs = campoStr.split('.');
	let objetoCadastroAux = objetoCadastro;
	let objetoEditadoAux = objetoEditado;
	let ultimoCampoNome = '';
	if (campoStrs.length && campoStrs.length > 1) {
		for (let index = 0; index < campoStrs.length; index++) {
			ultimoCampoNome = campoStrs[index];
			if (index + 1 < campoStrs.length) {
				if (!objetoEditadoAux[ultimoCampoNome]) {
					objetoEditadoAux[ultimoCampoNome] = {};
				}
				objetoEditadoAux = objetoEditadoAux[ultimoCampoNome];
			}
			objetoCadastroAux = objetoCadastroAux[ultimoCampoNome];
		}
	} else {
		ultimoCampoNome = campoStr;
		objetoCadastroAux = objetoCadastroAux[ultimoCampoNome];
	}

	if (!isCampoId && !objetoEditadoAux[ultimoCampoNome]) {
		objetoEditadoAux[ultimoCampoNome] = {};
	}

	if (isCampoId) {
		delete objetoEditadoAux[ultimoCampoNome];
		objetoEditadoAux[`${ultimoCampoNome}Id`] =
			objetoCadastroAux && objetoCadastroAux !== null && objetoCadastroAux.id !== undefined ? clone(objetoCadastroAux.id) : null;
	} else {
		objetoEditadoAux[ultimoCampoNome] = objetoCadastroAux !== undefined && objetoCadastroAux !== null ? clone(objetoCadastroAux) : null;
	}
}

export function clone(objeto: any) {
	return JSON.parse(JSON.stringify(objeto));
}

export const vMaskMoney = ['R$ #,##', 'R$ ##,##', 'R$ ###,##', 'R$ #.###,##', 'R$ ##.###,##', 'R$ #.###.###,##', 'R$ ###.###.###,##'];

export function formatarValor(valor: { toLocaleString: (arg0: string, arg1: { style: string; currency: string }) => any }) {
	let valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
	valorFormatado = replaceAll(valorFormatado, ',', '*');
	valorFormatado = replaceAll(valorFormatado, '.', '');
	valorFormatado = replaceAll(valorFormatado, '*', '.');
	valorFormatado = removerSiglaDinheiro(valorFormatado);
	return `${valorFormatado}`;
}

export function getQuantidadeStringToNumber(valorString: any) {
	let quantidade = cloneDeep(valorString);
	if (typeof quantidade === 'string' && quantidade.includes(',')) {
		quantidade = quantidade.replace(',', '');
	}
	return Number(quantidade);
}

export function replaceAll(string: string, search: string, replace: string) {
	return string.split(search).join(replace);
}

export function getValorFormatarParaNumero(valor: any) {
	return getValorNumber(formatarValor(valor));
}

export function formatarValorVmoney(valor: { toLocaleString: (arg0: string, arg1: { style: string; currency: string }) => any }) {
	let valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
	valorFormatado = replaceAll(valorFormatado, ',', '');
	valorFormatado = removerSiglaDinheiro(valorFormatado);
	return `${valorFormatado}`;
}

export function getValorNumberVmoney(valor: any) {
	return getValorNumber(formatarValorVmoney(valor));
}

export function isValidCnpj(cnpj: string): boolean {
	cnpj = cnpj.replace(/[^\d]+/g, '');

	if (cnpj === '') return false;

	if (cnpj.length !== 14) return false;

	// Elimina CNPJs invalidos conhecidos
	if (
		cnpj === '00000000000000' ||
		cnpj === '11111111111111' ||
		cnpj === '22222222222222' ||
		cnpj === '33333333333333' ||
		cnpj === '44444444444444' ||
		cnpj === '55555555555555' ||
		cnpj === '66666666666666' ||
		cnpj === '77777777777777' ||
		cnpj === '88888888888888' ||
		cnpj === '99999999999999'
	)
		return false;

	// Valida DVs
	let size = cnpj.length - 2;
	let digits = cnpj.substring(0, size);
	const digitos = cnpj.substring(size);
	let sum = 0;
	let pos = size - 7;
	for (let i = size; i >= 1; i--) {
		sum += Number(digits.charAt(size - i)) * pos--;
		if (pos < 2) pos = 9;
	}
	let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
	if (result !== Number(digitos.charAt(0))) {
		return false;
	}

	size += 1;
	digits = cnpj.substring(0, size);
	sum = 0;
	pos = size - 7;
	for (let i = size; i >= 1; i--) {
		sum += Number(digits.charAt(size - i)) * pos--;
		if (pos < 2) pos = 9;
	}
	result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
	if (result !== Number(digitos.charAt(1))) {
		return false;
	}

	return true;
}

export function isValidCpf(cpf: string): boolean {
	cpf = cpf.replace(/[^\d]+/g, '');

	if (cpf == null) {
		return false;
	}
	if (cpf.length !== 11) {
		return false;
	}

	// Elimina CPFs invalidos conhecidos

	if (
		cpf === '00000000000' ||
		cpf === '11111111111' ||
		cpf === '22222222222' ||
		cpf === '33333333333' ||
		cpf === '44444444444' ||
		cpf === '55555555555' ||
		cpf === '66666666666' ||
		cpf === '77777777777' ||
		cpf === '88888888888' ||
		cpf === '99999999999'
	) {
		return false;
	}
	let numero = 0;
	let caracter = '';
	const numeros = '0123456789';
	let j = 10;
	let somatorio = 0;
	let resto = 0;
	let digito1 = 0;
	let digito2 = 0;
	let cpfAux = '';

	cpfAux = cpf.substring(0, 9);
	for (let i = 0; i < 9; i++) {
		caracter = cpfAux.charAt(i);
		if (numeros.search(caracter) === -1) {
			return false;
		}
		numero = Number(caracter);
		somatorio += numero * j;
		j--;
	}
	resto = somatorio % 11;
	digito1 = 11 - resto;
	if (digito1 > 9) {
		digito1 = 0;
	}
	j = 11;
	somatorio = 0;
	cpfAux += digito1;
	for (let i = 0; i < 10; i++) {
		caracter = cpfAux.charAt(i);
		numero = Number(caracter);
		somatorio += numero * j;
		j--;
	}
	resto = somatorio % 11;
	digito2 = 11 - resto;
	if (digito2 > 9) {
		digito2 = 0;
	}
	cpfAux += digito2;
	if (cpf !== cpfAux) {
		return false;
	}
	return true;
}

export function adicionarEventos(eventos: any) {
	return document.addEventListener('keyup', eventos, true);
}

export function removerEventos(eventos: any) {
	return document.removeEventListener('keyup', eventos, true);
}

export function trataMensagemErrorTry(error: any) {
	try {
		let mensagem = '';
		if (error) {
			if (error.message) {
				mensagem = String(error.message);
			} else {
				mensagem = error && error.error ? JSON.stringify(error.error) : JSON.stringify(error);
				if (
					error &&
					(error instanceof ReferenceError ||
						error instanceof TypeError ||
						error instanceof EvalError ||
						error instanceof RangeError)
				) {
					mensagem = String(error);
				}
			}
		}
		return mensagem;
	} catch (error) {
		return 'Erro não catalogado';
	}
}

export function gerarUuid() {
	return uuidV4();
}

export async function getUrlApi() {
	try {
		const storage = new Storage();
		const url = (await storage.get('GRAPHQL_API_URL')) as string;
		const urlApi = removeLastChar(url, '/');
		return urlApi;
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
	}
}

export function removeLastChar(inputString: string, charToRemove: string): string {
	if (inputString && inputString.endsWith(charToRemove)) {
		return inputString.slice(0, inputString.lastIndexOf(charToRemove));
	}
	return inputString;
}

export function isValidEmail(email: string): boolean {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
}

export function formatarCep(cep: any) {
	if (cep) {
		cep = cep.replace(/^(\d{5})(\d)/, '$1-$2');
	} else {
		cep = '';
	}
	return cep;
}

export function formatarTelefoneComDDD(telefone: any) {
	if (telefone) {
		telefone = telefone.replace(/\D/g, '');
		if (telefone.toString().length === 10) {
			telefone = telefone.replace(/(\d{6})(\d)/, '$1-$2');
		} else {
			telefone = telefone.replace(/(\d{7})(\d)/, '$1-$2');
		}
		telefone = telefone.replace(/^(\d\d)(\d)/g, '($1) $2');
	} else {
		telefone = '';
	}
	return telefone;
}

export function stringContain(str: any, contem: any) {
	return str.indexOf(contem) !== -1;
}

export function sleep(ms: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function stringContains(str: any, contemArray: any) {
	for (let index = 0; index < contemArray.length; index++) {
		const contem = contemArray[index];
		if (str.indexOf(contem) !== -1) {
			return true;
		}
	}
	return false;
}

export function camelCaseToString(valor: any) {
	return valor
		.replace(/[^A-Za-z]/g, '')
		.replace(/[^a-z]/g, function (a: any, x: any) {
			return (x > 0 ? '_' : '') + a;
		})
		.toLowerCase();
}

export function converterValorEmpresaConfiguracao(tipoObjetoId: any, valor: any): any {
	if (tipoObjetoId === 'CONFIGURACAO_TIPO_OBJETO_STRING' || tipoObjetoId === 'CONFIGURACAO_TIPO_OBJETO_TIPO') {
		return valor;
	} else if (tipoObjetoId === 'CONFIGURACAO_TIPO_OBJETO_NUMBER') {
		return getValorNumber(valor);
	} else if (tipoObjetoId === 'CONFIGURACAO_TIPO_OBJETO_BOOLEAN') {
		if (valor === 'true') {
			return true;
		} else if (valor === 'false') {
			return false;
		}
	}
}

export function getStringSemAcento(text: any) {
	text = text.replace(new RegExp('[ÁÀÂÃÄ]', 'g'), 'A');
	text = text.replace(new RegExp('[ÉÈÊË]', 'g'), 'E');
	text = text.replace(new RegExp('[ÍÌÎÏ]', 'g'), 'I');
	text = text.replace(new RegExp('[ÓÒÔÕÖ]', 'g'), 'O');
	text = text.replace(new RegExp('[ÚÙÛÜ]', 'g'), 'U');
	text = text.replace(new RegExp('[Ç]', 'g'), 'C');
	text = text.replace(new RegExp('[Ñ]', 'g'), 'N');
	text = text.replace(new RegExp('[Ý]', 'g'), 'Y');

	text = text.replace(new RegExp('[áàâãä@]', 'g'), 'a');
	text = text.replace(new RegExp('[éèêë&]', 'g'), 'e');
	text = text.replace(new RegExp('[íìîï]', 'g'), 'i');
	text = text.replace(new RegExp('[óòôöõ]', 'g'), 'o');
	text = text.replace(new RegExp('[úùûü]', 'g'), 'u');
	text = text.replace(new RegExp('[ç]', 'g'), 'c');
	text = text.replace(new RegExp('[ñ]', 'g'), 'n');
	text = text.replace(new RegExp('[ýÿ]', 'g'), 'y');

	text = text.replace(new RegExp('[%]', 'g'), 'p');

	text = text.replace(new RegExp('[º°ª¹²³¨´`­\'%*"]', 'g'), ' ');

	return text;
}

export function getStringToUrl(texto: any) {
	const string = getStringSemAcento(texto).replace(/\s+/g, '-');
	return string;
}

export function possuiNumero(texto: any) {
	return /\d/.test(texto);
}

export function isArray(valor: any) {
	return !!(valor && valor !== null && valor.length);
}

export const TipoPreenchimentoStringEnum = {
	ESQUERDA: 'ESQUERDA',
	DIREITA: 'DIREITA',
	CENTRO: 'CENTRO'
};

export function floatToString(val: any, qtdDecimais = 2) {
	if (val === undefined || val === null) {
		val = 0;
	}
	return val.toFixed(qtdDecimais).replace('.', ',');
}

export function dinheiroToFloat(valor: any) {
	valor = `${valor}`;
	valor = removerSiglaDinheiro(valor);
	valor = valor.replace('.', '');
	valor = valor.replace(',', '.');
	if (valor === '') {
		return 0;
	}
	return parseFloat(valor);
}

export function stringToFloat(valor: any) {
	if (valor === undefined || valor === null) {
		valor = 0;
	}
	valor = `${valor}`;
	valor = removerSiglaDinheiro(valor);
	valor = valor.replace('.', '');
	valor = valor.replace(',', '.');
	if (valor === '') {
		return 0;
	}
	return parseFloat(valor);
}

export function formatarDinheiroExcel(valor: any, mostraMoeda = true) {
	if (!valor) {
		valor = 0;
	}
	let valorFormatado = `<div align="right">${valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>`;
	if (!mostraMoeda) {
		valorFormatado = removerSiglaDinheiro(valorFormatado);
	}
	if (valor === 0) {
		valorFormatado = (mostraMoeda ? 'R$ ' : '') + '0,00';
	}
	return `${valorFormatado}`;
}

export function formatarPorcentagem(valor: any) {
	if (!valor) {
		valor = 0;
	}
	let valorFormatado = formatarDinheiro(valor, false) + '%';
	if (valor === 0) {
		valorFormatado = '0,00%';
	}
	return `${valorFormatado}`;
}

export function formatarTelefoneSemDDD(telefone: any) {
	telefone = telefone.toString();
	if (telefone) {
		telefone = telefone.replace(/\D/g, '');
		if (telefone.length === 8) {
			telefone = telefone.replace(/(\d{4})(\d)/, '$1-$2');
		} else {
			telefone = telefone.replace(/(\d{5})(\d)/, '$1-$2');
		}
	} else {
		telefone = '';
	}
	return telefone;
}

export function formatarCpf(cpf: any) {
	if (cpf) {
		cpf = cpf.replace(/^(\d{9})(\d)/, '$1-$2');
		cpf = cpf.replace(/^(\d{6})(\d)/, '$1.$2');
		cpf = cpf.replace(/^(\d{3})(\d)/, '$1.$2');
	} else {
		cpf = '';
	}
	return cpf;
}

export const ufs = [
	'AC',
	'AL',
	'AM',
	'AP',
	'BA',
	'CE',
	'DF',
	'ES',
	'GO',
	'MA',
	'MT',
	'MS',
	'MG',
	'PA',
	'PB',
	'PR',
	'PE',
	'PI',
	'RJ',
	'RN',
	'RO',
	'RS',
	'RR',
	'SC',
	'SE',
	'SP',
	'TO'
];

export function removerUndefinedAttributes(obj: any) {
	return JSON.parse(JSON.stringify(obj));
}

// TODO: Refatorar usando função padStart() e padEnd() da classe string do javascript console.log(str1.padStart(20, '-'));
export function preencherString(original: any, preenchimento: any, tamanho: any, tipo: any) {
	if (original != null) {
		original = original.toString();
		let retorno = '';
		let incremento = '';
		if (tamanho === original.length) {
			return original;
		}
		switch (tipo) {
			case TipoPreenchimentoStringEnum.ESQUERDA:
			case TipoPreenchimentoStringEnum.DIREITA: {
				for (let i = 0; i < tamanho - original.length; i++) {
					incremento += preenchimento;
				}
				if (tamanho < original.length) {
					return tipo === TipoPreenchimentoStringEnum.ESQUERDA
						? original.substring(original.length - tamanho)
						: original.substring(0, tamanho);
				}
				retorno = tipo === TipoPreenchimentoStringEnum.ESQUERDA ? incremento + original : original + incremento;
				break;
			}
			case TipoPreenchimentoStringEnum.CENTRO: {
				let res = Math.trunc((tamanho - original.length) / 2);
				for (let i = 0; i < res; i++) {
					incremento += preenchimento;
				}
				if (tamanho < original.length) {
					return original.substring(0, tamanho);
				}
				retorno = incremento + original + incremento;
				retorno = retorno.length === tamanho ? retorno : retorno + preenchimento;
				break;
			}
		}
		return retorno;
	} else {
		return '';
	}
}

export function aplicarImagemPadrao(event: any) {
	event.target.src = 'noImg.png';
	return event;
}

export function gerarCodigoAutomatico(listaItemCodigo: any, valor: any) {
	if (listaItemCodigo.length > 0) {
		const listaCodigos = listaItemCodigo.map((Itemcodigo: any) => Itemcodigo.codigo.toString());

		for (let index = 0; index < listaCodigos.length; index++) {
			const codigo = listaCodigos[index];
			const tamanhoStringCodigo = codigo.length > 1 ? codigo.length - 1 : 1;
			valor = valor.toString() === codigo.substring(0, tamanhoStringCodigo) ? valor + 1 : valor;
		}
	}

	let codigo = setCodigo(valor);
	codigo = Number(`${valor}${codigo}`);

	return codigo;
}

export function setCodigo(valor: any) {
	let codigoBarra = valor;

	if (codigoBarra.toString().length < 12) {
		codigoBarra = preencherString(`${codigoBarra}`, '0', 12, TipoPreenchimentoStringEnum.ESQUERDA);
	}
	const arg1 = codigoBarra.substring(0, 12);
	let y0 = 0;
	let y1 = 0;
	let y2 = 0;

	y1 =
		parseInt(arg1.substring(1, 2)) +
		parseInt(arg1.substring(3, 4)) +
		parseInt(arg1.substring(5, 6)) +
		parseInt(arg1.substring(7, 8)) +
		parseInt(arg1.substring(9, 10)) +
		parseInt(arg1.substring(11, 12));

	y2 =
		parseInt(arg1.substring(0, 1)) +
		parseInt(arg1.substring(2, 3)) +
		parseInt(arg1.substring(4, 5)) +
		parseInt(arg1.substring(6, 7)) +
		parseInt(arg1.substring(8, 9)) +
		parseInt(arg1.substring(10, 11));

	y0 = 3 * y1 + y2;
	y0 = 10 * (parseInt((y0 / 10).toString()) + 1) - y0;
	y0 = y0 === 10 ? 0 : y0;

	return y0;
}

export function formatarNumeroDecimal(valor: any, casasDecimais = 2) {
	return Number(valor.toFixed(casasDecimais));
}

export function desmembrarChave(chaveNfe: any) {
	const anoNota = Number(chaveNfe.substr(2, 2));
	const ano = Number(moment(anoNota, 'YY').format('YYYY'));
	const mes = Number(chaveNfe.substr(4, 2));
	const data = new Date(ano, mes - 1, 1);
	const serie = Number(chaveNfe.substr(22, 3));
	const numero = Number(chaveNfe.substr(25, 9));

	const notaFiscalDesmembrada = {
		serie: serie,
		numero: numero,
		dataEmissao: data
	};

	return notaFiscalDesmembrada;
}

export async function getUrlApiLiberacoes() {
	try {
		const storage = new Storage();
		const url = (await storage.get('LIBERACOES_API_URL')) as string;
		const urlApi = removeLastChar(url, '/');
		return urlApi;
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
	}
}

export async function getUrlSocket() {
	try {
		const storage = new Storage();
		const url = (await storage.get('URL_SOCKET')) as string;
		const urlApi = removeLastChar(url, '/');
		return urlApi;
	} catch (error) {
		if (process.env.MODE === 'electron') {
			window.windowAPI.logs('error', error);
		}
	}
}

export function getUrlApiLiberacoesAndPath(api: string) {
	let url = 'http//localhost:4000';
	let path = '/socket.io';
	if (!api) return { url, path };
	const urlArray = api.split('/');
	const urlArrayFilter = urlArray.filter((atributo) => atributo !== '');
	if (urlArrayFilter.length === 2) {
		url = api;
		path = '/socket.io';
		return { url, path };
	} else if (urlArrayFilter.length === 3) {
		url = `${urlArrayFilter[0]}//${urlArrayFilter[1]}`;
		path = `/${urlArrayFilter[2]}/socket.io`;
		return { url, path };
	}
	return { url, path };
}

export function validarInscricaoEstadual(input: string): boolean {
	const regex = /^[0-9]{2,14}$|^[P][0-9]{2,14}$|^ISENTO$/;
	if (regex.test(input)) {
		return true;
	}
	return false;
}

export function getOnlyNumbers(caracteres: any) {
	if (!caracteres || isNaN(caracteres)) return;
	if (caracteres && caracteres !== '') {
		return caracteres.match(/\d/g).join('');
	}
}

export function isExactPhraseInside(outerStr: any, innerPhrase: any) {
	const regex = new RegExp(`\\b${innerPhrase.trim()}\\b`, 'g');
	return regex.test(outerStr.trim());
}
