let configuracaoTeclas = {
	ENTER: 'Enter',
	ESC: 'Escape',
	BACKSPACE: 'Backspace',
	ALT: 'AltLeft',
	TAB: 'Tab',

	BUSCA_ITEM: 'KeyA',
	BUSCA_CLIENTE: 'KeyB',
	EDITAR: 'KeyE',
	TECLA_FUNCAO: 'KeyF',
	REPETE_ITEM: 'KeyK',
	TECLA_MENU: 'KeyM',
	NOVO_PEDIDO: 'KeyN',
	OBSERVACAO_PEDIDO: 'KeyO',
	TROCA_TABELA_VENDA: 'KeyQ',
	RECEBIMENTO: 'KeyR',
	TROCA_BUSCA_CODIGO_REFERENCIA: 'KeyT',

	SETA_DIREITA: 'ArrowLeft',
	SETA_CIMA: 'ArrowUp',
	SETA_ESQUERDA: 'ArrowRight',
	SETA_BAIXO: 'ArrowDown',

	DINHEIRO: 'F1',
	DEBITO: 'F2',
	CREDITO: 'F3',
	BOLETO: 'F4',
	CARTEIRA_DIGITAL: 'F5',
	ALIMENTACAO: 'F6',
	REFEICAO: 'F7',
	OPERACAO_BANCARIA: 'F8',
	VALE_COMPRA: 'F9',
	CONVENIO: 'F10',
	CORTESIA: 'F11',
	CHEQUE: 'F12',

	UM: 'Digit1',
	DOIS: 'Digit2',
	TRES: 'Digit3',
	QUATRO: 'Digit4',
	CINCO: 'Digit5',
	SEIS: 'Digit6',
	SETE: 'Digit7',
	OITO: 'Digit8',
	NOVE: 'Digit9',

	FILTRO_AVANCADO: false,
	CANCELA_ITEM: '',
	BALANCA_CAPTURA: '',
	ALTERA_QUANTIDADE: '',
	CONSULTA_ITEM: '',
	ABRE_GAVETA: '',
	EM_CADASTRO: '',
	TECLA_RECARGA: '',
	FIDELIDADE: '',
	PESQUISA: '',
	ENTRADA_VALOR: '',
	DESCONTO_GERAL: '',
	VENDEDOR: '',
	CPF_CNPJ: '',
	DOIS_ZEROS: '',
	QTD24: '',
	QTD12: '',
	QTD6: '',
	QTD4: '',
	QTD2: '',
	TEF: ''
};

export default configuracaoTeclas;
